Promise.prototype.spread = spread;

export const HANDLED_PROMISE_REJECTION_MESSAGE_PREFIX = 'Handled Promise Rejection (this should not end up in Sentry): ';

/**
 * Spread method for promises
 * @param {function} resolve
 * @param {function} reject
 * @returns {Promise.<T>}
 */
function spread(resolve, reject) {
    return this.then(spreadExecutor, reject); // eslint-disable-line no-invalid-this

    /**
     * Resolve callback from the promise
     * @param {*} result
     * @returns {*}
     */
    function spreadExecutor(result) {
        return resolve.apply(null, result);
    }
}

export default class PromiseHelper {
    /**
     * Check if the specified object is a promise
     * @param {*} object
     * @returns {boolean}
     */
    static isPromise(object) {
        return object !== null && typeof object === 'object' && typeof object.then === 'function';
    }
    /**
     * Wait for all promises before rejecting
     * @param {Array} promises
     * @returns {Promise.<*>}
     */
    static all(promises) {
        return Promise.all(promises.map(inspectPromise)).then(results => {
            const success = results.filter(result => result.status === 'resolved');
            if (results.length === success.length) {
                return success.map(result => result.result);
            } else {
                return Promise.reject(results.filter(result => result.status === 'rejected').map(result => result.error));
            }
        });
        /**
         * Helper function used to return the result of a promise with the corresponding status
         * @param  {Promise} promise
         * @return {Promise<Object>}
         */
        function inspectPromise(promise) {
            return promise.then(
                result => ({ result, status: 'resolved' }),
                error => ({ error, status: 'rejected' })
            );
        }
    }
}

/**
 * Defer wrapper around promise
 */
export class Defer {
    constructor() {
        this.resolvePromise = null;
        this.rejectPromise = null;
        this.promise = new Promise(executor.bind(this));

        /**
         * Stores callback methods for resolve and reject, so they can be called later
         * @param {Function} resolve
         * @param {Function} reject
         */
        function executor(resolve, reject) {
            this.resolvePromise = resolve; // eslint-disable-line no-invalid-this
            this.rejectPromise = reject; // eslint-disable-line no-invalid-this
        }
    }

    /**
     * Resolve this defer's promise
     * @param {*} result
     */
    resolve(result) {
        this.resolvePromise(result);
    }

    /**
     * Reject this defer's promise
     * @param {String|*} reason
     */
    reject(reason) {
        this.rejectPromise(reason);
    }
}
