const ua = navigator.userAgent;
let version;
export const browser = {};
const ios = /ip(hone|od|ad)/i.test(ua);
const android = /android/i.test(ua);
const blackberry = /blackberry/i.test(ua);
const webos = /webos/i.test(ua);
const kindle = /silk|kindle/i.test(ua);

if (ios) {
    browser.ios = true;
    version = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    browser.version = version && parseFloat(version[1] + '.' + version[2]);
}
browser.mobile = /mobile/i.test(ua) || ios || android || blackberry || webos || kindle;
browser.firefox = /firefox/i.test(ua);
if (/safari/i.test(ua)) {
    browser.chrome = /chrome/i.test(ua);
    browser.safari = !browser.chrome;
}
if (browser.safari) {
    version = navigator.appVersion.match(/Version\/(\d+(\.\d+)?)/);
    browser.version = version && parseFloat(version[1]);
}

browser.phone = browser.mobile && isInResponsiveMobileMode();

function isInResponsiveMobileMode() {
    return (
        (window.matchMedia && window.matchMedia('(max-width: 520px)').matches) ||
        (window.matchMedia && window.matchMedia('(max-width: 736px) and (orientation:landscape)').matches)
    );
}
