import Dom from '../../helpers/dom-helper';

export default class Header {
    constructor(domManager, info, eventBus) {
        this.info = info;
        this.logoUrl = info.theme.logoUrl;
        this.organisationName = (info.organisation && info.organisation.name) || '';
        this.organisationBiography = (info.organisation && info.organisation.biography) || '';
        this.eventBus = eventBus;

        this.domManager = domManager;
        this.addEventListeners();
    }

    /**
     * Load info about the showcase
     */
    render() {
        Dom.removeClass('hide', this.domManager.get('showcase-header'));

        // Set company name
        this.domManager.get('company-name').forEach(nameElement => (nameElement.innerHTML = Dom.htmlEntities(this.organisationName)));

        // Set company bio
        this.domManager
            .get('company-description')
            .forEach(
                biographyElement => (biographyElement.innerHTML = Dom.htmlEntities(this.organisationBiography).replace(/\n/g, '<br>'))
            );

        // Set company logo or name
        const logo = this.domManager.get('company-logo');

        if (typeof this.logoUrl === 'string' && this.logoUrl.length > 0) {
            logo.classList.add('has-logo');
            logo.style.backgroundImage = `url(${this.logoUrl})`;
            logo.innerHTML = '';
        } else {
            logo.innerHTML = Dom.htmlEntities(this.organisationName);
        }

        try {
            // Hide re-share button
            if (this.info.disableReshare) {
                Dom.hideElement(this.domManager.get('showcase-header-share-button'));
            }
        } catch (err) {
            // ignore, safety measure in case this is deployed first
        }
    }

    onClickOpenModal(action) {
        this.openModal = action;
    }

    /**
     * Add event listeners for the info button
     */
    addEventListeners() {
        Dom.on(this.domManager.get('showcase-header-about-button'), 'click', () => this.openModal('about'));
        Dom.on(this.domManager.get('showcase-header-share-button'), 'click', () => {
            this.eventBus.dispatch('track:shareClick');
            this.openModal('share');
        });
    }
}
