/**
 * Helper for the DOM
 */
const ANIMATION_SPEED = 400; // in ms

export default class Dom {
    /**
     * Add event listener to the specified event
     * @param {Element} element
     * @param {String} eventName
     * @param {function} callback
     * @param {String} delegate
     */
    static on(element, eventName, callback, delegate = null) {
        if (delegate === null) {
            element.addEventListener(eventName, callback);
        } else {
            // shift arguments - enable param reassign here, as we're shifting params here
            /* eslint-disable no-param-reassign */
            [delegate, eventName, callback] = [eventName.toUpperCase(), callback, delegate];
            /* eslint-enable no-param-reassign */

            element.addEventListener(eventName, onEventDelegate);
        }

        /**
         * Callback method when the event happened on the delegate object
         * @param {Object} event
         */
        function onEventDelegate(event) {
            let target = event.target;

            // search for matching element between clicked element and starting element
            while (target.tagName !== delegate && target !== element) {
                target = target.parentNode;
            }

            if (target.tagName === delegate) {
                callback(event, target);
            }
        }
    }

    /**
     * Remove event listener from the specified element
     * @param {Element} element
     * @param {String} event
     * @param {function} callback
     */
    static off(element, event, callback) {
        element.removeEventListener(event, callback);
    }

    /**
     * Get elements index in parent
     * @param {Element} element
     * @returns {Number}
     */
    static index(element) {
        let elementIndex = 0;
        let siblingElement = element;

        while (siblingElement.previousSibling !== null) {
            siblingElement = siblingElement.previousSibling;
            elementIndex++;
        }

        return elementIndex;
    }

    /**
     * Check if the browser is in responsive mobile mode
     * @returns {boolean}
     */
    static isInResponsiveMobileMode() {
        return (
            (window.matchMedia && window.matchMedia('(max-width: 520px)').matches) ||
            (window.matchMedia && window.matchMedia('(max-width: 736px) and (orientation:landscape)').matches)
        );
    }

    /**
     * Check if the browser supports svg
     * @returns {boolean}
     */
    static doesBrowserSupportSvg() {
        /* eslint no-implicit-coercion:0 */
        return !!document.createElementNS && !!document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect;
    }

    /**
     * Check if the browser supports touch events
     * @returns {boolean}
     */
    static doesBrowserSupportTouch() {
        return 'ontouchstart' in document.documentElement;
    }

    /**
     * Hide the specified DOM element
     * @param {Element} element
     */
    static hideElement(element) {
        if (element.style.display !== 'none') {
            const displayProp = element.getAttribute('data-display') || element.style.display;
            element.setAttribute('data-display', displayProp);
            element.style.display = 'none';
        }
    }

    /**
     * Show the specified DOM element
     * @param {Element} element
     */
    static showElement(element) {
        element.style.display = element.getAttribute('data-display') || 'block';
    }

    /**
     * Show the specified DOM element with defined property value
     * @param {Element} element
     */
    static showElementAs(element, value) {
        element.style.display = element.getAttribute('data-display') || value;
    }

    /**
     * Add a class name or a list of class names to dom elements of a list of dom elements
     * @param {String|Array<String>} classNames
     * @param {Element|Array<Element>} elements
     */
    static addClass(
        classNames,
        elements // eslint-disable-line complexity
    ) {
        if (elements === null) {
            throw new Error(`Invalid element specified for addClass: ${elements}`);
        } else if (typeof classNames === 'object' && typeof classNames.length === 'number') {
            classNames.forEach(className => Dom.addClass(className, elements));
        } else if (typeof classNames === 'string') {
            if (!(elements instanceof Element)) {
                if (typeof elements === 'object' && typeof elements.length === 'number') {
                    elements.forEach(element => Dom.addClass(classNames, element));
                } else {
                    throw new Error(`Invalid elements specified for addClass: ${elements}`);
                }
            } else if (!Dom.hasClass(classNames, elements)) {
                elements.classList.add(classNames);
            }
        } else {
            throw new Error(`Invalid class name specified for addClass: ${classNames}`);
        }
    }

    /**
     * remove a class name or a list of class names from dom element of a list of dom elements
     * @param {String|Array<String>} classNames
     * @param {Element|Array<Element>} elements
     */
    static removeClass(
        classNames,
        elements // eslint-disable-line complexity
    ) {
        if (elements === null) {
            throw new Error(`Invalid element specified for removeClass: ${elements}`);
        } else if (typeof classNames === 'object' && typeof classNames.length === 'number') {
            classNames.forEach(className => Dom.removeClass(className, elements));
        } else if (typeof classNames === 'string') {
            if (!(elements instanceof Element)) {
                if (typeof elements === 'object' && typeof elements.length === 'number') {
                    elements.forEach(element => Dom.removeClass(classNames, element));
                } else {
                    throw new Error(`Invalid element specified for removeClass: ${elements}`);
                }
            } else if (Dom.hasClass(classNames, elements)) {
                elements.classList.remove(classNames);
            }
        } else {
            throw new Error(`Invalid class name specified for removeClass: ${classNames}`);
        }
    }

    /**
     * toggle a class name or a list of class names for dom element of a list of dom elements
     * @param {String|Array<String>} classNames
     * @param {Element|Array<Element>} elements
     */
    static toggleClass(
        classNames,
        elements // eslint-disable-line complexity
    ) {
        if (typeof classNames === 'object' && typeof classNames.length === 'number') {
            classNames.forEach(className => Dom.toggleClass(className, elements));
        } else if (typeof classNames === 'string') {
            if (typeof elements === 'object' && typeof elements.length === 'number') {
                elements.forEach(element => Dom.toggleClass(classNames, element));
            } else if (!(elements instanceof Element)) {
                throw new Error(`Invalid element specified for toggleClass: ${elements}`);
            } else if (Dom.hasClass(classNames, elements)) {
                Dom.removeClass(classNames, elements);
            } else {
                Dom.addClass(classNames, elements);
            }
        } else {
            throw new Error(`Invalid class name specified for toggleClass: ${classNames}`);
        }
    }

    /**
     * Check if the specified element has the specified class
     * @param {String} className
     * @param {Element} element
     * @returns {boolean}
     */
    static hasClass(className, element) {
        if (typeof className !== 'string') {
            throw new Error('Invalid class name specified');
        }

        if (!(element instanceof Element)) {
            throw new Error('Invalid element specified');
        }

        return element.classList.contains(className);
    }

    /**
     * Escapes html entities
     * @param {String} input
     * @returns {String}
     */
    static htmlEntities(input) {
        return input.replace(/[\u00A0-\u9999<>\&]/gim, replaceByFunction);

        /**
         * Replace char
         * @param {String} match
         * @returns {String}
         */
        function replaceByFunction(match) {
            return `&#${match.charCodeAt(0)};`;
        }
    }

    /**
     *
     * @param {String} str
     * @returns {String}
     */
    static linkify(str) {
        return str.replace(
            /((http(s)?(\:\/\/))+(www\.)?([\w\-\.\/])*(\.[a-zA-Z]{2,3}\/?)[^\s\r\n\?\!\@\^\$\"\'\(\)\]\<\>|]*[^.,;:\s\r\n\?\!\@\^\$\"\'\(\)\]\<\>|-])/g,
            '<a href="$1" target="_blank">$1</a>'
        );
    }

    /**
     * Fade out an element and remove it from the DOM
     * @param {Element} element
     * @param {Element} parent
     * @returns {Promise}
     */
    static fadeOutAndRemove(element, parent) {
        return fadeOut(element).then(() => {
            try {
                parent.removeChild(element);
            } catch (error) {
                console && console.warn && console.warn('could not remove the element'); // eslint-disable-line no-console
            }
        });
    }

    /**
     * Append element to a parent, set opacity to 0 and fade it in
     * @param {Element} element
     * @param {Element} parent
     * @returns {Promise}
     */
    static appendAndFadeIn(element, parent) {
        element.style.opacity = 0;
        parent.appendChild(element);
        return fadeIn(element);
    }

    /**
     * Create DOM element from given template
     * @param {String} template
     * @returns {HTMLElement}
     */
    static createElement(template) {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = template;

        if (tempElement.children.length > 1) {
            throw new Error('Create element expects 1 root node');
        }

        return tempElement.children[0];
    }

    /**
     * Set the text content of a node
     * @param {Element} element
     * @param {String} text
     */
    static setElementText(element, text) {
        element.textContent = text;
    }

    /**
     * Get size to after clamping has been applied to the specified DOM element
     * This is so we can update previewUrls with better quality depending on the rect of the DOM element, but we don't
     * need previews for every size
     *
     * @param {Element} element
     * @returns {Number}
     */
    static getClampedDimensionsForElement(element) {
        const rect = element.getBoundingClientRect();
        const ratio = window.devicePixelRatio || 2;
        const size = Math.max(rect.width, rect.height) * ratio;

        /* eslint no-magic-numbers: 0 */
        const clampSizes = [160, 320, 480, 640, 800, 1600];
        let clampSizeIndex = 0;
        let clampedSize = clampSizes[clampSizeIndex];

        while (clampedSize < size) {
            clampedSize = clampSizes[++clampSizeIndex];
        }

        return clampedSize;
    }

    /**
     * Find the parent node with the specified tag name,
     * includes the starting tag
     * @param {Element} element
     * @param {String} tagName
     * @returns {Element|null}
     */
    static findParent(element, tagName) {
        let result = null;

        if (element.tagName === tagName) {
            result = element;
        } else {
            let maxIterations = 10;
            let parent = element.parentNode;

            while (result === null && parent !== null && --maxIterations > 0) {
                if (parent.tagName === tagName) {
                    result = parent;
                }
                parent = parent.parentNode;
            }
        }

        return result;
    }
}

/**
 * Fade out an element
 * @param {Element} element
 * @returns {Promise}
 */
function fadeOut(element) {
    return new Promise(executor);

    /**
     * Executer for promise
     * @param {function} resolve
     * @param {function} reject
     */
    function executor(resolve, reject) {
        element.style.opacity = 1;

        /**
         * Fade method: change opacity and resolve or keep feeding
         */
        function fade() {
            try {
                element.style.opacity = Number(element.style.opacity) - ANIMATION_SPEED / 6000;
            } catch (error) {
                reject(error);
                return;
            }

            if (element.style.opacity > 0) {
                requestAnimationFrame(fade);
            } else {
                element.style.opacity = 0;
                resolve();
            }
        }

        fade();
    }
}

/**
 * Fade in an element
 * @param {Element} element
 * @returns {Promise}
 */
function fadeIn(element) {
    return new Promise(executor);

    /**
     * Execute the promise
     * @param {function} resolve
     * @param {function} reject
     */
    function executor(resolve, reject) {
        element.style.opacity = 0;

        /**
         * Fade method: change opacity and resolve or keep feeding
         */
        function fade() {
            try {
                element.style.opacity = Number(element.style.opacity) + ANIMATION_SPEED / 6000;
            } catch (error) {
                reject(error);
                return;
            }

            if (element.style.opacity < 1) {
                requestAnimationFrame(fade);
            } else {
                element.style.opacity = 1;
                resolve();
            }
        }

        fade();
    }
}
