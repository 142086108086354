import env from './env';

const BASE_HREF = env.BASE_HREF || (env.ENVIRONMENT === 'development' ? '/' : '/share/');
const BASE_HREF_WITHOUT_TRAILING_SLASH = BASE_HREF.endsWith('/') ? BASE_HREF.slice(0, -1) : BASE_HREF;
/**
 * Url Helper
 */
export default class Url {
    /**
     * Make sure the map for urls exists
     */
    static assertUrlMapExists() {
        if (typeof Url.urls !== 'object') {
            Url.urls = {};
        }
    }

    /**
     * Set a new url
     * @param {string} key
     * @param {string} url
     */
    static setUrl(key, url) {
        Url.assertUrlMapExists();
        Url.urls[key] = url;
    }

    /**
     * Get an url with replaced params
     * @param {string} key
     * @param {object} [params]
     * @returns {string}
     */
    static getUrlWithParams(key, params = {}) {
        Url.assertUrlMapExists();
        assertMapHasKey(Url.urls, key);
        assertVariableIsObject(params);

        return replaceParamsInString(Url.urls[key], params);
    }

    /**
     * Decode the share hash into an API base and the real share hash
     * @param {string} path
     * @returns {string[]}
     */
    static parseShareHash(path) {
        const hashRegexp = new RegExp(`^${BASE_HREF}([A-Za-z0-9]+)`);
        const matches = path.match(hashRegexp);

        if (matches === null) {
            throw new Error(`Path ${path} has no share hash`);
        }

        const hash = matches[1];

        return [`${env.baseUrl}/showcase`, hash];
    }

    static getShowpadSubdomain() {
        const baseUrl = env.baseUrl;
        return baseUrl.substring(0, baseUrl.indexOf('.showpad'));
    }

    /**
     * Get the url for viewing Showcases
     * @returns {String}
     */
    static getShowcaseBaseUrl() {
        return `${env.baseUrl}${BASE_HREF_WITHOUT_TRAILING_SLASH}`;
    }

    /**
     * Check if the specified path has a share hash
     * @param {String} path
     * @returns {boolean}
     */
    static hasShareHash(path) {
        const hashRegexp = new RegExp(`^${BASE_HREF}[A-Za-z0-9]+`);
        return hashRegexp.test(path);
    }

    /**
     * Get a URL segment or return all segments by default
     * @param {Number} index
     * @returns {String}
     */
    static getSegment(index) {
        const pathname = window.location.pathname;
        const segments = pathname.split('/');
        let segment = null;

        // remove empty string
        segments.shift();

        if (index >= 0) {
            segment = segments[index];
        } else {
            segment = segments;
        }

        return segment;
    }

    static getParamValue(paramName) {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        return params.get(paramName);
    }

    /**
     * Update the URL (replace state)
     * @param {String} url
     */
    static updateUrl(url) {
        try {
            window.history.replaceState(null, null, url);
        } catch (exception) {
            // silently fail
        }
    }
}

/**
 * Check if the specified map has the required key
 * @param {Object} map
 * @param {string} key
 */
function assertMapHasKey(map, key) {
    if (!map.hasOwnProperty(key)) {
        throw new Error(`key <${key}> was not found`);
    }
}

/**
 * Verify the specified variable is an object
 * @param {*} value
 */
function assertVariableIsObject(value) {
    if (typeof value !== 'object') {
        throw new Error('Invalid parameters specified');
    }
}

/**
 * Replaces string params
 * @param {string} value
 * @param {object} params
 * @returns {string}
 */
function replaceParamsInString(value, params) {
    let result = value;

    for (const index in params) {
        if (params.hasOwnProperty(index)) {
            const paramRegexp = new RegExp(`:${index}\\b`, 'g');
            result = result.replace(paramRegexp, params[index]);
        }
    }

    return result;
}
