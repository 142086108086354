import { v4 as uuidV4 } from 'uuid';

const initializePendo = function(apiKey, environment, organisationId) {
    if (!apiKey) {
        return; // no pendo to be initialized
    }

    // IDs should be prefixed with environment, always use `production` for any prod environment and `staging` for any other.
    const environmentPrefix = ['shared-eks-production', 'production'].includes(environment) ? 'production' : 'staging';

    window['pendo'].initialize({
        visitor: {
            id: `${environmentPrefix}-${organisationId}-showcase_client_${uuidV4()}`,
            isProspect: true, // This is always a prospect
            environment: environment,
            screenWidth: window.screen.width,
            screenHeight: window.screen.height
        },
        account: {
            id: `${environmentPrefix}-${organisationId}`
        },
        excludeAllText: true,
        disableCookies: true,
        disablePersistence: true
    });
};

export { initializePendo };
