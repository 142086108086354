export function getFullName(firstName, lastName) {
    return `${firstName ? firstName.trim() : ''} ${lastName ? lastName.trim() : ''}`.trim();
}

export function getInitials(firstName, lastName, emailAddress) {
    const trimmedFirstName = firstName && firstName.trim();
    const trimmedLastName = lastName && lastName.trim();
    const trimmedEmailAddress = emailAddress && emailAddress.trim();

    const initials =
        trimmedFirstName || trimmedLastName
            ? `${trimmedFirstName ? trimmedFirstName[0] : ''}${trimmedLastName ? trimmedLastName[0] : ''}`
            : trimmedEmailAddress
            ? trimmedEmailAddress[0]
            : '';

    return initials.toUpperCase();
}
