import DomManager from '../helpers/dom-manager';
import Dom from '../helpers/dom-helper';

export default class RenderComponent {
    constructor(element, parent) {
        this.domManager = new DomManager();
        this.element = element;
        this.parent = parent;
        this.data = {};
    }

    getElementContainer() {
        return this.element;
    }

    getParentElementContainer() {
        return this.parent;
    }

    show() {
        Dom.showElement(this.element);
    }

    hide() {
        Dom.hideElement(this.element);
    }

    fetchDomElements() {}
    addEventListeners() {}
    render() {}
}
