import * as Sentry from '@sentry/browser';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { STATIC_PATH } from '../helpers/constants';
import { Defer } from '../helpers/promise';
import { I18N_WHITELIST } from './i18n-languages';
import { getPreferredLanguage } from './language-detector';

function init() {
    const ShowpadLngDetector = new LanguageDetector();
    ShowpadLngDetector.addDetector({
        name: 'showpadNavigator',
        lookup() {
            return getPreferredLanguage(I18N_WHITELIST, window.navigator.languages || [window.navigator.language]);
        }
    });

    i18n.use(Backend)
        .use(ShowpadLngDetector)
        .init({
            fallbackLng: 'en',
            ns: ['translations'],
            defaultNS: 'translations',
            keySeparator: '.',
            debug: false,
            saveMissing: true,
            missingKeyHandler: ([language], namespace, key, fallbackValue) =>
                Sentry.captureException('Invalid translation key', {
                    tags: { category: 'i18n' },
                    extra: { language, namespace, key, fallbackValue, location: document.location.href }
                }),
            interpolation: {
                escapeValue: false, // could this be true?
                formatSeparator: ',',
                format: (value, format) => {
                    if (format === 'uppercase') {
                        return value.toUpperCase();
                    }
                    return value;
                }
            },
            backend: {
                loadPath: function(lng, ns) {
                    if (!window.hasOwnProperty('__i18nDict')) {
                        const i18nDictionaryError = new Error('Internationalization dictionary not found');
                        i18nDictionaryError['extra'] = { location: document.location.href };

                        throw i18nDictionaryError;
                    }

                    // Object that maps dictionary names without hash to dictionary names with hash.
                    // i18n/locales/{{lng}}/{{ns}}.json => i18n/locales/{{lng}}/{{ns}}.hash.json
                    const i18nDict = window['__i18nDict'] || {};
                    const urlWithoutHash = `source/i18n/locales/${lng}/${ns}.json`;
                    const urlWithHash = i18nDict[urlWithoutHash];

                    if (!urlWithHash && lng === 'en') {
                        const i18nUrlError = new Error('Internationalization file not found');
                        i18nUrlError['extra'] = { location: document.location.href, urlWithoutHash };

                        throw i18nUrlError;
                    }

                    // If STATIC_PATH is set, update prefix with static path
                    if (window.__env && window.__env.STATIC_PATH) {
                        return `${window.__env.STATIC_PATH}${urlWithHash}`;
                    }

                    return `${STATIC_PATH}${urlWithHash}`;
                }
            },
            detection: {
                // i18next-browser-languagedetector
                order: ['querystring', 'sessionStorage', 'showpadNavigator', 'localStorage'],
                excludeCacheFor: ['cimode']
            }
        });

    const i18nLoadedOrFailed = new Defer();

    i18n.on('loaded', () => {
        i18nLoadedOrFailed.resolve();
    });

    i18n.on('onFailedLoading', () => {
        i18nLoadedOrFailed.resolve();
    });

    i18n.on('languageChanged', lang => {
        lang = lang.toLocaleLowerCase();
        if (lang === 'en' || lang.startsWith('en')) {
            return;
        }
    });

    return i18nLoadedOrFailed.promise;
}

export default init;
