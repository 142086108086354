export default class DomManager {
    constructor() {
        this.elements = {};
    }

    /**
     * Store one or more elements by key
     * @param {String} key
     * @param {Element|Array<Element>} value
     */
    set(key, value) {
        if (this.elements.hasOwnProperty(key) && this.elements[key] !== null) {
            throw new Error(`The key '${key}' is already used`);
        }

        this.elements[key] = value;
    }

    /**
     * Retrieve the value for the stored key
     * @param {String} key
     * @returns {Element|Array<Element>}
     */
    get(key) {
        if (!this.elements.hasOwnProperty(key) || this.elements[key] === null) {
            throw new Error(`The key '${key}' is not initialized`);
        }

        return this.elements[key];
    }

    /**
     * Check if the domManager has a certain key available
     * @param {String} key
     * @returns {Boolean}
     */
    has(key) {
        return this.elements.hasOwnProperty(key) && this.elements[key] !== null;
    }

    /**
     * Remove an element from the manager
     * This will just set the value to null
     * @param {string} key
     */
    remove(key) {
        this.elements[key] = null;
    }
}
