/* eslint-disable no-console */
import { hasDeviceEventData } from './device-event.helper';
import env from '../../source/helpers/env';

const isProdEnv = () => {
    return /\b(?:prod|production)\b/gi.test(env.ENVIRONMENT);
};

const logMainInfo = (event, color) => {
    if (hasDeviceEventData(event)) {
        const finalColor = `color:${color}`;

        console.log(`%cID:            ${event.data.assetViewerItem.assetId}`, finalColor);
        console.log(`%cName:          ${event.data.assetViewerItem.displayName}`, finalColor);
        console.log(`%cType:          ${event.data.assetViewerItem.type}`, finalColor);
        console.log(`%cDeviceEventId: ${event.deviceEvent.id}`, finalColor);
        console.log(`%cAction:        ${event.deviceEvent.action}`, finalColor);
        console.log(`%cPage:          ${event.deviceEvent.page}`, finalColor);
    }
};

export const logStart = event => {
    if (!isProdEnv()) {
        console.group();
        console.log('%c🏁 startDeviceEvent', 'font-weight:800;color:#13ff11');
        logMainInfo(event, '#13ff11');
        console.groupEnd();
    }
};

export const logUpdate = event => {
    if (!isProdEnv()) {
        console.group();
        console.log('%c🔄 updateDeviceEvent', 'font-weight:800;color:skyblue');
        logMainInfo(event, 'skyblue');
        console.groupEnd();
    }
};

export const logStop = event => {
    if (!isProdEnv()) {
        console.group();
        console.log('%c🛑 stopDeviceEvent', 'font-weight:800;color:#ff2929');
        logMainInfo(event, '#ff2929');
        console.groupEnd();
    }
};

export const warnDeviceEventLogging = () => {
    !isProdEnv()
        ? console.info('ℹ️ 🟢 Device event logging is %cenabled%c.', 'font-weight:800;color:#15ff00', 'font-weight:100;color:white')
        : console.info('ℹ️ 🔴 Device event logging is %cdisabled%c.', 'font-weight:800;color:red', 'font-weight:100;color:white');
};
