function _template() {return `<div class="compliance-modal">
    <div class="modal-header">
        <div class="modal-header-title"></div>
        <button type="button" class="button-transparent button-close">
            <i class="spsc-icon-close"></i>
            <div class="button_focus-overlay"></div>
        </button>
    </div>
    <div class="modal-body">
        <div class="compliance-desc"></div>
        <ol class="compliance-section">
            <li>
                <section>
                    <h3 class="compliance-resharing-title section-title"></h3>
                    <div class="compliance-resharing-description"></div>
                    <ol>
                        <li class="compliance-resharing-1"></li>
                        <li class="compliance-resharing-2"></li>
                        <li class="compliance-resharing-3"></li>
                    </ol>
                </section>
            </li>
            <li>
                <section>
                    <h3 class="compliance-uploading-title section-title"></h3>
                    <div class="compliance-uploading-description"></div>
                    <ol>
                        <li class="compliance-uploading-1"></li>
                        <li class="compliance-uploading-2"></li>
                        <li class="compliance-uploading-3"></li>
                        <li class="compliance-uploading-4"></li>
                        <li class="compliance-uploading-5"></li>
                    </ol>
                </section>
            </li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="button--m button-tint button-done"><span>Got it</span>
            <div class="button_focus-overlay"></div>
        </button>
    </div>
</div>`;}export default function (ctx) {return _template.call(ctx, ctx);};