import i18next from 'i18next';
import Dom from '../../../helpers/dom-helper';
import complianceModal from './compliance-modal.html';

export default class ComplianceModal {
    constructor(parentModal) {
        this.type = 'compliance';
        this.element = null;
        this.parentModal = parentModal;
        this.domManager = this.parentModal.domManager;
        this.eventLogger = this.parentModal.eventLogger || null;
        this.info = null;

        this.init();
    }

    /**
     * Initialize the compliance modal
     */
    init() {
        this.render();
    }

    /**
     * Render the content of the modal
     */
    render() {
        if (this.element !== null) {
            throw new Exception('ComplianceModal already rendered');
        }

        const COMPLIANCE_MODAL_TEMPLATE = complianceModal(this.info);

        this.element = Dom.createElement(COMPLIANCE_MODAL_TEMPLATE);
        this.domManager.get('modal').appendChild(this.element);

        this.element.querySelector('.modal-header-title').textContent = i18next.t('compliance-policy.header');
        this.element.querySelector('.compliance-desc').textContent = i18next.t('compliance-policy.intro');
        this.element.querySelector('.compliance-resharing-title').textContent = i18next.t('compliance-policy.section-resharing-header');
        this.element.querySelector('.compliance-resharing-description').textContent = i18next.t(
            'compliance-policy.section-resharing-intro'
        );
        this.element.querySelector('.compliance-resharing-1').textContent = i18next.t(
            'compliance-policy.section-resharing-bullet-point-one'
        );
        this.element.querySelector('.compliance-resharing-2').textContent = i18next.t(
            'compliance-policy.section-resharing-bullet-point-two'
        );
        this.element.querySelector('.compliance-resharing-3').textContent = i18next.t(
            'compliance-policy.section-resharing-bullet-point-three'
        );
        this.element.querySelector('.compliance-uploading-title').textContent = i18next.t('compliance-policy.section-uploading-header');
        this.element.querySelector('.compliance-uploading-description').textContent = i18next.t(
            'compliance-policy.section-uploading-intro'
        );
        this.element.querySelector('.compliance-uploading-1').textContent = i18next.t(
            'compliance-policy.section-uploading-bullet-point-one'
        );
        this.element.querySelector('.compliance-uploading-2').textContent = i18next.t(
            'compliance-policy.section-uploading-bullet-point-two'
        );
        this.element.querySelector('.compliance-uploading-3').innerHTML = i18next.t(
            'compliance-policy.section-uploading-bullet-point-three'
        );
        this.element.querySelector('.compliance-uploading-4').textContent = i18next.t(
            'compliance-policy.section-uploading-bullet-point-four'
        );
        this.element.querySelector('.compliance-uploading-5').textContent = i18next.t(
            'compliance-policy.section-uploading-bullet-point-five'
        );

        this.element.querySelector('.privacy-policy-link').href = this.parentModal.privacyPolicyUrl;

        this.cacheElements();
        this.bindCallbacks();
        this.addEventListeners();
    }

    /**
     * Cache our elements in the DOM manager
     */
    cacheElements() {
        this.domManager.set('compliance-modal-close-button', this.element.querySelector('.button-close'));
        this.domManager.set('compliance-modal-done-button', this.element.querySelector('.button-done'));
    }

    /**
     * Bind methods to wrapper functions
     * to use as callbacks in event listeners
     */
    bindCallbacks() {
        this.boundClose = () => this.close();
    }

    /**
     * Add event listeners for this modal
     */
    addEventListeners() {
        Dom.on(this.domManager.get('compliance-modal-close-button'), 'click', this.boundClose);
        Dom.on(this.domManager.get('compliance-modal-done-button'), 'click', this.boundClose);
        Dom.on(this.domManager.get('modal'), 'click', this.stopBubble);
        Dom.on(this.domManager.get('modal-wrapper'), 'click', this.boundClose);
    }

    /**
     * Remove the event listeners for this modal
     */
    removeEventListeners() {
        Dom.off(this.domManager.get('compliance-modal-close-button'), 'click', this.boundClose);
        Dom.off(this.domManager.get('compliance-modal-done-button'), 'click', this.boundClose);
        Dom.off(this.domManager.get('modal'), 'click', this.stopBubble);
        Dom.off(this.domManager.get('modal-wrapper'), 'click', this.boundClose);
    }

    /**
     * Close the parent modal and cleanup instance
     */
    close() {
        this.parentModal.close(this.type);
        this.destroy();
    }

    /**
     * Stop event bubbling
     * @param {Event} event
     */
    stopBubble(event) {
        event.stopPropagation();
    }

    /**
     * Uncache elements, remove its listeners
     * and remove the element from the DOM
     */
    destroy() {
        this.removeEventListeners();
        this.domManager.remove('compliance-modal-close-button');
        this.domManager.remove('compliance-modal-done-button');

        // remove elements from the DOM
        this.domManager.get('modal').removeChild(this.element);
    }
}
