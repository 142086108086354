const ASSET_LINK_PREFIX = 'showpad://';

/**
 * Extracts the useful info from an asset-link
 */
export function extractAssetLinkInfoFromUrl(link) {
    const info = {};
    const regexp = new RegExp('\\b(profile|folder|file)/([a-z0-9-]+)', 'i');
    let results;

    // Validate prefix
    if (link.indexOf(ASSET_LINK_PREFIX) !== 0) {
        throw new Error(link + ' is not a valid asset link');
    }

    // Split into parts
    const queryString = (link.split('?')[1] || '').toLowerCase(); // query string is case insensitive
    link = link.split('?')[0]; // profile/folder/file is case insensitive, the value is not!

    // Extract usefull parts from the link
    while ((results = regexp.exec(link)) !== null) {
        if (results.length === 3) {
            info[results[1].toLowerCase()] = results[2];
        }

        link = link.replace(results[0], '');
    }

    // Extra extra params from queryString
    const queryKeyValue = queryString.split('&').reduce((acc, curr) => {
        acc[curr.split('=')[0]] = curr.split('=')[1];
        return acc;
    }, {});

    if (queryKeyValue.page !== undefined) {
        info.page = parseInt(queryKeyValue.page, 10);
    }

    if (queryKeyValue.nodeid !== undefined) {
        info.nodeId = queryKeyValue.nodeid;
    }

    if (queryKeyValue.modal === '1' || queryKeyValue.modal === 'true') {
        info.modal = true;
    }

    return info;
}
