/**
 * Simple event bus for an application. Listeners are attached using the
 * `on` and `off` methods. To raise an event, the `dispatch` method shall be
 * used.
 */
export default class EventBus {
    constructor() {
        this.listeners = {};
    }

    on(eventName, listener) {
        let eventListeners = this.listeners[eventName];
        if (!eventListeners) {
            eventListeners = [];
            this.listeners[eventName] = eventListeners;
        }
        eventListeners.push(listener);
    }

    off(eventName, listener) {
        const eventListeners = this.listeners[eventName];
        const i = eventListeners.indexOf(listener);
        if (!eventListeners || i < 0) {
            return;
        }
        eventListeners.splice(i, 1);
    }

    dispatch(eventName) {
        const eventListeners = this.listeners[eventName];
        if (!eventListeners || eventListeners.length === 0) {
            return;
        }
        const args = Array.prototype.slice.call(arguments, 1);
        eventListeners.slice(0).forEach(listener => listener.apply(null, args));
    }
}
