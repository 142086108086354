function _template() {return `<div class="alert alert--inline alert--success toast">
    <div class="icon--alert">
        <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewbox="0 0 512 512">
            <path
                d="M256 .5C114.893.5.5 114.893.5 256S114.893 511.5 256 511.5 511.5 397.107 511.5 256 397.107.5 256 .5zm-44.636 361.958l-88.755-93.824 28.993-29.771 59.762 64.952 149.031-154.273 28.993 28.869-178.024 184.047z"
            />
        </svg>
    </div>
    <div class="alert__container">
        <span class="alert__title toast-message"></span>
    </div>
</div>
`;}export default function (ctx) {return _template.call(ctx, ctx);};