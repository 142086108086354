const isAndroid = function() {
    return navigator.userAgent.match(/Android/i);
};

const isIOS = function() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
};

const isWindowsPhone = function() {
    return navigator.userAgent.match(/IEMobile/i);
};

const isMobile = function() {
    return isAndroid() || isIOS() || isWindowsPhone();
};

export { isAndroid, isIOS, isWindowsPhone, isMobile };
