import DOMPurify from 'dompurify';
import i18next from 'i18next';
import DateHelper from '../../../helpers/date';
import Dom from '../../../helpers/dom-helper';
import { getInitials } from '../../../helpers/name.helper';
import aboutTemplate from './about-modal.html';

export default class AboutModal {
    constructor(parentModal, data) {
        this.type = 'about';
        this.element = null;
        this.parentModal = parentModal;
        this.domManager = this.parentModal.domManager;
        this.eventLogger = this.parentModal.eventLogger || null;
        this.showcaseViewer = data.showcaseViewer;
        this.info = null;

        this.init();
    }

    /**
     * Initialize the about modal
     */
    init() {
        this.render();
    }

    /**
     * Render the about content of the modal
     */
    render() {
        if (this.element !== null) {
            throw new Exception('AboutModal already rendered');
        }

        this.parseInfo();
        const ABOUT_MODAL_TEMPLATE = aboutTemplate(this.info);

        this.element = Dom.createElement(ABOUT_MODAL_TEMPLATE);
        this.domManager.get('modal').appendChild(this.element);

        this.element.querySelector('.showcase-creator-role').textContent = `${
            this.info.creator.companyRole ? this.info.creator.companyRole + ', ' : ''
        } ${this.info.creator.company || this.info.organisation.name}`;
        this.element.querySelector('.showcase-creator-phone').textContent = this.info.creator.phoneNumber || '';
        this.element.querySelector('.showcase-creator-avatar').textContent = this.info.creator.initials;
        this.element.querySelector('.showcase-creator-name').textContent = this.info.creator.name;
        this.element.querySelector('.showcase-creator-email').textContent = this.info.creator.email;

        this.element.querySelector('.modal-header-title').textContent = i18next.t('about');
        this.element.querySelector('.creator-info .section-title').textContent = i18next.t('sent-on-date', { date: this.info.sharedAt });
        this.element.querySelector('.info-wrapper .section-title').textContent = i18next.t('about');
        this.element.querySelector('#privacy-policy').textContent = i18next.t('entity.privacy-policy');

        this.cacheElements();
        this.bindCallbacks();
        this.addEventListeners();
    }

    /**
     * Parse sharing info (parse date, replace \n with html break, etc ..)
     */
    parseInfo() {
        let hasCustomPrivacyDisclaimer =
            this.showcaseViewer.hasCustomPrivacyDisclaimer !== undefined ? this.showcaseViewer.hasCustomPrivacyDisclaimer : false;

        const sanitizedCreator = Object.keys(this.showcaseViewer.creator).reduce((acc, key) => {
            acc[key] = DOMPurify.sanitize(this.showcaseViewer.creator[key]);
            return acc;
        }, {});

        this.info = {
            sharedAt: DateHelper.timestampToFormattedDate(this.showcaseViewer.sharedAt),
            creator: {
                ...sanitizedCreator,
                initials: getInitials(sanitizedCreator.firstName, sanitizedCreator.lastName, sanitizedCreator.email)
            },
            organisation: this.showcaseViewer.organisation,
            theme: this.showcaseViewer.theme,
            showPrivacyPolicy: this.showcaseViewer.showPrivacyPolicy && !hasCustomPrivacyDisclaimer,
            privacyPolicyUrl:
                (this.showcaseViewer.consent && this.showcaseViewer.consent.privacyPolicyUrl) || 'http://www.showpad.com/privacy-policy'
        };

        if (this.info && this.info.theme && this.info.theme.biography) {
            this.info.theme.biographySafe = Dom.linkify(Dom.htmlEntities(this.info.theme.biography).replace(/\n/g, '<br>'));
        } else {
            // set this to empty string
            // otherwise "undefined" will be rendered
            this.info.theme.biographySafe = '';
        }
    }

    /**
     * Cache our elements in the DOM manager
     */
    cacheElements() {
        this.domManager.set('about-modal-privacy', this.element.querySelector('.privacy-info-actions'));
        this.domManager.set('about-modal-close-button', this.element.querySelector('.button-close'));
    }

    /**
     * Bind methods to wrapper functions
     * to use as callbacks in event listeners
     */
    bindCallbacks() {
        this.boundClose = () => this.close();
    }

    /**
     * Add event listeners for this modal
     */
    addEventListeners() {
        Dom.on(this.domManager.get('about-modal-close-button'), 'click', this.boundClose);
        Dom.on(this.domManager.get('modal'), 'click', this.stopBubble);
        Dom.on(this.domManager.get('modal-wrapper'), 'click', this.boundClose);
    }

    /**
     * Remove the event listeners for this modal
     */
    removeEventListeners() {
        Dom.off(this.domManager.get('about-modal-close-button'), 'click', this.boundClose);
        Dom.off(this.domManager.get('modal'), 'click', this.stopBubble);
        Dom.off(this.domManager.get('modal-wrapper'), 'click', this.boundClose);
    }

    /**
     * Close the parent modal and cleanup instance
     */
    close() {
        this.parentModal.close(this.type);
        this.destroy();
    }

    /**
     * Stop event bubbling
     * @param {Event} event
     */
    stopBubble(event) {
        event.stopPropagation();
    }

    /**
     * Uncache elements, remove its listeners
     * and remove the element from the DOM
     */
    destroy() {
        this.removeEventListeners();
        this.domManager.remove('about-modal-privacy');
        this.domManager.remove('about-modal-close-button');

        // remove elements from the DOM
        this.domManager.get('modal').removeChild(this.element);
    }
}
