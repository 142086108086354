import { v3 as uuidV3 } from 'uuid';
import Url from '../helpers/url-helper';
import Storage from './storage';

const VALUE_NOT_FOUND = -1;
const CONTENT_TYPE_JSON = 'application/json';
const FETCH_METHOD_POST = 'post';
const DEVICE_ID_KEY = 'deviceId'; // should be the same as web-app, to re-use/identify a regular user's device
const CREATE_DEVICE_EVENT_MAX_RETRY_ATTEMPTS = 2;
const UPDATE_DEVICE_EVENT_MAX_RETRY_ATTEMPTS = 2;

export const DEVICE_EVENT_CONTEXT = {
    SHOWCASE: 'onlinecatalog',
    SHOWCASE_ITEM: 'contactsessionasset'
};

export const DEVICE_EVENT_ACTION = {
    VIEWED: 'viewed',
    DOWNLOADED: 'download',
    RESHARED: 'reshared',
    CHILDVIEW: 'childview'
};

// Result of `toUTF8Array('showpad.eingress')` see comments https://jira.showpad.io/browse/FEAT-2379
const UUID_NAMESPACE = 'ffa8959d-1e33-3974-890d-405c3ab6fb1a';

/**
 * Default DeviceEvent, has a context, action and a start and end time
 */
class DeviceEvent {
    /* eslint-disable max-params */
    /**
     * @param {String} shareHash
     * @param {String} context
     * @param {String} action
     * @param {Number} timestampStart
     * @param {Number} timestampEnd
     */
    constructor(shareHash, context, action, timestampStart = null, timestampEnd = null) {
        /* eslint-enable max-params */

        this.shareHash = shareHash;
        this.context = context;
        this.action = action;
        this.begin = timestampStart;
        this.end = timestampEnd;
    }
}

/**
 * ShowcaseViewed DeviceEvent, sets correct context and action.
 * Has showcase related info
 */
export class ShowcaseViewedDeviceEvent extends DeviceEvent {
    /**
     * @param {String} shareHash
     * @param {Number} timestampStart
     * @param {Number} timestampEnd
     */
    constructor(shareHash, timestampStart = null, timestampEnd = null) {
        super(shareHash, DEVICE_EVENT_CONTEXT.SHOWCASE, DEVICE_EVENT_ACTION.VIEWED, timestampStart, timestampEnd);
    }

    /**
     * Build a ShowcaseViewed DeviceEvent from data
     * @param {Object} data
     * @returns {ShowcaseViewedDeviceEvent}
     */
    static build(data) {
        return new ShowcaseViewedDeviceEvent(data.shareHash, data.start, data.end);
    }
}

/**
 * ItemViewed DeviceEvent, sets correct context and action.
 * When an items has been viewed
 * Has showcase item related info
 */
export class ItemViewedDeviceEvent extends DeviceEvent {
    /**
     * @param {String} shareHash
     * @param {Number} timestampStart
     * @param {Number} timestampEnd
     */
    constructor(shareHash, timestampStart = null, timestampEnd = null) {
        super(shareHash, DEVICE_EVENT_CONTEXT.SHOWCASE_ITEM, DEVICE_EVENT_ACTION.VIEWED, timestampStart, timestampEnd);

        this.assetPosition = null;
    }

    /**
     * Build a ShowcaseAssetView DeviceEvent from data
     * @param {Object} data
     * @returns {ShowcaseViewedDeviceEvent}
     */
    static build(data) {
        const event = new ItemViewedDeviceEvent(data.shareHash, data.start, data.end);

        event.assetPosition = data.itemPosition;
        event.page = data.page;
        event.shareHash = data.shareHash;

        return event;
    }
}

/**
 * Device event tracking item download
 */
export class ItemDownloadDeviceEvent extends DeviceEvent {
    /**
     * @param {String} shareHash
     * @param {Number} timestampStart
     * @param {Number} timestampEnd
     */
    constructor(shareHash, timestampStart = null, timestampEnd = null) {
        super(shareHash, DEVICE_EVENT_CONTEXT.SHOWCASE_ITEM, DEVICE_EVENT_ACTION.DOWNLOADED, timestampStart, timestampEnd);

        this.assetPosition = null;
    }

    /**
     * Build a ShowcaseViewed DeviceEvent from data
     * @param {Object} data
     * @returns {ShowcaseViewedDeviceEvent}
     */
    static build(data) {
        const event = new ItemDownloadDeviceEvent(data.shareHash, data.start, data.end);
        event.assetPosition = data.itemPosition;

        return event;
    }
}

/**
 * ChildView DeviceEvent, sets correct context and action.
 * When a page of a webapp has been viewed
 * Has showcase item related info
 */
export class ChildViewDeviceEvent extends DeviceEvent {
    /**
     * @param {String} shareHash
     * @param {Number} timestampStart
     */
    constructor(shareHash, timestampStart = null) {
        super(shareHash, DEVICE_EVENT_CONTEXT.SHOWCASE_ITEM, DEVICE_EVENT_ACTION.CHILDVIEW, timestampStart);
    }

    /**
     * Build a ShowcaseAssetView DeviceEvent from data
     * @param {Object} data
     * @returns {ShowcaseViewedDeviceEvent}
     */
    static build(data) {
        const event = new ChildViewDeviceEvent(data.shareHash, data.start);

        event.metadata = { pageLocation: data.pageLocation, assetId: data.assetId };
        event.parentId = data.parentId;
        event.assetPosition = data.assetPosition;

        return event;
    }
}

/**
 * Device Events helper class.
 * Provides static methods for creating/updating device events
 */
export default class DeviceEvents {
    /**
     * Create a device event, returns the event after completing
     * @param {DeviceEvent[]|DeviceEvent} deviceEvent
     * @param startSessionTimestamp number
     * @returns {Promise.<Object>}
     */
    static createDeviceEvent(deviceEvent, startSessionTimestamp, extraHeaders = {}, retryAttempt = 0) {
        const url = Url.getUrlWithParams('device-event-create');
        const deviceEvents = Array.isArray(deviceEvent) ? deviceEvent : [deviceEvent];

        return DeviceEvents.getDeviceId()
            .then(deviceId => {
                const sessionId = DeviceEvents.getSessionId(deviceId, startSessionTimestamp);
                const options = {
                    method: FETCH_METHOD_POST,
                    credentials: 'include',
                    headers: {
                        Accept: CONTENT_TYPE_JSON,
                        'Content-Type': CONTENT_TYPE_JSON,
                        'X-SHOWPAD-SESSION-ID': sessionId,
                        ...extraHeaders
                    },
                    body: JSON.stringify(
                        deviceEvents.map(event => {
                            event.deviceId = deviceId;
                            return event;
                        })
                    )
                };
                return fetch(url, options);
            })
            .then(httpResponse => httpResponse.json())
            .then(jsonResponse => {
                if (!jsonResponse.success) {
                    const retry = !jsonResponse.hasOwnProperty('retry') || jsonResponse.retry;
                    if (retry && retryAttempt >= CREATE_DEVICE_EVENT_MAX_RETRY_ATTEMPTS) {
                        throw new Error(`Failed to send 'Create' device event after ${retryAttempt} attempts`);
                    } else if (retry) {
                        return DeviceEvents.createDeviceEvent(deviceEvent, startSessionTimestamp, extraHeaders, retryAttempt + 1);
                    }
                }
                return jsonResponse.data[0];
            });
    }

    /**
     * Create a device event, async: not returning any event data
     * @param {DeviceEvent[]|DeviceEvent} deviceEvent
     * @param startSessionTimestamp number
     * @returns {Promise}
     */
    static createAsyncDeviceEvent(deviceEvent, startSessionTimestamp, extraHeaders = {}) {
        // Add `storeAsynchronously` to the data, and proxy to createDeviceEvent without returning the promise;
        const deviceEvents = Array.isArray(deviceEvent) ? deviceEvent : [deviceEvent];
        return DeviceEvents.createDeviceEvent(
            deviceEvents.map(event => {
                event.storeAsynchronously = true;
                return event;
            }),
            startSessionTimestamp,
            extraHeaders
        ).then(() => null);
    }

    /**
     * Update an existing device event, this is always async
     * @param {DeviceEvent} deviceEvent
     * @param startSessionTimestamp number
     * @returns {Promise}
     */
    static updateDeviceEvent(deviceEvent, startSessionTimestamp, extraHeaders = {}, retryAttempt = 0) {
        // Backend requires this
        deviceEvent.eventId = deviceEvent.id;
        const url = Url.getUrlWithParams('device-event-update');

        return DeviceEvents.getDeviceId()
            .then(deviceId => {
                const sessionId = DeviceEvents.getSessionId(deviceId, startSessionTimestamp);

                const options = {
                    method: FETCH_METHOD_POST,
                    credentials: 'include',
                    headers: {
                        Accept: CONTENT_TYPE_JSON,
                        'Content-Type': CONTENT_TYPE_JSON,
                        'X-SHOWPAD-SESSION-ID': sessionId,
                        ...extraHeaders
                    },
                    body: JSON.stringify([deviceEvent])
                };

                return fetch(url, options);
            })
            .then(httpResponse => httpResponse.json())
            .then(jsonResponse => {
                if (!jsonResponse.success) {
                    const retry = !jsonResponse.hasOwnProperty('retry') || jsonResponse.retry;
                    if (retry && retryAttempt >= UPDATE_DEVICE_EVENT_MAX_RETRY_ATTEMPTS) {
                        throw new Error(`Failed to send 'Update' device event after ${retryAttempt} attempts`);
                    } else if (retry) {
                        return DeviceEvents.updateDeviceEvent(deviceEvent, startSessionTimestamp, extraHeaders, retryAttempt + 1);
                    }
                }
                return true;
            });
    }

    /**
     * Get the device id of the current user
     * This will try to get the id from local storage or create one if needed
     * @returns {Promise.<String>}
     */
    static getDeviceId() {
        return Storage.get(DEVICE_ID_KEY)
            .then(deviceId => {
                if (deviceId === null) {
                    throw new Error('no device id found');
                }

                return deviceId;
            })
            .catch(() =>
                DeviceEvents.createDevice()
                    .then(device => device.id)
                    .then(deviceId => {
                        Storage.set(DEVICE_ID_KEY, deviceId).catch(() => {});
                        return deviceId;
                    })
            );
    }

    /**
     * Create a new device for the user, based upon his/her browser info
     * @returns {Promise.<String>}
     */
    static createDevice() {
        return new Promise(createDeviceExecutor);

        /**
         * Execute the creation of the device
         * @param {function} resolve
         * @param {function} reject
         */
        function createDeviceExecutor(resolve, reject) {
            const data = {
                appDistributionType: 'release',
                appVersion: 1,
                appRevision: 0,
                model: window.navigator.userAgent,
                os: DeviceEvents.getUserOs(),
                type: 'browser',
                app: 'scv'
            };

            const options = {
                method: FETCH_METHOD_POST,
                credentials: 'include',
                headers: {
                    Accept: CONTENT_TYPE_JSON,
                    'Content-Type': CONTENT_TYPE_JSON
                },
                body: JSON.stringify(data)
            };

            fetch(Url.getUrlWithParams('device-create'), options)
                .then(response => response.json())
                .then(createDeviceSuccess)
                .catch(createDeviceFailure);

            /**
             * Callback when device was created
             * @param {Object} response
             */
            function createDeviceSuccess(response) {
                if (response.success === true) {
                    resolve(response.data);
                } else {
                    throw new Error('invalid json response');
                }
            }

            /**
             * Callback when no device could be created
             * @param {*} error
             */
            function createDeviceFailure(error) {
                reject(error);
            }
        }
    }

    /**
     * Get the OS version
     * @returns {string}
     */
    static getUserOs() {
        const model = window.navigator.userAgent;
        let os = 'windows';

        if (model.toLowerCase().indexOf('linux') > VALUE_NOT_FOUND) {
            os = 'linux';
        } else if (model.toLowerCase().indexOf('macintosh') > VALUE_NOT_FOUND || model.toLowerCase().indexOf('mac os') > VALUE_NOT_FOUND) {
            os = 'osx';
        }

        return os;
    }

    static getSessionId(deviceId, startSessionTimestamp) {
        if (!startSessionTimestamp) {
            throw new Error('No start session timestamp');
        }
        const subdomain = Url.getShowpadSubdomain();
        const startTime = Math.round(startSessionTimestamp / 1000);
        const idString = `${startTime}${deviceId}${subdomain}`;
        return uuidV3(idString, UUID_NAMESPACE);
    }

    static getEveId(shareHash, position, startTimestamp) {
        const subdomain = Url.getShowpadSubdomain();
        const startTime = Math.round(startTimestamp / 1000);
        const idString = `${startTime}${shareHash}${position}${subdomain}`;
        return uuidV3(idString, UUID_NAMESPACE);
    }
}

export const hasDeviceEventData = event => {
    return !!event && !!event.data && !!event.deviceEvent;
};
