import { STATIC_PATH } from '../../helpers/constants';
import { PdfJsDistService, PdfJsDistVersion } from '@showpad/asset-viewer-v2';

export class PdfJsService {
    constructor(flags) {
        this.pdfJsResourcesPromise = null;
        this.flags = flags;
    }

    loadPdfJsResources() {
        if (this.pdfJsResourcesPromise) {
            return this.pdfJsResourcesPromise;
        }
        const PDF_JS_PATH = require('!!file-loader?name=[name]-[hash].[ext]!@showpad/pdfjs-dist/build/pdf.min.js').default;
        const PDF_JS_WORKER_PATH = require('!!file-loader?name=[name]-[hash].[ext]!@showpad/pdfjs-dist/build/pdf.worker.min.js').default;
        const PDF_JS_TEXT_LAYER_BUILDER_CSS_PATH = require('!!file-loader?name=[name]-[hash].[ext]!@showpad/pdfjs-dist/web/text_layer_builder.css')
            .default;
        const PDF_JS_ANNOTATION_LAYER_BUILDER_CSS_PATH = require('!!file-loader?name=[name]-[hash].[ext]!@showpad/pdfjs-dist/web/annotation_layer_builder.css')
            .default;
        const PDF_JS_CMAPS_PATH = `${STATIC_PATH}cmaps/`;

        const PDF_JS_IE11_LEDGE_PATH = require('!!file-loader?name=pdfjs-dist-ie11-ledge/[name]-[hash].[ext]!@showpad/pdfjs-dist-ie11-ledge/build/pdf.min.js')
            .default;
        const PDF_JS_IE11_LEDGE_WORKER_PATH = require('!!file-loader?name=pdfjs-dist-ie11-ledge/[name]-[hash].[ext]!@showpad/pdfjs-dist-ie11-ledge/build/pdf.worker.min.js')
            .default;
        const PDF_JS_IE11_LEDGE_TEXT_LAYER_BUILDER_CSS_PATH = require('!!file-loader?name=pdfjs-dist-ie11-ledge/[name]-[hash].[ext]!@showpad/pdfjs-dist-ie11-ledge/web/text_layer_builder.css')
            .default;
        const PDF_JS_IE11_LEDGE_ANNOTATION_LAYER_BUILDER_CSS_PATH = require('!!file-loader?name=pdfjs-dist-ie11-ledge/[name]-[hash].[ext]!@showpad/pdfjs-dist-ie11-ledge/web/annotation_layer_builder.css')
            .default;
        const PDF_JS_IE11_LEDGE_CMAPS_PATH = `${STATIC_PATH}pdfjs-dist-ie11-ledge/cmaps/`;

        this.pdfJsResourcesPromise = Promise.resolve().then(() => {
            const pdfJsPreferredVersions = [];

            // Asset viewer will consider the preferred versions in specified order.
            pdfJsPreferredVersions.push({
                version: PdfJsDistVersion.Generic,
                src: PDF_JS_PATH,
                workerSrc: PDF_JS_WORKER_PATH,
                textLayerBuilderCssUrl: PDF_JS_TEXT_LAYER_BUILDER_CSS_PATH,
                annotationLayerBuilderCssUrl: PDF_JS_ANNOTATION_LAYER_BUILDER_CSS_PATH,
                cMapUrl: PDF_JS_CMAPS_PATH
            });

            pdfJsPreferredVersions.push({
                version: PdfJsDistVersion.IE11Ledge,
                src: PDF_JS_IE11_LEDGE_PATH,
                workerSrc: PDF_JS_IE11_LEDGE_WORKER_PATH,
                textLayerBuilderCssUrl: PDF_JS_IE11_LEDGE_TEXT_LAYER_BUILDER_CSS_PATH,
                annotationLayerBuilderCssUrl: PDF_JS_IE11_LEDGE_ANNOTATION_LAYER_BUILDER_CSS_PATH,
                cMapUrl: PDF_JS_IE11_LEDGE_CMAPS_PATH
            });

            const config = {
                versions: pdfJsPreferredVersions
            };
            return new PdfJsDistService(config).loadResources();
        });

        return this.pdfJsResourcesPromise;
    }

    getWorker() {
        return new window.pdfjsLib.PDFWorker();
    }
}
