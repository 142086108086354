export const ignoreErrors = [
    'ResizeObserver loop limit exceeded',
    'TypeError: Failed to fetch', // result of blocked/failed api requests, usually device events
    'TypeError: NetworkError when attempting to fetch resource.', // result of blocked/failed api requests, usually device events
    'TypeError: Network request failed',
    'TypeError: The network connection was lost.',
    'TypeError: The Internet connection appears to be offline.',
    'TypeError: cancelled',
    'UnhandledRejection: Non-Error promise rejection captured with value: '
];
