import { ViewerEventType } from '@showpad/asset-viewer-v2';
import { browser } from '../../helpers/browser';
import { DownloadHelper } from '../../helpers/download-helper';

const ACTION_BAR_HIDDEN_CLASS = 'action-bar-hidden';

export default class ActionBar {
    constructor(props) {
        this.container = props.container;
        this.assetViewer = props.assetViewer;
        this.eventLogger = props.eventLogger;
        this.viewer = this.assetViewer.viewer;
        this.eventBus = this.viewer.eventBus;
        this.config = this.viewer.config;
        this.hideActionBarTimeout = null;
        this.currentView = null;

        this.onFocusChange = this.onFocusChange.bind(this);
        this.onSetView = this.onSetView.bind(this);
        this.onViewportChange = this.onViewportChange.bind(this);
        this.onLayoutZoomChange = this.onLayoutZoomChange.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.downloadAsset = this.downloadAsset.bind(this);

        this.addEventListeners();
    }

    addEventListeners() {
        this.eventBus.on(ViewerEventType.FocusChange, this.onFocusChange);
        this.eventBus.on(ViewerEventType.SetView, this.onSetView);
        this.eventBus.on(ViewerEventType.ViewportChange, this.onViewportChange);
        this.eventBus.on(ViewerEventType.LayoutZoomChange, this.onLayoutZoomChange);
        this.container.onmousemove = this.onMouseMove;
        this.container.ontouchmove = this.onMouseMove;
    }

    render() {
        this.actionBar = this.createActionBar();
        this.container.appendChild(this.actionBar);
        this.show();
    }

    show() {
        clearTimeout(this.hideActionBarTimeout);
        this.actionBar.classList.remove(ACTION_BAR_HIDDEN_CLASS);
        this.setHideTimeout();
    }

    hide() {
        this.actionBar.classList.add(ACTION_BAR_HIDDEN_CLASS);
    }

    focus() {
        this.clearHideTimeout();
        this.actionBar.classList.remove(ACTION_BAR_HIDDEN_CLASS);
    }

    onMouseMove(event) {
        if (this.actionBar.contains(event.target)) {
            return this.focus();
        }
        this.show();
    }

    update(view) {
        if (view) {
            this.currentView = view;
            this.downloadContainer.style.display = view.assetViewerItem.isDownloadable === true ? 'flex' : 'none';
        }
        if (view && this.displayNameContainer) {
            this.displayNameContainer.textContent = view.assetViewerItem.displayName;
            this.displayNameContainer.title = view.assetViewerItem.displayName;

            this.currentPageContainer.innerHTML = view.page && view.numPages > 1 ? view.page : '';
            this.numPagesContainer.innerHTML = view.numPages && view.numPages > 1 ? '/' + view.numPages : '';
        }
        if (this.viewer && this.viewer.viewerLayout.canZoomIn && this.zoomInContainer) {
            this.zoomInContainer.classList.remove('disabled');
            this.zoomInContainer.onclick = this.onZoomInClick.bind(this);
        } else if (this.zoomInContainer) {
            this.zoomInContainer.classList.add('disabled');
            this.zoomInContainer.onclick = () => {};
        }

        if (this.viewer && this.viewer.viewerLayout.canZoomOut && this.zoomOutContainer) {
            this.zoomOutContainer.classList.remove('disabled');
            this.zoomOutContainer.onclick = this.onZoomOutClick.bind(this);
        } else if (this.zoomInContainer) {
            this.zoomOutContainer.classList.add('disabled');
            this.zoomOutContainer.onclick = () => {};
        }
    }

    clearHideTimeout() {
        clearTimeout(this.hideActionBarTimeout);
    }

    setHideTimeout() {
        this.hideActionBarTimeout = setTimeout(() => this.hide(), this.config.ACTION_BAR_HIDE_TIMEOUT);
    }

    onSetView(event) {
        const view = this.assetViewer.getAssetViewerViewComponentById(event.payload.assetViewerView.id);
        if (!this.actionBar) {
            this.render();
        }
        this.update(view);
    }

    onFocusChange(event) {
        const view = this.assetViewer.getAssetViewerViewComponentById(event.payload.assetViewerView.id);
        if (!this.actionBar) {
            this.render();
        }
        this.update(view);
    }

    onViewportChange() {
        if (!this.actionBar) {
            this.render();
        }
        this.show();
    }

    onLayoutZoomChange() {
        this.update();
    }

    createActionBar() {
        const element = (this.actionBar = document.createElement('div'));
        const infoContainer = document.createElement('div');
        infoContainer.classList.add('action-bar-info-container');
        const buttonContainer = document.createElement('div');
        buttonContainer.classList.add('action-bar-button-container');
        const pageContainer = document.createElement('div');
        pageContainer.classList.add('action-bar-page-container');
        const displayNameContainer = (this.displayNameContainer = document.createElement('div'));
        displayNameContainer.classList.add('action-bar-displayname');
        const currentPageContainer = (this.currentPageContainer = document.createElement('div'));
        currentPageContainer.classList.add('action-bar-page');
        const numPagesContainer = (this.numPagesContainer = document.createElement('div'));
        numPagesContainer.classList.add('action-bar-numpages');
        const previousContainer = (this.previousContainer = document.createElement('div'));
        previousContainer.classList.add('action-bar-previous');
        previousContainer.innerHTML = `
            <svg  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="512px"
                 height="512px" viewBox="0 0 512 512" enable-background="new 0 0 512 512" xml:space="preserve"><g id="5151e0c8492e5103c096af88a5270478"><path display="inline" d="M417.197,504.225L67.135,285.431c-25.9-16.188-25.9-42.673,0-58.86L417.197,7.776
                    c25.9-16.188,47.093-4.441,47.093,26.101v444.248C464.29,508.666,443.098,520.412,417.197,504.225z"></path></g></svg>
        `;
        previousContainer.onclick = () => this.viewer.previous();
        const nextContainer = (this.nextContainer = document.createElement('div'));
        nextContainer.classList.add('action-bar-next');
        nextContainer.innerHTML = `
            <svg  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="512px"
                 height="512px" viewBox="0 0 512 512" enable-background="new 0 0 512 512" xml:space="preserve"><g id="5151e0c8492e5103c096af88a526f803"><path display="inline" d="M444.865,285.434L94.797,504.229c-25.899,16.182-47.087,4.436-47.087-26.105V33.874
                    c0-30.542,21.188-42.288,47.087-26.095l350.068,218.794C470.766,242.755,470.766,269.24,444.865,285.434z"></path></g></svg>
        `;
        nextContainer.onclick = () => this.viewer.next();
        const zoomInContainer = (this.zoomInContainer = document.createElement('div'));
        zoomInContainer.classList.add('action-bar-zoom-in');
        zoomInContainer.innerHTML = `
            <svg  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="512px"
                 height="512px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g id="afcae5a789ccd549f9c6e2a2f80d1e54"><path style="display: inline;" d="M306.594,208.262H508.97v101.188H306.594v202.377H205.406V309.449H3.03V208.262h202.375V0.826
                    h101.188V208.262z"></path></g></svg>
        `;
        zoomInContainer.onclick = this.onZoomInClick.bind(this);
        const zoomOutContainer = (this.zoomOutContainer = document.createElement('div'));
        zoomOutContainer.classList.add('action-bar-zoom-out');
        zoomOutContainer.innerHTML = `
            <svg  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="512px"
                 height="512px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g id="afcae5a789ccd549f9c6e2a2f80d22a0"><path style="display: inline;" d="M511.5,204.9v102.2H0.5V204.9H511.5z"></path></g></svg>
        `;
        zoomOutContainer.onclick = this.onZoomOutClick.bind(this);
        const downloadContainer = (this.downloadContainer = document.createElement('div'));
        downloadContainer.classList.add('action-bar-download');
        downloadContainer.setAttribute('role', 'button');
        downloadContainer.setAttribute('aria-label', 'downloadButton');
        downloadContainer.setAttribute('aria-pressed', 'false');
        downloadContainer.innerHTML = `
            <svg  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="512px"
                 height="512px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g id="afcae5a789ccd549f9c6e2a2f80cccc2"><path style="display: inline;" d="M400.617,154.126L256,332.976l-144.617-178.85H204.9V0.825h102.2v153.301H400.617z
                     M460.399,154.126v102.2v204.401H51.6V256.326v-102.2l-51.1-51.1v51.1v102.2v255.5h511v-255.5v-102.2v-51.1L460.399,154.126z"></path></g></svg>
        `;
        downloadContainer.onclick = () => {
            this.downloadAsset();
        };
        const fullScreenContainer = (this.fullScreenContainer = document.createElement('div'));
        fullScreenContainer.classList.add('action-bar-fullscreen');
        fullScreenContainer.innerHTML = `
            <svg  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="512px"
                 height="512px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g id="afcae5a789ccd549f9c6e2a2f8071638"><path style="display: inline;" d="M391.928,180.997L180.994,391.931l119.567,119.566H0.503L0.5,211.431
                    l119.566,119.567l210.939-210.926L211.431,0.503H511.5v300.061L391.928,180.997z"></path></g></svg>
        `;
        fullScreenContainer.onclick = () => this.assetViewer.toggleFullscreen();
        pageContainer.appendChild(currentPageContainer);
        pageContainer.appendChild(numPagesContainer);
        infoContainer.appendChild(displayNameContainer);
        infoContainer.appendChild(pageContainer);
        if (!browser.mobile) {
            buttonContainer.appendChild(zoomOutContainer);
            buttonContainer.appendChild(zoomInContainer);
        }
        buttonContainer.appendChild(downloadContainer);
        if (!browser.mobile) {
            buttonContainer.appendChild(fullScreenContainer);
        }
        element.appendChild(infoContainer);
        element.appendChild(buttonContainer);
        element.classList.add('action-bar');
        element.classList.add(ACTION_BAR_HIDDEN_CLASS);
        this.config.actionBarPositionTop ? element.classList.add('action-bar-top') : element.classList.add('action-bar-bottom');
        return element;
    }

    onZoomInClick() {
        this.viewer.viewerLayout.zoomIn();
        this.update();
    }

    onZoomOutClick() {
        this.viewer.viewerLayout.zoomOut();
        this.update();
    }

    downloadAsset() {
        this.eventLogger.trackItemDownload(this.currentView.assetViewerItem);
        DownloadHelper.download(this.currentView.assetViewerItem.downloadUrl, this.currentView.assetViewerItem.displayName);
    }
}
