import Dom from '../../helpers/dom-helper';
import toastSuccessTemplate from './html/toast-success.html';
import toastInfoTemplate from './html/toast-info.html';
import toastErrorTemplate from './html/toast-error.html';

class Toast {
    constructor(message, type) {
        this.element = Dom.createElement('<div></div>');
        this.element.innerHTML = this.getToastTemplate(type);

        const toastMessageEl = this.element.querySelector('.toast-message');
        toastMessageEl.innerHTML = message;
    }

    getToastTemplate(type) {
        switch (type) {
            case 'success':
                return toastSuccessTemplate();
            case 'info':
                return toastInfoTemplate();
            case 'error':
                return toastErrorTemplate();
            default:
                return toastSuccessTemplate();
        }
    }
}

export default class ToastService {
    constructor(toastContainer) {
        this.toastContainer = toastContainer;
    }

    showToast(message, type = 'success', hideTimeout = 2500, width) {
        const HIDE_ANIMATION_TIME = 300;
        const toast = new Toast(message, type);
        this.toastContainer.appendChild(toast.element);
        this.toastContainer.style.maxWidth = '90%';
        if (width) {
            this.toastContainer.style.width = width + 'px';
        }
        setTimeout(() => {
            Dom.addClass('fade-out', toast.element.querySelector('.toast'));
            setTimeout(() => {
                this.toastContainer.removeChild(toast.element);
            }, HIDE_ANIMATION_TIME);
        }, hideTimeout);
    }
}
