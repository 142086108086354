export class DownloadBar {
    constructor(eventBus) {
        this.eventBus = eventBus;
        this.downloadBar = document.querySelector('.download-bar');
        this.closeButton = this.downloadBar.querySelector('.close-button');
        this.closeButton.classList.remove('hide');
        this.closeButton.onclick = () => this.eventBus.dispatch('downloadBar:toggle', false);
    }

    setMessage(message) {
        this.downloadBar.querySelector('.download-bar-message').textContent = message;
    }

    toggle() {
        this.downloadBar.classList.toggle('open');
    }

    isOpen() {
        return this.downloadBar.classList.contains('open');
    }
}
