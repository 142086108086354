// The code in this file is a JavaScript copy of the "isValidLanguagesArray" and "getPreferredLanguage"-functions in the monorepo.

/**
 * Return whether the array passed in is a string[]
 * @param {string[]} languages
 * @returns {boolean}
 */
const isValidLanguagesArray = languages => Array.isArray(languages) && languages.every(language => typeof language === 'string');

/**
 * Return the user's preferred language
 *
 * @param {string[]} whitelist allowed languages
 * @param {string} preferredLanguage user single preferred language
 * @param {string[]} languages user preferred languages sorted by descending priority (doesn't work in IE11)
 * @returns {string}
 */
function getPreferredLanguage(whitelist, preferredLanguages) {
    const fallbackLanguage = 'en';

    if (!(isValidLanguagesArray(whitelist) && isValidLanguagesArray(preferredLanguages))) {
        return fallbackLanguage;
    }

    const simpleWhitelist = whitelist.map(lng => lng.split('-')[0]);
    const simpleLanguages = preferredLanguages.map(lng => lng.split('-')[0]);

    const match = preferredLanguages.find(lng => whitelist.includes(lng));
    const simpleMatch = simpleLanguages.find(lng => simpleWhitelist.includes(lng));
    const index = preferredLanguages.indexOf(match);
    const simpleIndex = simpleLanguages.indexOf(simpleMatch);

    if (simpleIndex === -1 && index === -1) {
        return fallbackLanguage;
    }
    if (simpleIndex === -1 || index === -1) {
        return simpleIndex > index ? whitelist[simpleWhitelist.indexOf(simpleMatch)] : match;
    }

    return simpleIndex >= index ? match : whitelist[simpleWhitelist.indexOf(simpleMatch)] || fallbackLanguage;
}

module.exports = {
    getPreferredLanguage
};
