export class DownloadHelper {
    static download(url, filename) {
        let isIOS = /iPad|iPhone|iPod/.test(navigator.platform);
        if (isIOS) {
            fetch(url)
                .then(response => response.blob())
                .then(blob => {
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = filename;
                    link.click();
                })
                .catch(console.error);
        } else {
            // setTimeout is required as a workaround for this issue >
            //
            // "Fetch requests inside onClick abort when downloading a file in Firefox"
            // https://stackoverflow.com/questions/57542425/fetch-requests-inside-onclick-abort-when-downloading-a-file-in-firefox
            //
            // This basically allows us to record device events and download files at the same time.
            // We found this issue was affecting Firefox and Safari browser
            setTimeout(() => {
                const downloadElement = document.createElement('a');
                downloadElement.setAttribute('href', url);
                downloadElement.style.display = 'none';
                document.body.appendChild(downloadElement);
                downloadElement.click();
                document.body.removeChild(downloadElement);
            }, 10);
        }
    }
}
