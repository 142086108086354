import Dom from './dom-helper.js';

const KEY_CODE_ESC = 27;

// NOTE: IE 11 uses upper-camel-case for this, which is apparently necessary
const FULLSCREENCHANGE_EVENT = ['fullscreenchange', 'webkitfullscreenchange', 'mozfullscreenchange', 'MSFullscreenChange'];
const FAKE_FULLSCREEN_CLASS_NAME = 'fake-fullscreen';
let fullscreenChangeListeners = [];
let listenerInitialized = false;
let isFakeFullscreen = false;
let fakeFullscreenElement = null;

/**
 * Initialize listener for fullscreen change
 */
function initListener() {
    if (!listenerInitialized) {
        FULLSCREENCHANGE_EVENT.forEach(eventName => Dom.on(document, eventName, fullscreenChangeHandler));
        listenerInitialized = true;
    }
}

/**
 * On fullscreen change handler, call all listeners
 */
function fullscreenChangeHandler() {
    fullscreenChangeListeners.forEach(callback => callback.call());
}

/**
 * Enter fake fullscreen mode
 * @param {Element} element
 */
function fakeRequestFullscreen(element) {
    // Use document.body instead of dom-manager, we don't have an instance here
    Dom.on(document.body, 'keydown', fakeFullscreenKeydownHandler);
    Dom.addClass(FAKE_FULLSCREEN_CLASS_NAME, element);
    Dom.addClass(FAKE_FULLSCREEN_CLASS_NAME, document.body);

    isFakeFullscreen = true;
    fakeFullscreenElement = element;

    fullscreenChangeHandler();
}

/**
 * Exit the fake fullscreen mode
 */
function fakeCancelFullscreen() {
    Dom.off(document, 'keydown', fakeFullscreenKeydownHandler);
    Dom.removeClass(FAKE_FULLSCREEN_CLASS_NAME, fakeFullscreenElement);
    Dom.removeClass(FAKE_FULLSCREEN_CLASS_NAME, document.body);

    isFakeFullscreen = false;
    fakeFullscreenElement = null;

    fullscreenChangeHandler();
}

/**
 * Callback on key down when in fake fullscreen mode
 * @param {event} event
 */
function fakeFullscreenKeydownHandler(event) {
    if (event.keyCode === KEY_CODE_ESC) {
        fakeCancelFullscreen();
    }
}

/**
 * Get the fullscreen method
 * @param {HTMLElement} element
 * @returns {function}
 */
function getRequestFullscreenMethod(
    element // eslint-disable-line complexity
) {
    let method = fakeRequestFullscreen;

    if (typeof element.requestFullScreen === 'function') {
        method = element.requestFullscreen.bind(element);
    } else if (typeof element.mozRequestFullScreen === 'function') {
        method = element.mozRequestFullScreen.bind(element);
    } else if (typeof element.webkitRequestFullScreen === 'function') {
        method = element.webkitRequestFullScreen.bind(element);
    } else if (typeof element.msRequestFullscreen === 'function') {
        method = element.msRequestFullscreen.bind(element);
    }

    return method;
}

/**
 * Get the method to exit fullscreen, depending on the implementation
 * @returns {Function}
 *
 */
function getExitFullscreenMethod() {
    // eslint-disable-line complexity
    let method = fakeCancelFullscreen;

    if (typeof document.cancelFullScreen === 'function') {
        method = document.cancelFullScreen.bind(document);
    } else if (typeof document.exitFullscreen === 'function') {
        method = document.exitFullscreen.bind(document);
    } else if (typeof document.mozCancelFullScreen === 'function') {
        method = document.mozCancelFullScreen.bind(document);
    } else if (typeof document.webkitCancelFullScreen === 'function') {
        method = document.webkitCancelFullScreen.bind(document);
    } else if (typeof document.msExitFullscreen === 'function') {
        method = document.msExitFullscreen.bind(document);
    }

    return method;
}

export default class FullscreenHelper {
    /**
     * Request fullscreen modus for the specified element
     * @param {HTMLElement} element
     */
    static enterFullscreen(element) {
        initListener();
        getRequestFullscreenMethod(element)(element);
    }

    /**
     * Leave fullscreen view
     */
    static exitFullscreen() {
        initListener();
        getExitFullscreenMethod()();
    }

    /**
     * Add a listener for fullscreen change
     * @param {function} listener
     */
    static addFullscreenChangeListener(listener) {
        initListener();
        fullscreenChangeListeners.push(listener);
    }

    /**
     * Remove a listener for fullscreen change
     * @param {function} listener
     */
    static removeFullscreenChangeListener(listener) {
        fullscreenChangeListeners = fullscreenChangeListeners.filter(callback => callback !== listener);
    }

    /**
     * Check if native fullscreen is supported
     * @returns {boolean}
     */
    static isFullscreenSupported() {
        // eslint-disable-line complexity
        return (
            typeof document.body.requestFullScreen === 'function' ||
            typeof document.body.mozRequestFullScreen === 'function' ||
            typeof document.body.webkitRequestFullScreen === 'function' ||
            typeof document.body.msRequestFullscreen === 'function'
        );
    }

    /**
     * Check if in fullscreen mode
     * @returns {boolean}
     */
    static isFullscreen() {
        // eslint-disable-line complexity
        return (
            (document.fullScreenElement ||
                document.webkitFullscreenElement ||
                document.mozFullScreenElement ||
                document.msFullscreenElement ||
                isFakeFullscreen) !== false
        );
    }
}
