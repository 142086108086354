import i18next from 'i18next';
import DeviceEvent from '../../../helpers/device-event.helper';
import Dom from '../../../helpers/dom-helper';
import Http from '../../../helpers/http';
import { Defer } from '../../../helpers/promise';
import Url from '../../../helpers/url-helper';
import ToastService from '../../toast/toast';
import shareTemplate from './share-modal.html';
import tagsInput from './tags-input-es6.js';

const CONTENT_TYPE_FORM_URLENCODED = 'application/x-www-form-urlencoded';
const CONTENT_TYPE_JSON = 'application/json';
const FETCH_METHOD_POST = 'post';

const SHARE_MODAL_TEMPLATE = shareTemplate();

export default class ShareModal {
    constructor(parentModal) {
        this.type = 'share';
        this.parentModal = parentModal;
        this.confirmBeforeClose = true;
        this.domManager = this.parentModal.domManager;
        this.eventLogger = this.parentModal.eventLogger || null;

        this.toastService = new ToastService(this.domManager.get('toast-container'));

        this.element = null;

        this.render();
    }

    /**
     * Render the modal in the DOM
     * @returns {Promise}
     */
    render() {
        const renderDefer = new Defer();
        const modalAnimationTimeout = 50;
        let result = Promise.resolve();

        if (this.element !== null) {
            throw new Exception('ShareModal already rendered');
        }

        this.element = Dom.createElement(SHARE_MODAL_TEMPLATE);
        this.domManager.get('modal').appendChild(this.element);

        const recipients = this.element.querySelector('.share-recipients');

        this.domManager.set('share-modal', this.element);
        this.domManager.set('share-modal-close-button', this.element.querySelector('.button-close'));
        this.domManager.set('share-modal-form', this.element.querySelector('.share-modal-form'));
        this.domManager.set('share-modal-textarea', this.element.querySelector('.share-body'));
        this.domManager.set('share-modal-recipients', recipients);
        this.domManager.set('share-modal-send-button', this.element.querySelector('.button-send'));
        this.tagsInputEmail = tagsInput(this.element.querySelector('.tags-input-email'));

        this.element.querySelector('.modal-header-title').textContent = i18next.t('share-modal.title');
        this.element.querySelector('.share-error').textContent = i18next.t('share-error');
        this.element.querySelector('#tags-input-email__label').textContent = i18next.t('entity.recipient_plural');
        this.element.querySelector('#tags-input-message__label').textContent = i18next.t('entity.message');
        this.element.querySelector('.tags-input').input.placeholder = i18next.t('entity.email-address');
        this.element.querySelector('.button-send span').textContent = i18next.t('action.send');
        this.element.querySelector('.reshare-policy').innerHTML = i18next.t('share-modal.reshare-policy');

        this.domManager.set('compliance-policy-link', this.element.querySelector('.compliance-policy-link'));

        this.bindCallbacks();
        this.addEventListeners();

        // Don't focus until the modal is visible
        setTimeout(() => {
            this.tagsInputEmail.focus();
        }, modalAnimationTimeout);

        result = renderDefer.promise;

        return result;
    }

    /**
     * Bind methods to wrapper functions
     * to use as callbacks in event listeners
     */
    bindCallbacks() {
        this.boundClose = () => this.close();
        this.boundShare = event => this.share(event);
        this.boundShowComplianceModal = () => this.parentModal.open('compliance');
    }

    /**
     * Add event listeners for this modal
     */
    addEventListeners() {
        Dom.on(this.domManager.get('share-modal-close-button'), 'click', this.boundClose);
        Dom.on(this.domManager.get('modal-wrapper'), 'click', this.boundClose);
        Dom.on(this.domManager.get('modal'), 'click', this.stopBubble);
        Dom.on(this.domManager.get('compliance-policy-link'), 'click', this.boundShowComplianceModal);
        Dom.on(this.domManager.get('share-modal-form'), 'submit', this.boundShare);
    }

    /**
     * Remove the event listeners for this modal
     */
    removeEventListeners() {
        Dom.off(this.domManager.get('share-modal-close-button'), 'click', this.boundClose);
        Dom.off(this.domManager.get('modal-wrapper'), 'click', this.boundClose);
        Dom.off(this.domManager.get('modal'), 'click', this.stopBubble);
        Dom.off(this.domManager.get('share-modal-form'), 'submit', this.boundShare);
    }

    /**
     * Close the parent modal and cleanup instance
     */
    close() {
        const self = this;
        const textMessageElement = this.domManager.get('share-modal-textarea');
        const recipients =
            this.tagsInputEmail
                .getValue()
                .split(',')
                .filter(Boolean) || [];
        const textMessage = textMessageElement.value.trim();

        if (canCloseModal) {
            closeModal();
        }

        /**
         * Close the modal
         */
        function closeModal() {
            self.parentModal.close(self.type);
            self.destroy();
        }

        /**
         * Verify if the modal can be closed
         * @returns {boolean}
         */
        function canCloseModal() {
            let canClose = true;

            if (self.confirmBeforeClose === true && (recipients.length > 0 || textMessage.length > 0)) {
                /* eslint-disable no-alert */
                canClose = window.confirm('Are you sure you want to leave? Your changes will be lost.');
                /* eslint-enable no-alert */
            }

            return canClose;
        }
    }

    /**
     * Stop event bubbling
     * @param {Event} event
     */
    stopBubble(event) {
        event.stopPropagation();
    }

    /**
     * Uncache elements, remove its listeners
     * and remove the element from the DOM
     */
    destroy() {
        this.removeEventListeners();
        this.domManager.remove('share-modal');
        this.domManager.remove('share-modal-close-button');
        this.domManager.remove('share-modal-form');
        this.domManager.remove('share-modal-textarea');
        this.domManager.remove('share-modal-recipients');
        this.domManager.remove('share-modal-send-button');
        this.domManager.remove('compliance-policy-link');

        // remove the element from the dom
        this.domManager.get('modal').removeChild(this.element);
    }

    /**
     * Share the showcase with other people
     * @param {Event} event
     */
    share(
        event // eslint-disable-line complexity
    ) {
        event.preventDefault();

        const recipientsElement = this.domManager.get('share-modal-recipients');
        const textMessageElement = this.domManager.get('share-modal-textarea');
        const recipients =
            this.tagsInputEmail
                .getValue()
                .split(',')
                .filter(Boolean) || [];
        const textMessage = textMessageElement.value.trim();

        const errors = [];

        // Validate recipients
        if (recipients.length === 0) {
            Dom.addClass('error', recipientsElement);
            errors.push('recipients');

            if (errors.length === 1) {
                this.tagsInputEmail.focus();
            }
        } else {
            Dom.removeClass('error', recipientsElement);
        }

        // Validate body
        if (textMessage.length === 0) {
            Dom.addClass('error', textMessageElement);
            errors.push('body');

            if (errors.length === 1) {
                textMessageElement.focus();
            }
        } else {
            Dom.removeClass('error', textMessageElement);
        }

        // Submit when there are no errors
        if (errors.length === 0) {
            this.domManager.get('share-modal-send-button').setAttribute('disabled', 'disabled');

            share()
                .then(() => {
                    this.tagsInputEmail.setValue('');
                    textMessageElement.value = '';

                    this.toastService.showToast(i18next.t('content-shared'));
                    this.close();
                })
                .catch(() => {
                    this.domManager.get('share-modal-send-button').removeAttribute('disabled');
                    Dom.addClass('has-share-error', this.domManager.get('share-modal'));
                });

            this.parentModal.eventBus.dispatch('track:share');
        }

        /**
         * Do the share fetch call
         * @returns {Promise.<T>}
         */
        function share() {
            return DeviceEvent.getDeviceId().then(deviceId => {
                const emails = JSON.stringify(recipients.map(recipient => ({ text: recipient })));
                const url = Url.getUrlWithParams('showcase-share');
                const shareOptions = {
                    method: FETCH_METHOD_POST,
                    credentials: 'include',
                    headers: {
                        Accept: CONTENT_TYPE_JSON,
                        'Content-Type': CONTENT_TYPE_FORM_URLENCODED
                    },
                    body: `recipients=${encodeURIComponent(emails)}&body=${encodeURIComponent(textMessage)}`
                };

                if (deviceId !== null) {
                    shareOptions.body += `&deviceId=${encodeURIComponent(deviceId)}`;
                }

                return Http.getJson(url, shareOptions).then(response => parseJsonResponse(response));
            });
        }

        /**
         * Parse the response returned by the server, if an incorrect response is returned, log and notifiy the user
         * @param {Object} jsonResponse
         */
        function parseJsonResponse(jsonResponse) {
            if (`${jsonResponse.success}` !== 'true') {
                this.eventLogger.notify(
                    // eslint-disable-line no-invalid-this
                    'could not share showcase',
                    jsonResponse.message,
                    { response: jsonResponse }
                );

                throw new Error('invalid json response');
            }
        }
    }
}
