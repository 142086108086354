import Dom from '../../helpers/dom-helper';
import { isCloseToBlack, isCloseToWhite } from '../../helpers/color.helper';

class ShowcaseTheme {
    constructor(domManager) {
        this.accentColor = null;
        this.fontColor = null;
        this.allowContentDownload = null;
        this.organisationName = null;
        this.logoUrl = null;
        this.promoImageUrl = null;
        this.biography = null;

        this.domManager = domManager;

        this.element = null;
    }

    /**
     * Render the style tag
     * @returns {Element}
     */
    render() {
        // eslint-disable-line
        if (this.element !== null) {
            throw new Error('Showcase Theme already rendered');
        }

        let element = null;

        if (this.element === null) {
            element = Dom.createElement('<style></style>');
        } else {
            element = this.element;
        }

        let consentFontColor = this.fontColor;
        if (isCloseToWhite(this.accentColor) && isCloseToWhite(this.fontColor)) {
            consentFontColor = '#2b2b2b';
        } else if (isCloseToBlack(this.accentColor) && isCloseToBlack(this.fontColor)) {
            consentFontColor = '#ffffff';
        }

        // Create style element
        element.innerHTML = `
            .header {
                background-color: ${this.accentColor};
                color: ${this.fontColor};
            }
            .header-actions-list .button { color: ${this.fontColor}; }
            .button-tint {
                background-color: ${this.accentColor} !important;
                color: ${this.fontColor};
                border: 1px solid ${this.fontColor};
            }
            .control--checkbox input[type="checkbox"]:checked + .control__indicator {
                background-color: ${this.accentColor} !important;
                border: 1px solid ${this.fontColor};
            }
            .control--checkbox .control__indicator:after {
                border-color: ${this.fontColor};
            }
            .revoked-svg {
                color: ${this.accentColor} !important;
            }

            .modal-consent .button-tint {
                color: ${consentFontColor} !important;
                border: 1px solid ${consentFontColor} !important;
            }
            .modal-consent .control--checkbox input[type="checkbox"]:checked + .control__indicator {
                border: 1px solid ${consentFontColor};
            }
            .modal-consent .control--checkbox .control__indicator:after {
                border-color: ${consentFontColor} !important;
            }
        `;

        // Update the page title to the company name title, some DOM setters
        this.domManager.get('title').innerHTML = `${this.organisationName} | Showpad`;

        if (this.element === null) {
            this.element = element;
        }

        this.domManager.get('body').appendChild(this.element);

        return this.element;
    }

    /**
     * Remove DOM object and references to the object
     */
    destroy() {
        document.querySelector('body').removeChild(this.element);
        this.element = null;
    }

    /**
     * Returns the current element
     * @returns {null|Element}
     */
    getElement() {
        return this.element;
    }

    getInstance() {
        return this;
    }

    /**
     * Create a theme component from given data
     * @param {Object} config
     * @param {Object} organisation
     * @param {DomManager} domManager
     * @returns {ShowcaseTheme}
     */
    static fromConfig(config, organisation, domManager) {
        const theme = new ShowcaseTheme(domManager);

        theme.accentColor = `#${config.accentColor}`;
        theme.fontColor = `#${config.fontColor}`;
        // Content color should never be used in showcase-client, reserved for Shared Spaces
        theme.allowContentDownload = config.allowContentDownload;
        theme.logoUrl = config.logoUrl;
        theme.promoImageUrl = config.promoImageUrl;
        theme.biography = config.biography;
        theme.organisationName = (organisation && organisation.name) || '';

        return theme;
    }
}

export default ShowcaseTheme;
