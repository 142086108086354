import RenderComponent from '../renderComponent';
import Dom from '../../helpers/dom-helper';
import privacyTemplate from './html/privacyWarning.html';
import Storage from '../../helpers/storage';

const STORAGE_KEY_PRIVACY_HIDDEN = 'privacy-warning-hidden';

export default class PrivacyWarning extends RenderComponent {
    constructor(element, parent, privacyContent) {
        super(element, parent);

        this.element.innerHTML = privacyTemplate();
        this.fetchDomElements();
        this.addEventListeners();
        this.privacyContent = privacyContent;
        this.domManager.get('privacy-content').innerHTML = this.privacyContent;
    }

    fetchDomElements() {
        this.domManager.set('privacy-close-button', this.element.querySelector('.hide-button'));
        this.domManager.set('privacy-content', this.element.querySelector('.privacy-content'));
    }

    addEventListeners() {
        Dom.on(this.domManager.get('privacy-close-button'), 'click', () => this.close());
    }

    render() {
        Dom.addClass('show-privacy-warning', this.parent);
    }

    close() {
        Storage.set(STORAGE_KEY_PRIVACY_HIDDEN, 'true');

        this.hide();
        Dom.removeClass('show-privacy-warning', this.parent);
    }

    static shouldShow() {
        return Storage.get(STORAGE_KEY_PRIVACY_HIDDEN)
            .catch(() => 'false')
            .then(value => {
                return value !== 'true';
            });
    }
}
