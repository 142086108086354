const SECONDS_IN_MINUTE = 60;
const SECONDS_TO_MILISECONDS = 1000;

export default class KeepAliveHelper {
    constructor(url, sessionTtl) {
        this.url = url;
        this.sessionTtl = sessionTtl;

        // Call keep alive to set timeout, don't do the real polling
        if (sessionTtl) {
            this.keepAlive(false);
        }
    }

    /**
     * Poll to keep the session alive
     */
    keepAlive(poll = true) {
        let promise = null;

        if (poll) {
            // Ignore issues from fetch, map to a successful promise
            promise = fetch(this.url).catch(() => Promise.resolve());
        } else {
            promise = Promise.resolve();
        }

        promise
            .then(() => {
                window.setTimeout(() => this.keepAlive(true), (this.sessionTtl - SECONDS_IN_MINUTE) * SECONDS_TO_MILISECONDS);
            })
            .catch(() => {
                throw new Error('error thrown by keep alive promise');
            });
    }
}
