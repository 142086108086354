import { Defer } from './promise';

/**
 * Do a post request to the ADN host with the identifier to get a valid cookie back we can use
 * @param {Object} adn
 * @param {Int} organisationId
 * @param {String} serverTime
 * @returns {Promise}
 */
export function authenticateWithAdn(adn, serverTime) {
    const authenticationDefer = new Defer();
    const request = new XMLHttpRequest();
    const clientTime = new Date().getTime();

    // With-credentials property needs to be after the `open` https://xhr.spec.whatwg.org/#the-withcredentials-attribute
    request.open('POST', `${adn.host}/cookie?ct=${clientTime}&st=${serverTime}`, true);
    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    request.withCredentials = true;

    // Verify data, only do a request if either org or identifier is set, do not send empty values!
    const hasAdnIdentifier = adn.hasOwnProperty('identifier') && typeof adn.identifier === 'string' && adn.identifier.length > 0;

    const data = [hasAdnIdentifier ? `SHOWPAD_SESSION=${adn.identifier}` : null].filter(value => value !== null);

    if (data.length > 0) {
        request.onreadystatechange = onReadyStateChange;
        request.send(data.join('&'));

        return authenticationDefer.promise;
    } else {
        return Promise.resolve();
    }

    /**
     * Callback when the request state changes
     */
    function onReadyStateChange() {
        if (request.readyState === 4) {
            authenticationDefer.resolve();
        }
    }
}
