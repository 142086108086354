import Dom from './dom-helper.js';

const PAGE_VISIBILITY_PROPERTY = ['hidden', 'mozHidden', 'msHidden', 'webkitHidden'];
const PAGE_VISIBILITY_EVENT = ['visibilitychange', 'mozvisibilitychange', 'msvisibilitychange', 'webkitvisibilitychange'];

const isPageVisibilitySupported = function() {
    return (
        typeof document.hidden !== 'undefined' ||
        typeof document.mozHidden !== 'undefined' ||
        typeof document.msHidden !== 'undefined' ||
        typeof document.webkitHidden !== 'undefined'
    );
};

const getPageVisibilityPropertyAndEvent = function() {
    let hidden = null;
    let event = null;

    if (typeof document.hidden !== 'undefined') {
        hidden = 'hidden';
        event = 'visibilitychange';
    } else if (typeof document.mozHidden !== 'undefined') {
        hidden = 'mozHidden';
        event = 'mozvisibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden';
        event = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden';
        event = 'webkitvisibilitychange';
    }

    return [hidden, event];
};

const getPageVisibilityPropertyName = function() {
    const [hidden, _] = getPageVisibilityPropertyAndEvent();

    return hidden;
};

const getPageVisibilityEventName = function() {
    const [hidden, event] = getPageVisibilityPropertyAndEvent();

    return event;
};

const isPageHidden = function() {
    const hiddenPropName = getPageVisibilityPropertyName();
    const isHidden = document[hiddenPropName];

    return isHidden;
};

const pageVisibilityChangedListener = action => {
    const eventName = getPageVisibilityEventName();

    Dom.on(document, eventName, action);
};

export { isPageVisibilitySupported, isPageHidden, pageVisibilityChangedListener };
