export const CONTENT_TYPE_JSON = 'application/json';

export default class Http {
    static getJson(url, config = { credentials: 'include' }) {
        if (!config.hasOwnProperty('contentType')) {
            config.contentType = CONTENT_TYPE_JSON;
        }

        return fetch(url, config).then(response => onResponse(response));

        /**
         * Callback when response is ready
         * @param {Object} httpResponse
         * @returns {Promise.<T>}
         */
        function onResponse(httpResponse) {
            let jsonPromise = null;

            // Correct http info call
            if (httpResponse.ok) {
                jsonPromise = httpResponse.json();
            } else {
                jsonPromise = Promise.reject(httpResponse);
            }

            return jsonPromise;
        }
    }

    static fetch(url, config = { credentials: 'include' }) {
        if (!config.hasOwnProperty('contentType')) {
            config.contentType = CONTENT_TYPE_JSON;
        }

        return fetch(url, config);
    }

    static getText(url, config = { credentials: 'include' }) {
        // if (!config.hasOwnProperty('contentType')) {
        //     config.contentType = CONTENT_TYPE_JSON;
        // }

        return fetch(url, config).then(response => onResponse(response));

        /**
         * Callback when response is ready
         * @param {Object} httpResponse
         * @returns {Promise.<T>}
         */
        function onResponse(httpResponse) {
            let textPromise = null;

            // Correct http info call
            if (httpResponse.ok) {
                textPromise = httpResponse.text();
            } else {
                textPromise = Promise.reject(httpResponse);
            }

            return textPromise;
        }
    }
}
