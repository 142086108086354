import i18next from 'i18next';
import Dom from '../../helpers/dom-helper';
import { isMobileBrowser } from '../../helpers/browser-detector.helper';
import Storage from '../../helpers/storage';
import modalTemplate from './consent-modal.html';

const MODAL_TEMPLATE = modalTemplate();
const BODY_CONSENT_MODAL_CLASSNAME = 'consent-modal-open';
const STORAGE_KEY_CONSENT_HIDDEN = 'privacy-modal-hidden';

const ONE_WEEK_IN_MS = 604800000;

export const CONSENT_TYPE_OPTIONAL = 'optional';
export const CONSENT_TYPE_MANDATORY = 'mandatory';
const allowedTranslationKeys = [
    'content-sharing-purpose',
    'cookie-tracking',
    'account-tracking',
    'privacy-policy-reference',
    'consent-revoke',
    'option'
];

export default class ConsentModal {
    constructor(container, domManager, eventBus, consentType, privacyPolicyUrl, onConsentAccepted, onViewContent, consentExpiry) {
        this.consentType = consentType;
        this.privacyPolicyUrl = privacyPolicyUrl;
        this.container = container;
        this.domManager = domManager;
        this.eventBus = eventBus;
        this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onViewContentButtonClick = this.onViewContentButtonClick.bind(this);
        this.onViewContent = onViewContent;
        this.onConsentAccepted = onConsentAccepted;
        this.isOpen = false;

        this.consentExpiry = consentExpiry;
        this.privacyPolicyLink = this.buildPrivacyPolicyLink(privacyPolicyUrl);

        this.render();
    }

    open() {
        this.isOpen = true;
        Dom.addClass(BODY_CONSENT_MODAL_CLASSNAME, this.domManager.get('body'));
    }

    close() {
        this.isOpen = false;
        Dom.removeClass(BODY_CONSENT_MODAL_CLASSNAME, this.domManager.get('body'));
        this.destroy();
        this.eventBus.dispatch('modal:close');
    }

    getModalElements() {
        return {
            consentHeaderText: this.element.querySelector('.modal-consent__header'),
            confirmationCheckboxText: this.element.querySelector('.modal-consent__accept .control__label--custom'),
            submitButtonText: this.element.querySelector('.modal-consent__continue span'),
            consentMessage: this.element.querySelector('.modal-consent__message'),
            buttonsContainer: this.element.querySelector('.modal-consent__buttons'),
            privacyPolicyLink: this.element.querySelector('.privacy-policy-link'),
            privacyPolicyLinkMessage: this.element.querySelector('.message__privacy-policy-link'),
            consentCheckbox: this.element.querySelector('.consent-modal-checkbox'),
            consentButton: this.element.querySelector('.consent-modal-button'),
            viewContentButton: this.element.querySelector('.consent-modal-view-content-button')
        };
    }

    render() {
        this.element = Dom.createElement(MODAL_TEMPLATE);

        this.assembleUI();
        this.addEventListeners();
    }

    assembleUI() {
        this.elements = this.getModalElements();

        this.elements.consentHeaderText.textContent = i18next.t('privacy-policy.welcome');
        this.elements.submitButtonText.textContent = i18next.t('privacy-policy.accept-privacy-policy');
        this.elements.confirmationCheckboxText.innerHTML = i18next.t(
            isMobileBrowser() ? 'privacy-policy.mobile.confirmation' : 'privacy-policy.web.confirmation',
            {
                privacyPolicyLink: this.privacyPolicyLink
            }
        );

        if (this.consentType === CONSENT_TYPE_OPTIONAL) {
            this.buildViewContentButton();

            this.domManager.set('consent-modal-view-content-button', this.elements.buttonsContainer.lastElementChild);
        }

        this.elements.consentMessage.innerHTML = this.assemblePrivacyPolicyMessage();

        this.domManager.set('consent-modal-button', this.elements.consentButton);
        this.domManager.set('consent-modal-checkbox', this.elements.consentCheckbox);

        this.container.appendChild(this.element);
    }

    assemblePrivacyPolicyMessage() {
        const isMobile = isMobileBrowser();

        return `${this.buildMessage(isMobile)}${
            this.consentType === CONSENT_TYPE_OPTIONAL ? `${this.buildParagraph('option', isMobile)}` : ''
        }`;
    }

    buildViewContentButton() {
        const viewContentButton = document.createElement('button');

        viewContentButton.classList.add(
            'button--m',
            'consent-modal-view-content-button',
            'button',
            'button-tint--reversed',
            'button-outline'
        );
        viewContentButton.innerHTML = i18next.t('privacy-policy.proceed-without-accepting');

        this.elements.buttonsContainer.appendChild(viewContentButton);
    }

    buildMessage(isMobile) {
        const cookieDurationObject = { consentExpiry: this.consentExpiry };

        return isMobile
            ? `${this.buildParagraph('cookie-tracking', true, cookieDurationObject)}${this.buildParagraph('consent-revoke', true)}`
            : `${this.buildParagraph('content-sharing-purpose', false)}${this.buildParagraph(
                  'cookie-tracking',
                  false,
                  cookieDurationObject
              )}${this.buildParagraph('account-tracking', false)}${this.buildParagraph('privacy-policy-reference', false, {
                  privacyPolicyLink: this.privacyPolicyLink
              })}`;
    }

    buildParagraph(type, mobile, extra) {
        if (!allowedTranslationKeys.find(key => type === key)) {
            return '';
        }

        const translationKey = `privacy-policy.${mobile ? 'mobile' : 'web'}.${type}`;
        return `<p>${i18next.t(translationKey, extra ? extra : undefined)}</p>`;
    }

    buildPrivacyPolicyLink(privacyPolicyUrl) {
        const link = document.createElement('a');

        link.classList.add('privacy-policy-link');

        link.innerHTML = 'Privacy Policy';
        link.target = '_blank';
        link.href = privacyPolicyUrl;

        // Returning only the outerHTML of the element because it is passed to the translation key
        // for it to render within the translated text
        return link.outerHTML;
    }

    destroy() {
        this.removeEventListeners();
    }

    addEventListeners() {
        Dom.on(this.domManager.get('consent-modal-button'), 'click', this.onSubmitButtonClick);
        Dom.on(this.domManager.get('consent-modal-checkbox'), 'change', this.onCheckboxChange);

        if (this.consentType === CONSENT_TYPE_OPTIONAL) {
            Dom.on(this.domManager.get('consent-modal-view-content-button'), 'click', this.onViewContentButtonClick);
        }
    }

    removeEventListeners() {
        Dom.off(this.domManager.get('consent-modal-button'), 'click', this.onSubmitButtonClick);
        Dom.off(this.domManager.get('consent-modal-checkbox'), 'change', this.onCheckboxChange);

        if (this.consentType === CONSENT_TYPE_OPTIONAL) {
            Dom.off(this.domManager.get('consent-modal-view-content-button'), 'click', this.onViewContentButtonClick);
        }
    }

    onCheckboxChange(event) {
        if (event.target.checked) {
            this.domManager.get('consent-modal-button').removeAttribute('disabled');

            if (this.consentType === CONSENT_TYPE_OPTIONAL) {
                this.domManager.get('consent-modal-view-content-button').style.display = 'none';
            }
        } else {
            this.domManager.get('consent-modal-button').setAttribute('disabled', true);

            if (this.consentType === CONSENT_TYPE_OPTIONAL) {
                this.domManager.get('consent-modal-view-content-button').style.display = 'block';
            }
        }
    }

    onSubmitButtonClick() {
        this.onConsentAccepted();
        this.close();
    }

    onViewContentButtonClick() {
        // In case the user proceeds without giving consent, (Optional Consent),
        // store the timestamp and wait a week to show the modal again
        Storage.set(STORAGE_KEY_CONSENT_HIDDEN, Date.now());
        this.onViewContent();
        this.close();
    }

    static shouldShow(consentRequired, consentType, consentEnabled) {
        if (consentEnabled === false || consentRequired === false) {
            return Promise.resolve(false);
        }
        if (consentType === CONSENT_TYPE_MANDATORY) {
            return Promise.resolve(true);
        }
        if (consentType === CONSENT_TYPE_OPTIONAL) {
            return ConsentModal.consentHidden().then(consentHidden => !consentHidden);
        }

        return Promise.resolve(false);
    }

    static consentHidden() {
        return Storage.get(STORAGE_KEY_CONSENT_HIDDEN)
            .catch(() => 0)
            .then(value => {
                const timestamp = parseInt(value, 10);
                const showPolicy = !(Date.now() - timestamp < ONE_WEEK_IN_MS);

                return !showPolicy;
            });
    }
}
