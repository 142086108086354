function _template() {return `<div class="collection-sidebar-header">
    <div class="collection-sidebar-toggle button-light">
        <i class="spsc-icon-icons-unused-system-actions-sidebar-outlined"></i>
    </div>
    View All Files
</div>
<div class="button-close-web-wrapper">
    <button type="button" class="button button-close__web collection-sidebar-close button-light" title="Toggle Sidebar"
        aria-label="Toggle Sidebar">
        <i class="spsc-icon-icons-unused-system-actions-sidebar-outlined"></i>
        <div class="button_focus-overlay"></div>
    </button>

</div>
<div class="collection-sidebar">
    <div class="collection-sidebar-subheader">
        <button type="button" class="button collection-sidebar-close-mobile button-light" title="Toggle Sidebar"
            aria-label="Toggle Sidebar">
            <i class="spsc-icon-icons-unused-system-actions-sidebar-outlined"></i>
        </button>
        <span class="collection-sidebar-count"></span>
        <button type="button" class="button-transparent collection-sidebar-download-all-button" title="Download all"
            aria-label="Download all">
            <i class="spsc-icon-download"></i>
            <div class="button_focus-overlay"></div>
        </button>
        <div class="collection-sidebar-dropdown">
            <div class="dropdown-item" id="download-as-one-button">As a single PDF</div>
            <div class="dropdown-item" id="download-separate-button">As separate items</div>
        </div>

    </div>
    <div class="collection-sidebar-thumbs"></div>
</div>
`;}export default function (ctx) {return _template.call(ctx, ctx);};