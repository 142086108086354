/**
 * Creates an array sorting function
 * @param {String} key
 * @returns {Function}
 */
export function sortArrayByObjectKey(key) {
    /**
     * Method doing sort
     * @param {object} item1
     * @param {object} item2
     * @return {number}
     */
    return function sortCompareItems(item1, item2) {
        const value1 = item1[key];
        const value2 = item2[key];

        if (value1 > value2) {
            return -1;
        } else if (value1 < value2) {
            return 1;
        } else {
            return 0;
        }
    };
}

export const REGEX_URL = /^(?!\/)[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,24}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/i;

export function isUrlValid(url) {
    return REGEX_URL.test(url);
}

export function shouldRedirectToUrl(assetViewerItems) {
    const hasOneUrlToRedirect =
        assetViewerItems.length === 1 && assetViewerItems[0].type === 'url' && assetViewerItems[0].canRenderInIframe === false;
    if (!hasOneUrlToRedirect) {
        return false;
    }
    const presentation = assetViewerItems[0].presentations.find(presentation => presentation.type === 'url');
    const url = presentation && presentation.url ? presentation.url : presentation && presentation.urls ? presentation.urls[0] : null;
    return hasOneUrlToRedirect && isUrlValid(url);
}

export function shouldRenderUrlPresentationInLargerIframe(assetViewerItems) {
    if (assetViewerItems.length === 1 && assetViewerItems[0].type === 'url' && assetViewerItems[0].canRenderInIframe === true) {
        const presentations = assetViewerItems[0].presentations.filter(presentation => {
            const url = presentation.url ? presentation.url : presentation.urls ? presentation.urls[0] : null;
            return presentation.type === 'url' && /^(https:\/\/)/.test(url);
        });
        return presentations.length > 0;
    }
    return false;
}
