function _template() {return `<div class="about-modal">
    <div class="modal-header">
        <div class="modal-header-title">About</div>
        <button type="button" class="button-transparent button-close">
            <i class="spsc-icon-close"></i>
            <div class="button_focus-overlay"></div>
        </button>
    </div>
    <div class="modal-body">
        <div class="creator-info">
            <h2 class="section-title">Sent on <span class="showcase-creation-date">${this.sharedAt}</span></h2>
            <div class="showcase-creator">
                <div class="showcase-creator-avatar-wrapper">
                    <div class="showcase-creator-avatar"></div>
                </div>
                <div class="showcase-creator-info">
                    <div class="showcase-creator-name-role">
                        <div class="showcase-creator-name"></div>
                        <div class="showcase-creator-role"></div>
                    </div>
                    <div class="showcase-creator-phone ${this.creator.phoneNumber ? '' : 'hidden'}"></div>
                    <div><a href="mailto:${this.creator.email}" class="showcase-creator-email"> </a><br /></div>
                </div>
            </div>
        </div>
        <div class="info-wrapper${this.theme.promoImageUrl !== null ? ' with-promo-image' : ''}">
            <h2 class="section-title ${this.theme.biographySafe ? '' : 'hidden'}">About</h2>
            <div class="promo-image" style="background-image: url(${this.theme.promoImageUrl || ''})"></div>

            <div class="company-biography">
                <p class="company-biography-content">${this.theme.biographySafe}</p>
            </div>
        </div>

        <div class="privacy-info-actions" style="${this.showPrivacyPolicy ? '' : 'display: none' }">
            <ul class="list">
                <li><a href="http://www.showpad.com" target="_blank">Showpad</a></li>
                &bull;
                <li><a id="privacy-policy" href="${this.privacyPolicyUrl}" target="_blank">Privacy Policy</a></li>
            </ul>
        </div>
    </div>
</div>`;}export default function (ctx) {return _template.call(ctx, ctx);};