import { ASSET_TYPE, ViewerEventType } from '@showpad/asset-viewer-v2';
import { IframePresentationRenderer } from '@showpad/asset-viewer-v2/release/esm/components/renderers/IframePresentationRenderer';
import AppFocusHelper from '../../helpers/app-focus.helper';
import { isMobile } from '../../helpers/detect-agent-browser';
import { logStart, logStop, logUpdate } from '../../helpers/device-event-logger.helper';
import { hasDeviceEventData } from '../../helpers/device-event.helper';
import Dom from '../../helpers/dom-helper';
import { UPDATE_ASSET_THRESHOLD, UPDATE_THRESHOLD } from '../../helpers/event-logger';
import { isPageHidden, isPageVisibilitySupported, pageVisibilityChangedListener } from '../../helpers/pagevisibility';

const INDEX_NOT_FOUND = -1;
const CAN_LOG_UNFOCUSED = [ASSET_TYPE.AUDIO, ASSET_TYPE.VIDEO];
const LOG_CREATED_IN_EVENT = [ASSET_TYPE.AUDIO, ASSET_TYPE.VIDEO, ASSET_TYPE.URL, ASSET_TYPE['3D-MODEL'], ASSET_TYPE.MEETING_VIDEO];

export default class ShowpadAssetViewerLogger {
    // # DeviceEvent logging:
    //
    // - IMAGE      starts logging after being viewed UPDATE_THRESHOLD time in the viewport and is updated every UPDATE_ASSET_THRESHOLD
    // - DOCUMENT   starts logging after being viewed UPDATE_THRESHOLD time in the viewport and is updated every UPDATE_ASSET_THRESHOLD
    // - VIDEO      starts logging as it's being played, stops logging when end or pause, not required to be in the viewport
    // - AUDIO      starts logging as it's being played, stops logging when end or pause, not required to be in the viewport
    // - WEBAPP     starts logging as it's fullscreen, stops logging when webapp close (in 'feed' a preview is shown), unless when there's only 1 assetViewerItem, then the web-app is rendered inline and needs to follow the regular flow // SP-41148
    // - URL        log once after url has been clicked
    // - 3D-MODEL   starts logging when model is loaded after button click

    constructor(eventLogger, assetViewer, eventBus) {
        this.eventLogger = eventLogger;
        this.assetViewer = assetViewer;
        this.eventBus = eventBus;
        this.childAssetViewer = null;

        // there are multiple events that can trigger a blur or resume, prevent multiple executions
        this.isLogging = false;
        // don't refocus on event if a modal or sidebar is open
        this.canRefocus = true;

        this.lastFocusedParentAssetViewerItem = null;
        this.lastLoggedEvent = {};
        this.lastLoggedEventMap = {};
        this.parentEventPromiseMap = {};

        this.appFocusHelper = new AppFocusHelper();
        this.viewportAssetItem = null;
        this.firstRun = true;
    }
    /**
     * Attach eventlisteners to the associated events for logging deviceevents
     */
    startLogging() {
        this.isLogging = true;

        this.listenToFocusChange();

        // Track navigation events sent by showpadjs
        Dom.on(window, 'message', message => {
            if (message.data && message.data.type === 'showpad-track-event' && message.data.data && message.data.data.event) {
                const events = Array.isArray(message.data.data.event) ? message.data.data.event : [message.data.data.event];
                const assetViewer = this.childAssetViewer || this.assetViewer;
                const currentView = assetViewer.viewer.currentView;

                if (!currentView || (currentView && !currentView.assetViewerItem)) {
                    throw new Error('showpad-track-event received without parent assetViewerItem');
                }

                const activeAssetViewerItemId = currentView.assetViewerItem.id;
                const activeAssetViewerItemPosition =
                    typeof currentView.assetViewerItem.position === 'number'
                        ? currentView.assetViewerItem.position
                        : currentView.assetViewerItem.parentPosition;
                const parentEventPromise = this.parentEventPromiseMap[activeAssetViewerItemId];

                if (parentEventPromise) {
                    parentEventPromise
                        .then(parentEvent => {
                            if (currentView.assetViewerItem.type === ASSET_TYPE.WEBAPP) {
                                this.eventLogger.trackHtmlNavigation(events, {
                                    parentId: parentEvent.id,
                                    assetPosition: activeAssetViewerItemPosition,
                                    assetId: this.childAssetViewer ? currentView.assetViewerItem.assetId : undefined,
                                    displayName: currentView.assetViewerItem.displayName
                                });
                            }
                        })
                        .catch(() => {});
                }
            }
        });

        this.addAssetViewerEventListeners(this.assetViewer);

        // on desktop, out of focus means we stop logging
        // mobile browsers don't always get to trigger blur, fallback on page visibility
        if (isMobile()) {
            const withPageVisibilityApi = true;

            this.fallbackBackgroundEventLogging(withPageVisibilityApi);
        }
    }

    registerChildAssetViewer(assetViewer) {
        this.childAssetViewer = assetViewer;
        const unregisterAssetViewerEventListeners = this.addAssetViewerEventListeners(assetViewer);
        return function unregisterChildAssetViewer() {
            unregisterAssetViewerEventListeners();
            this.childAssetViewer = null;
        };
    }

    addAssetViewerEventListeners(assetViewer) {
        const onPublicRenderedViewFocus = event => {
            if (this.appFocusHelper.isAppFocused) {
                const view = assetViewer.getAssetViewerViewComponentById(event.payload.assetViewerView.id);
                const page = view.assetViewerItem.type === ASSET_TYPE.DOCUMENT ? view.page : null;

                this.eventBus.dispatch('reset-parent-page-device-event', view.assetViewerItem);

                // prettier-ignore
                if (
                    // If there is no previous item
                    this.lastFocusedParentAssetViewerItem === null ||

                    // or if the last focused item is different than what we have on the viewport
                    (this.lastFocusedParentAssetViewerItem !== null && this.lastFocusedParentAssetViewerItem.id !== view.assetViewerItem.id) ||

                    // or it's the same item but with a different page (document type)
                    (this.lastFocusedParentAssetViewerItem !== null &&
                        this.lastFocusedParentAssetViewerItem.id === view.assetViewerItem.id &&
                        this.lastFocusedParentAssetViewerItem.page !== page)
                ) {
                    if (
                        this.lastFocusedParentAssetViewerItem !== null &&
                        CAN_LOG_UNFOCUSED.indexOf(this.lastFocusedParentAssetViewerItem.type) > -1 &&
                        hasDeviceEventData(this.lastLoggedEventMap[this.lastFocusedParentAssetViewerItem.id]) &&
                        this.lastFocusedParentAssetViewerItem.id ===
                            this.lastLoggedEventMap[this.lastFocusedParentAssetViewerItem.id].data.assetViewerItem.id
                    ) {
                        this.resetDynamicDeviceEvent(
                            this.lastLoggedEventMap[this.lastFocusedParentAssetViewerItem.id].data.assetViewerItem
                        );
                    }

                    if (typeof view.assetViewerItem.position === 'number') {
                        this.lastFocusedParentAssetViewerItem = view.assetViewerItem;
                    }

                    if (view.assetViewerItem.type === ASSET_TYPE.URL && view.presentationRenderer instanceof IframePresentationRenderer) {
                        this.eventLogger.createUrlAssetViewDeviceEvent(view.assetViewerItem).catch(() => {});
                    } else {
                        this.handleViewEvents(view.assetViewerItem, page, true);
                    }
                }
            }
        };

        const onPlaybackStart = event => {
            if (this.eventLogger.showcaseViewedEventSent) {
                this.handleDynamicViewEvent(event.payload.assetViewerView.assetViewerItem);
            } else {
                // For videos, if the user presses play before 'showcase-view' event is sent (before tracking starts),
                // we need to send that event before sending any 'asset-view' events
                this.eventLogger
                    .trackShowcaseView()
                    .then(() => this.handleDynamicViewEvent(event.payload.assetViewerView.assetViewerItem))
                    .catch(() => {});
            }
        };

        const onPlaybackEnd = event => {
            this.resetDynamicDeviceEvent(event.payload.assetViewerView.assetViewerItem);
        };

        const onWebappOpen = event => {
            this.handleDynamicViewEvent(event.payload.assetViewerItem);
        };

        const onWebappClose = event => {
            this.resetDynamicDeviceEvent(event.payload.assetViewerItem);
        };

        const onStartViewing3dModel = event => {
            this.handleDynamicViewEvent(event.payload.assetViewerView.assetViewerItem);
        };

        const onStopViewing3dModel = event => {
            this.handleViewEvents(event.payload.assetViewerView.assetViewerItem);
        };

        const onUrlAssetClicked = event => {
            this.eventLogger.createUrlAssetViewDeviceEvent(event.payload.assetViewerView.assetViewerItem).catch(() => {});
        };

        const onDownloadAsset = event => {
            this.eventLogger.trackItemDownload(event.payload.assetViewerItem);
        };

        const onFocusChange = event => {
            this.viewportAssetItem = { item: event.payload.assetViewerView.assetViewerItem, page: event.payload.assetViewerView.page };
        };

        // handle device from asset-viewer for view and focus events
        assetViewer.eventBus.on(ViewerEventType.PublicRenderedViewFocus, onPublicRenderedViewFocus);
        // handle device from asset-viewer for "dynamic" assets being viewed that need extra interaction
        assetViewer.eventBus.on(ViewerEventType.PlaybackStart, onPlaybackStart);
        assetViewer.eventBus.on(ViewerEventType.PlaybackEnd, onPlaybackEnd);
        assetViewer.eventBus.on(ViewerEventType.WebappOpen, onWebappOpen);
        assetViewer.eventBus.on(ViewerEventType.WebappClose, onWebappClose);
        assetViewer.eventBus.on(ViewerEventType.StartViewing3dModel, onStartViewing3dModel);
        assetViewer.eventBus.on(ViewerEventType.StopViewing3dModel, onStopViewing3dModel);
        assetViewer.eventBus.on(ViewerEventType.UrlAssetClicked, onUrlAssetClicked);
        // handle download event from asset-viewer
        assetViewer.eventBus.on(ViewerEventType.DownloadAsset, onDownloadAsset);
        assetViewer.eventBus.on(ViewerEventType.FocusChange, onFocusChange);

        return function removeAssetViewerEventListeners() {
            assetViewer.eventBus.off(ViewerEventType.PublicRenderedViewFocus, onPublicRenderedViewFocus);
            assetViewer.eventBus.off(ViewerEventType.PlaybackStart, onPlaybackStart);
            assetViewer.eventBus.off(ViewerEventType.PlaybackEnd, onPlaybackEnd);
            assetViewer.eventBus.off(ViewerEventType.WebappOpen, onWebappOpen);
            assetViewer.eventBus.off(ViewerEventType.WebappClose, onWebappClose);
            assetViewer.eventBus.off(ViewerEventType.StartViewing3dModel, onStartViewing3dModel);
            assetViewer.eventBus.off(ViewerEventType.StopViewing3dModel, onStopViewing3dModel);
            assetViewer.eventBus.off(ViewerEventType.UrlAssetClicked, onUrlAssetClicked);
            assetViewer.eventBus.off(ViewerEventType.DownloadAsset, onDownloadAsset);
            assetViewer.eventBus.off(ViewerEventType.FocusChange, onFocusChange);
        };
    }

    fallbackBackgroundEventLogging(withPageVisibilityApi = true) {
        if (withPageVisibilityApi && isPageVisibilitySupported() === true) {
            this.pageVisibilityEventLogging();
        }
    }

    pageVisibilityEventLogging() {
        const self = this;

        pageVisibilityChangedListener(() => pageVisibilityChangedHandler());

        function pageVisibilityChangedHandler() {
            if (isPageHidden() === true) {
                self.pause();
            } else if (isPageHidden() === false) {
                self.resume();
            }
        }
    }

    /**
     * When a new AssetViewerView is focussed, most present in the viewport:
     * Reset the last logged event, which emits events in sync and create a new one if not triggered by user
     *
     * @param {AssetViewerItem} assetViewerItem
     * @param {null|Number} page
     * @param {Boolean} fromOrigin, is the event fired from asset-viewer
     */
    handleViewEvents(assetViewerItem, page, fromOrigin = false) {
        let newPage = page;

        // 1-paged document page to 0-based API
        if (page !== null && fromOrigin) {
            newPage = page - 1;
        }

        if (
            hasDeviceEventData(this.lastLoggedEvent) && // if data exists and is valid
            assetViewerItem.id === this.lastLoggedEvent.data.assetViewerItem.id && // and if same asset as previously logged
            this.lastLoggedEvent.data.page !== newPage // but is different page
        ) {
            // keep updating, but with new data
            this.lastLoggedEvent.data = { assetViewerItem, page: newPage };
            this.updateDeviceEvent(this.lastLoggedEvent);
            return;
        }

        // Keep track of asset to be logged, as lastLogged, to be able to re-init the instance on focus after blur
        this.resetLoggedDeviceEvent(this.lastLoggedEvent);

        // Events will be created by user interaction, without timeout and can be ignored,
        if (!this.isDeviceEventHandledBySeparateEvent(assetViewerItem)) {
            this.lastLoggedEvent.data = { assetViewerItem, page: newPage };
            this.lastLoggedEvent.createTimeout = setTimeout(() => {
                this.startDeviceEvent(this.lastLoggedEvent, assetViewerItem, newPage, true);
            }, UPDATE_THRESHOLD);
        }
    }

    /**
     * When a new asset is actived for logging by the user (audio, video, web-app, 3d model)
     * If sync use last logged event as it was a view event, if async use the map
     *
     * @param {AssetViewerItem} assetViewerItem
     * @param {null|Number} page
     */
    handleDynamicViewEvent(assetViewerItem, page = null) {
        const hasThreshold = false;

        // Start logging as with a normal view event, except there is no timeout, user manually started the action
        if (CAN_LOG_UNFOCUSED.indexOf(assetViewerItem.type) === INDEX_NOT_FOUND) {
            this.lastLoggedEvent.data = { assetViewerItem, page };
            this.startDeviceEvent(this.lastLoggedEvent, assetViewerItem, page, hasThreshold);
        } else {
            if (hasDeviceEventData(this.lastLoggedEventMap[assetViewerItem.id])) {
                return;
            }

            // For items that can log in the background, hold references to asset and deviceEvent in a map
            this.lastLoggedEventMap[assetViewerItem.id] = {};
            this.lastLoggedEventMap[assetViewerItem.id].data = { assetViewerItem, page };
            this.startDeviceEvent(this.lastLoggedEventMap[assetViewerItem.id], assetViewerItem, page, hasThreshold);
        }
    }

    /**
     * Reset a dynamic device event
     * If sync clear the last logged device event, is async find the data in map and clear
     *
     * @param {AssetViewerItem} assetViewerItem
     */
    resetDynamicDeviceEvent(assetViewerItem) {
        // Stop logging the current last active item in viewport, as normal
        if (CAN_LOG_UNFOCUSED.indexOf(assetViewerItem.type) === INDEX_NOT_FOUND) {
            this.resetLoggedDeviceEvent(this.lastLoggedEvent);
        }
        // Stop logging an item than may be logging while not in viewport, can be found with assetViewerItem.id
        else {
            this.resetLoggedDeviceEvent(this.lastLoggedEventMap[assetViewerItem.id]);
        }
    }

    /**
     * Start a new deviceevent, which updates on every UPDATE_ASSET_THRESHOLD interval
     * Stores the data and timeouts in the last logged event or map
     *
     * @param {Object} loggedEventStore
     * @param {Object} asset
     * @param {null|Number} page
     * @param {Boolean} hasThreshold
     */
    startDeviceEvent(loggedEventStore, assetViewerItem, page, hasThreshold = true) {
        const parentPosition = assetViewerItem.parentPosition;
        const parentEventPromise = this.eventLogger
            .startViewingAsset(assetViewerItem, page, parentPosition, hasThreshold)
            .then(deviceEvent => {
                loggedEventStore.deviceEvent = deviceEvent;
                logStart(loggedEventStore);
                return deviceEvent;
            });
        this.parentEventPromiseMap[assetViewerItem.id] = parentEventPromise;

        parentEventPromise.catch(() => {
            /* Prevent uncaught promise rejection errors when tracking is disabled or the deviceevent call fails */
        });

        loggedEventStore.updateInterval = setInterval(() => {
            if (hasDeviceEventData(loggedEventStore)) {
                logUpdate(loggedEventStore);
                this.eventLogger.stillViewingAsset(loggedEventStore.deviceEvent, loggedEventStore.data.assetViewerItem);
            }
        }, UPDATE_ASSET_THRESHOLD);
    }

    /**
     * Update deviceevent with a new page (same document), same timeout logic as initial create (actually viewing with threshold)
     * Stores the data and timeouts in the last logged event or map
     *
     * @param {Object} loggedEventStore
     */
    updateDeviceEvent(loggedEventStore) {
        // reset possible other queued events
        clearInterval(loggedEventStore.updateInterval);
        clearTimeout(loggedEventStore.createTimeout);

        // update the current event with a new page
        loggedEventStore.createTimeout = setTimeout(() => {
            loggedEventStore.deviceEvent.page = loggedEventStore.data.page;

            if (hasDeviceEventData(loggedEventStore)) {
                logUpdate(loggedEventStore);
            }

            this.eventLogger.stillViewingAsset(loggedEventStore.deviceEvent, loggedEventStore.data.assetViewerItem);

            loggedEventStore.updateInterval = setInterval(() => {
                if (hasDeviceEventData(loggedEventStore)) {
                    logUpdate(loggedEventStore);
                    this.eventLogger.stillViewingAsset(loggedEventStore.deviceEvent, loggedEventStore.data.assetViewerItem);
                }
            }, UPDATE_ASSET_THRESHOLD);
        }, UPDATE_THRESHOLD);
    }

    /**
     * Stop a deviceevent, clear interval and deviceevents but keep the asset data
     * Used when browser is unfocussed, on re-focus with the asset data create a new deviceevent if possible
     *
     * @param {Object} loggedEventStore
     */
    stopLoggedDeviceEvent(loggedEventStore) {
        if (loggedEventStore.createTimeout) {
            clearTimeout(loggedEventStore.createTimeout);
            loggedEventStore.createTimeout = null;
        }

        if (loggedEventStore.updateInterval) {
            clearInterval(loggedEventStore.updateInterval);
            loggedEventStore.updateInterval = null;
        }

        if (hasDeviceEventData(loggedEventStore)) {
            // If event to be stopped belongs to a page, keep the reference. It can be stopped by navigating
            // to another asset in the share BUT ALSO by opening an asset viewer overlay. In this second case,
            // we need to keep the reference in order to resume the page's device event once the overlay is closed
            if (loggedEventStore.data.assetViewerItem.type === 'page') {
                this.eventBus.dispatch('save-page-device-event-reference', loggedEventStore);
            }

            logStop(loggedEventStore);

            this.eventLogger.stopViewingAsset(loggedEventStore.deviceEvent, loggedEventStore.data.assetViewerItem);
            loggedEventStore.deviceEvent = null;
        }
    }

    /**
     * Reset a deviceevent, stops the running events and clear the data
     * Used when asset is no longer logged, no longer in viewport for sync or paused/ended for async dynamic events
     *
     * @param {Object} loggedEventStore
     */
    resetLoggedDeviceEvent(loggedEventStore) {
        this.stopLoggedDeviceEvent(loggedEventStore);
        loggedEventStore.data = null;
        loggedEventStore = null;
    }

    pause() {
        if (this.isLogging === true) {
            this.isLogging = false;

            this.stopLoggedDeviceEvent(this.lastLoggedEvent);

            // Same for the map
            Object.keys(this.lastLoggedEventMap)
                .map(key => this.lastLoggedEventMap[key])
                .filter(Boolean)
                .forEach(eventItem => {
                    this.stopLoggedDeviceEvent(eventItem);
                });
        }
    }

    isDeviceEventHandledBySeparateEvent(assetViewerItem) {
        const isAssetFromPage = typeof assetViewerItem.parentPosition === 'number';
        let assetTypesThatAreHandledBySeparateEvents;

        // Webapp's are handled with the assetViewer event 'ViewerEventType.WebappOpen' when they use the NEW_WINDOW presentationRenderer, if they use the IframePresentationRenderer, they need to follow the regular device event flow
        if (isAssetFromPage) {
            assetTypesThatAreHandledBySeparateEvents = LOG_CREATED_IN_EVENT; // WebApp is rendered inline, follow regular flow
        } else {
            if (this.assetViewer.assetViewerItems.length === 1) {
                assetTypesThatAreHandledBySeparateEvents = LOG_CREATED_IN_EVENT; // WebApp is rendered inline, follow regular flow
            } else {
                assetTypesThatAreHandledBySeparateEvents = [...LOG_CREATED_IN_EVENT, ASSET_TYPE.WEBAPP]; // // WebApp is rendered with NewWindowPresentationRenderer, wait for 'ViewerEventType.WebappOpen' event
            }
        }

        return assetTypesThatAreHandledBySeparateEvents.includes(assetViewerItem.type);
    }

    resume() {
        if (this.isLogging === false && this.canRefocus === true) {
            this.isLogging = true;

            // This resume() function can also be called when the user blurs out from the viewport
            // but comes back to only scroll on top of the document to see other assets, without
            // clicking and not generating a proper focus on the asset viewer. This behavior causes
            // the lastLoggedEvent attribute to get the wrong asset/deviceEvent information.

            // If this is the case, we need to check on what is currently in the viewport and start a device
            // event with that asset rather than the lastLoggedEvent's asset
            const item =
                this.lastLoggedEvent.data && this.lastLoggedEvent.data.assetViewerItem === this.viewportAssetItem.item
                    ? this.lastLoggedEvent.data.assetViewerItem
                    : this.viewportAssetItem.item;

            // Same thing with the page as this is important for the correct continuity logging of the events
            const page =
                this.lastLoggedEvent.data &&
                this.lastLoggedEvent.data.assetViewerItem === this.viewportAssetItem.item &&
                this.lastLoggedEvent.data.page === this.viewportAssetItem.page
                    ? this.lastLoggedEvent.data.page
                    : this.viewportAssetItem.page;

            // Create a new event, start flow again, with last viewed asset, if it has been cleared by pause
            if (this.lastLoggedEvent.data) {
                if (!this.isDeviceEventHandledBySeparateEvent(this.lastLoggedEvent.data.assetViewerItem.type)) {
                    this.handleViewEvents(item, page, true);
                } else {
                    this.startDeviceEvent(this.lastLoggedEvent, this.lastLoggedEvent.data.assetViewerItem, this.lastLoggedEvent.data.page);
                }
                // Resume also gets called when after refreshing and blurring out immediately, we go back and focus an asset.
                // So if this.lastLoggedEvent.data does not exist, it means this edge case happened and we have to build the data
                // with the info from the viewport
            } else {
                this.handleViewEvents(item, page, true);
            }
        }
    }

    stopRunningDeviceEvents() {
        this.isLogging = false;

        if (hasDeviceEventData(this.lastLoggedEvent)) {
            this.resetLoggedDeviceEvent(this.lastLoggedEvent);
        }

        if (Object.keys(this.lastLoggedEventMap).length > 0) {
            this.resetLoggedDeviceEvent(Object.values(this.lastLoggedEventMap).find(loggedEvent => hasDeviceEventData(loggedEvent)));
        }
    }

    listenToFocusChange() {
        this.appFocusHelper.subscribe(isAppFocused => (isAppFocused ? this.resume() : this.pause()));
    }
}
