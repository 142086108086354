export default class AppFocusHelper {
    constructor() {
        this.handlers = [];
        this.isAppFocused = typeof document.hasFocus === 'function' ? document.hasFocus() : true;
        this.focusCheckInterval = null;
        this.onWindowBlur = this.onWindowBlur.bind(this);
        this.onWindowFocus = this.onWindowFocus.bind(this);
    }

    addEventListeners() {
        if (typeof document.hasFocus === 'function') {
            this.focusCheckInterval = setInterval(() => {
                const documentHasFocus = document.hasFocus();
                if (this.isAppFocused !== documentHasFocus) {
                    this.isAppFocused = documentHasFocus;
                    this.emit();
                }
            }, 2000);
        }

        window.addEventListener('blur', this.onWindowBlur);
        window.addEventListener('focus', this.onWindowFocus);
    }

    removeEventListeners() {
        clearInterval(this.focusCheckInterval);
        window.removeEventListener('blur', this.onWindowBlur);
        window.removeEventListener('focus', this.onWindowFocus);
    }

    onWindowBlur() {
        // Use document.hasFocus to not take window.blur events into account when an iframe gets focus
        const documentHasFocus = typeof document.hasFocus === 'function' ? document.hasFocus() : false;
        if (this.isAppFocused !== documentHasFocus) {
            this.isAppFocused = documentHasFocus;
            this.emit();
        }
    }

    onWindowFocus() {
        const documentHasFocus = typeof document.hasFocus === 'function' ? document.hasFocus() : true;
        if (this.isAppFocused !== documentHasFocus) {
            this.isAppFocused = documentHasFocus;
            this.emit();
        }
    }

    emit() {
        this.handlers.forEach(handler => {
            handler(this.isAppFocused);
        });
    }

    subscribe(handler) {
        if (this.handlers.length === 0) {
            this.addEventListeners();
        }
        this.handlers.push(handler);
        handler(this.isAppFocused);

        return { unsubscribe: () => this.unsubscribe(handler) };
    }

    unsubscribe(fn) {
        this.handlers = this.handlers.filter(handler => {
            if (handler !== fn) {
                return handler;
            }
        });
        if (this.handlers.length === 0) {
            this.removeEventListeners();
        }
    }
}
