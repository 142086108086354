import { AnnotationsMapper, AssetViewerItem, ASSET_TYPE, ViewerEventType } from '@showpad/asset-viewer-v2';
import i18next from 'i18next';
import { cloneDeep } from 'lodash-es';
import { v4 as uuidV4 } from 'uuid';
import ShowpadAssetViewer from '../../components/asset-viewer/assetViewer';
import Sidebar from '../../components/side-bar/sidebar';
import { extractAssetLinkInfoFromUrl } from '../../helpers/asset-link';
import { hasDeviceEventData } from '../../helpers/device-event.helper';
import Dom from '../../helpers/dom-helper';
import { DownloadHelper } from '../../helpers/download-helper';
import { shouldLogError } from '../../helpers/event-logger';
import { shouldRedirectToUrl } from '../../helpers/helper';
import Http from '../../helpers/http';
import { Defer, HANDLED_PROMISE_REJECTION_MESSAGE_PREFIX } from '../../helpers/promise';
import Url from '../../helpers/url-helper';
import AssetViewerOverlay from '../asset-viewer-overlay/asset-viewer-overlay';
import ShowpadAssetViewerLogger from '../asset-viewer/assetViewerLogger';
import { PdfJsService } from '../asset-viewer/pdf-js.service';
import { DownloadBar } from '../download-bar/downloadBar';
import ToastService from '../toast/toast';

const ASSET_INDEX_SEGMENT = 2;
const SIDEBAR_ANIMATION_TIME = 300;
const MERGE_PDF_POLLING_INTERVAL = 5000;
const SHOWPAD_ASSET_LINK_MESSAGE_TYPE = 'showpad-asset-link';
const PAGE_RENDERER_READY_MESSAGE_TYPE = 'PAGE_RENDERER_READY';
const REGEX_FILENAME_WITHOUT_EXTENSION = /(.+)\.[^.]+$/;
const MINIMUM_DISPLAY_TIME_OF_DOWNLOADBAR = 1500; //in ms

let pdfMergeErrorLogged = false;

export default class ShowcaseViewer {
    constructor(domManager, eventLogger, eventBus, info, shareHash, consentDeferPromise) {
        this.eventLogger = eventLogger;
        this.domManager = domManager;
        this.eventBus = eventBus;
        this.flags = {
            trackHtmlPageLocationEnabled: info.trackHtmlPageLocationEnabled
        };
        this.pdfJsService = new PdfJsService(this.flags);
        this.info = info;
        this.shareHash = shareHash;
        this.showcaseItems = null;
        this.assetViewerItems = null;

        this.toastService = new ToastService(this.domManager.get('toast-container'));
        this.sideBar = null;
        this.assetViewer = null;
        this.assetViewerOverlay = null;
        this.mergePdfInterval = null;
        this.consentDeferPromise = consentDeferPromise;
        this.pageRendererReady = new Defer();
        this.parentPageDeviceEvent = null;
    }

    load() {
        const url = Url.getUrlWithParams('showcase-info-with-svg');
        const errorMessage = 'Invalid json response, items/with-svg';

        const promise = Http.getJson(url)
            .then(jsonResponse => {
                if (jsonResponse.success === true) {
                    return jsonResponse.data.items;
                } else {
                    throw new Error(errorMessage);
                }
            })
            .catch(error => {
                const message = this.eventBus.dispatch('error:messagelog', 'The content could not be retrieved', errorMessage, error);
                return Promise.reject(HANDLED_PROMISE_REJECTION_MESSAGE_PREFIX + errorMessage);
            });

        return promise;
    }

    /**
     * Handle info about the showcase items and setup the asset-viewer
     */
    handle(showcaseItems) {
        const validShowcaseItems = showcaseItems.filter(item => item && item.isShareable && item.asset);
        this.showcaseItems = validShowcaseItems;

        if (validShowcaseItems.length === 0) {
            const errorMessage = 'showcase items endpoint /items/with-svg returned 0 undeleted, shareable items';
            this.eventBus.dispatch('error:message', 'We could not find the content, access may be revoked', errorMessage, showcaseItems);
            return Promise.reject(HANDLED_PROMISE_REJECTION_MESSAGE_PREFIX + errorMessage);
        }

        // Creators
        this.sideBar = this.createSidebar(validShowcaseItems.length, this.info);
        const thumbnailContainer = this.sideBar.getAssetThumbsContainer();
        const contentContainer = this.domManager.get('showcase-items-container');
        const assetViewerItems = this.toAssetViewerItems(validShowcaseItems);
        this.assetViewerItems = assetViewerItems;
        // don't initialize the assetViewer when we're redirecting anyway
        if (!shouldRedirectToUrl(assetViewerItems)) {
            this.assetViewer = this.createAssetViewer(contentContainer, thumbnailContainer, assetViewerItems);
            this.assetViewerLogger = new ShowpadAssetViewerLogger(this.eventLogger, this.assetViewer.assetViewer, this.eventBus);

            this.assetViewerOverlay = this.createAssetViewerOverlay();

            if (this.info.allowDownloadAll && this.info.downloadAsPdfEnabled) {
                this.downloadBar = new DownloadBar(this.eventBus);
                this.downloadBar.setMessage(i18next.t('preparing-download'));
            }

            // onEvent listeners
            this.addEventListeners();
            // Start logging and pass responsibility to asset-viewer
            this.assetViewerLogger.startLogging();
            this.assetViewer.init();
            if (Dom.isInResponsiveMobileMode() === true && this.assetViewer && this.assetViewer.assetViewer) {
                this.assetViewer.assetViewer.viewer.config.VIDEO_POSTER_IMAGE_ENABLED = true;
            }

            // Debugging tool
            window.assetViewer = this.assetViewer;
        }
    }

    createSidebar(showcaseItemsLength) {
        const sideBar = new Sidebar(
            this.domManager.get('collection-sidebar-container'),
            this.domManager.get('shared-collection'),
            showcaseItemsLength,
            this.domManager,
            this.eventBus,
            this.info
        );

        if (Dom.isInResponsiveMobileMode() === true || showcaseItemsLength === 1) {
            sideBar.close();
        }

        sideBar.render();

        // Do not transition on initial render
        setTimeout(() => {
            Dom.addClass('animate', this.domManager.get('shared-collection-content'));
        }, 1);

        return sideBar;
    }

    toAssetViewerItems(items) {
        function filterAssetItems(item) {
            return item.asset && item.isShareable;
        }

        function sortAssetItems(itemA, itemB) {
            return itemA.position - itemB.position;
        }

        function createAssetViewerItems(item) {
            const id = uuidV4();
            const page = typeof item.page === 'number' ? item.page + 1 : null;
            const annotations =
                item.annotations && item.annotations.length
                    ? AnnotationsMapper.annotationsArrayToAnnotationsPageMap(item.annotations)
                    : null;
            const canPresentInOfficeOnline = false; // TODO
            const displayName =
                item.asset.type === 'page'
                    ? item.asset.displayName.replace(REGEX_FILENAME_WITHOUT_EXTENSION, '$1')
                    : item.asset.displayName;

            // Technically all assets will support thumbnailUrl but only the types 'video' and 'personal-video-recording'
            // currently implement it so for now we limit the scope these types.
            const previewUrl =
                (item.asset.type === ASSET_TYPE.VIDEO || item.asset.type === ASSET_TYPE.PERSONAL_VIDEO_RECORDING) && item.thumbnailUrl
                    ? item.thumbnailUrl
                    : item.previewUrl;

            const assetViewerItem = new AssetViewerItem(
                displayName,
                item.asset.type,
                item.asset.downloadUrl,
                previewUrl,
                item.asset.presentations,
                item.asset.isPdf ? 'pdf' : '', // TODO
                id,
                annotations,
                page,
                item.position,
                item.isDownloadable,
                item.asset.embeddedData,
                undefined,
                undefined,
                item.asset.allowRenderExternal === false,
                item.asset.description,
                canPresentInOfficeOnline,
                undefined,
                item.asset.videoTracks
            );

            assetViewerItem.assetId = item.asset.id; // will only be defined for assets within pages, and is only needs for device events of those assets

            return assetViewerItem;
        }

        return items
            .filter(filterAssetItems)
            .sort(sortAssetItems)
            .map(createAssetViewerItems);
    }

    createAssetViewer(contentContainer, sidebarContainer, assetViewerItems, isOverlayAssetViewer = false) {
        const assetToOpen = this.getAssetToOpen(assetViewerItems);

        const assetViewer = new ShowpadAssetViewer(this.eventBus, this.pdfJsService, this.eventLogger);
        assetViewer.createAssetViewer(contentContainer, sidebarContainer, assetViewerItems, assetToOpen, this.flags, isOverlayAssetViewer);
        return assetViewer;
    }

    createAssetViewerOverlay() {
        if (!this.domManager || !this.eventLogger || !this.eventBus || !this.assetViewerLogger) {
            throw new Error('overlayAssetViewer dependencies have not been initialised');
        }
        return new AssetViewerOverlay(this.domManager, this.eventLogger, this.eventBus, this.assetViewerLogger);
    }

    openOverlayAssetViewer(pageId, assetId, page = 1) {
        this.assetViewerOverlay.open();
        this.openAsset(pageId, assetId, page);
    }

    openAsset(pageId, assetId, page) {
        // TODO set loading state
        const { data: item } = this.fetchPageAsset(pageId, assetId)
            .then(response => response.data)
            .then(item => {
                const avItem = this.toAssetViewerItems([item])[0];
                const parentPageItem = this.showcaseItems.find(item => item.pageInfo && item.pageInfo.id === pageId);
                avItem.parentPosition = parentPageItem.position;

                this.assetViewerOverlay.setDisplayName(avItem.displayName);
                if (avItem.isDownloadable) {
                    this.assetViewerOverlay.setDownloadEnabled();
                }
                if (avItem.type === ASSET_TYPE.DOCUMENT) {
                    this.assetViewerOverlay.showSidebar();
                } else {
                    this.assetViewerOverlay.hideSidebar();
                }
                const overlayAssetViewer = this.createAssetViewer(
                    this.assetViewerOverlay.container,
                    this.assetViewerOverlay.thumbnailContainer,
                    [avItem],
                    true
                );

                this.assetViewerOverlay.registerAssetViewer(overlayAssetViewer, page);
            });
    }

    addEventListeners() {
        if (this.assetViewer && this.assetViewer.assetViewer) {
            this.eventBus.on('sidebar:toggle', isClosed => {
                if (Dom.isInResponsiveMobileMode() === false) {
                    setTimeout(() => this.assetViewer.resize(), SIDEBAR_ANIMATION_TIME);
                } else {
                    if (isClosed) {
                        this.assetViewerLogger.canRefocus = true;
                        this.assetViewerLogger.resume();
                        this.assetViewer.assetViewer.viewer.setActive();
                    } else {
                        this.assetViewerLogger.canRefocus = false;
                        this.assetViewerLogger.pause();
                        this.assetViewer.assetViewer.viewer.setPassive();
                    }
                }
            });

            if (Dom.isInResponsiveMobileMode() === true) {
                const TIMEOUT_THROTTLE = 30;
                let headerClicked = false;

                this.assetViewer.assetViewer.eventBus.on(ViewerEventType.SetView, () => {
                    this.sideBar.close();
                    headerClicked = true;
                    setTimeout(() => (headerClicked = false), TIMEOUT_THROTTLE);
                });

                this.assetViewer.assetViewer.eventBus.on(ViewerEventType.ViewportChange, event => {
                    // ViewerViewportChangeEvent
                    const { viewState } = event.payload;
                    if (headerClicked === false) {
                        this.eventBus.dispatch('viewport:change', viewState.top, viewState.scroll);
                    }
                });
            }
        }

        this.eventBus.on('download-only-item', () => {
            const item = this.assetViewer.assetViewerItems.find(item => item.isDownloadable);
            DownloadHelper.download(item.downloadUrl, item.displayName);
        });

        this.eventBus.on('downloadAsPDF', shouldStart => {
            if (this.info.downloadAsPdfEnabled) {
                if (shouldStart) {
                    if (this.isMergingPdf()) {
                        this.alreadyMergingPdf();
                    } else {
                        this.startPdfMerge();
                    }
                } else {
                    this.stopPdfMerge();
                }
            } else console.log('download as PDF isnt enabled');
        });

        this.eventBus.on('downloadAllFiles', () => {
            if (this.info.allowDownloadAll) {
                this.eventBus.dispatch('downloadBar:toggle');
                setTimeout(() => {
                    const path = window.location.pathname;
                    const [_, shareHash] = Url.parseShareHash(path);
                    const url = Url.getUrlWithParams('zip-download');
                    this.sideBar.showDownloadStartedToast();
                    DownloadHelper.download(url, `${this.info.title}.zip`);
                    this.eventBus.dispatch('track:downloadAll');
                    this.eventBus.dispatch('downloadBar:toggle');
                }, MINIMUM_DISPLAY_TIME_OF_DOWNLOADBAR);
            } else console.log('downloadAllFiles is not enabled');
        });

        this.eventBus.on('downloadBar:toggle', () => {
            this.toggleDownloadBar();
        });

        this.eventBus.on('downloading-enabled', value => {
            if (value) {
                this.sideBar.enableButtons();
            } else {
                this.sideBar.disableButtons();
            }
        });

        this.eventBus.on('save-page-device-event-reference', event => {
            this.parentPageDeviceEvent = cloneDeep(event);
        });

        this.eventBus.on('reset-parent-page-device-event', event => {
            if (
                !this.assetViewerOverlay.isOpen &&
                hasDeviceEventData(this.parentPageDeviceEvent) &&
                hasDeviceEventData(event) &&
                event.data.assetViewerItem.id !== this.parentPageDeviceEvent.data.assetViewerItem.id &&
                event.deviceEvent.id !== this.parentPageDeviceEvent.deviceEvent.id
            ) {
                this.assetViewerLogger.stopLoggedDeviceEvent(this.parentPageDeviceEvent);
                this.parentPageDeviceEvent = null;
            }
        });

        this.eventBus.on('asset-viewer-overlay-closed', () => {
            this.assetViewerLogger.updateDeviceEvent(this.parentPageDeviceEvent);

            // Since we are coming back from the asset viewer overlay
            // and see the page again, we have to change the viewportAssetItem
            // to the correct state, which is the page
            this.assetViewerLogger.viewportAssetItem = {
                item: this.parentPageDeviceEvent.data.assetViewerItem,
                page: null
            };
            this.assetViewerLogger.lastLoggedEvent = this.parentPageDeviceEvent;
            this.assetViewerLogger.isLogging = true;
            this.assetViewerLogger.childAssetViewer = null;
        });

        Dom.on(window, 'message', message => {
            if (
                message.data &&
                message.data.action === PAGE_RENDERER_READY_MESSAGE_TYPE &&
                message.data.payload &&
                message.data.payload.pageId
            ) {
                Promise.all([this.consentDeferPromise.promise, this.pageRendererReady.promise])
                    .then(([, { pageId, source }]) => {
                        this.onPageRendererReady(pageId, source);
                    })
                    .catch(error => {
                        if (shouldLogError(error)) throw new Error(error);
                    });
                this.pageRendererReady.resolve({ pageId: message.data.payload.pageId, source: message.source });
                this.pageRendererReady = new Defer();
            }

            const assetLinkInfo =
                message.data && message.data.data && message.data.data.link ? extractAssetLinkInfoFromUrl(message.data.data.link) : null;

            if (message.data && message.data.type === SHOWPAD_ASSET_LINK_MESSAGE_TYPE) {
                this.handleAssetLinkMessage(message, assetLinkInfo);
            }
        });
    }

    fetchPageAsset(pageId, assetId) {
        const url = Url.getUrlWithParams('showcase-page-item', { pageId, assetId });

        return Http.getJson(url);
    }

    onPageRendererReady(pageId, pageIframeWindow) {
        const pageItem = this.showcaseItems.find(item => item.pageInfo && item.pageInfo.id === pageId);
        if (pageItem && pageIframeWindow) {
            pageIframeWindow.postMessage(
                {
                    action: 'PAGE_RENDER_DATA',
                    origin: 'shared-content-viewer',
                    payload: pageItem.pageInfo
                },
                '*'
            );
        }
        pageIframeWindow.pageId = pageId; // used to identify the page when an showpad-asset-link postmessage comes in
    }

    async handleAssetLinkMessage(message, assetLinkInfo) {
        if (assetLinkInfo && assetLinkInfo.file) {
            const slug = assetLinkInfo.file;
            const pageId = message.source.pageId;
            const pageItem = this.showcaseItems.find(item => item.pageInfo && item.pageInfo.id === pageId);
            if (!pageItem) {
                throw new Error('no pageJson found in showcaseItems');
            }

            const asset = getAssetBySlug(pageItem.pageInfo, slug);
            if (!asset) {
                throw new Error('no asset for slug found in pageJson');
            }

            asset.filetype === 'url' && asset.allowRenderExternal
                ? await this.openURLInTab(asset, pageId)
                : this.openOverlayAssetViewer(pageId, asset.id, assetLinkInfo.page);
        }
    }

    async openURLInTab(asset, pageId) {
        const windowOpenedSuccessfully = window.open(asset.url, '_blank');

        if (windowOpenedSuccessfully) {
            await this.fetchPageAsset(pageId, asset.id)
                .then(response => response.data)
                .then(item => {
                    const avItem = this.toAssetViewerItems([item])[0];
                    const parentPageItem = this.showcaseItems.find(item => item.pageInfo && item.pageInfo.id === pageId);

                    avItem.parentPosition = parentPageItem.position;

                    this.eventLogger.createUrlAssetViewDeviceEvent(avItem);
                })
                .catch(e => {
                    throw new Error(e);
                });
        }
    }

    fetchPdfMergeInfo() {
        const url = Url.getUrlWithParams('pdf-merge-info');
        return Http.fetch(url);
    }

    downloadMergedPdf() {
        const url = Url.getUrlWithParams('pdf-merge-download');
        DownloadHelper.download(url, `${this.info.title}.zip`);
        this.eventBus.dispatch('track:downloadAll');
    }

    isMergingPdf() {
        return !!this.mergePdfInterval;
    }

    checkPdfMerge() {
        this.eventBus.dispatch('downloadBar:toggle'); //toggle ON the downloadBar (preparing pdf)
        this.fetchPdfMergeInfo(this.shareHash)
            .then(response => {
                if (response.status === 200) {
                    this.pdfMergeReady();
                } else if (response.status >= 400) {
                    this.pdfMergeInfoFailed();
                }
            })
            .catch(error => {
                if (shouldLogError(error) && !pdfMergeErrorLogged) {
                    // since this function is called in an interval, prevent logging the same error multiple times
                    pdfMergeErrorLogged = true;
                    throw new Error(error);
                }
            });
    }

    startPdfMerge() {
        if (!this.mergePdfInterval) {
            this.mergePdfInterval = setInterval(() => this.checkPdfMerge(), MERGE_PDF_POLLING_INTERVAL);
            this.checkPdfMerge();
        }
    }

    pdfMergeReady() {
        if (this.mergePdfInterval < MINIMUM_DISPLAY_TIME_OF_DOWNLOADBAR) {
            let timeWeShouldWait = MINIMUM_DISPLAY_TIME_OF_DOWNLOADBAR - this.mergePdfInterval;
            setTimeout(() => {
                this.eventBus.dispatch('downloadBar:toggle'); //toggling off the downloadBar
            }, timeWeShouldWait);
        }
        this.clearMergePdfInterval();
        this.downloadMergedPdf();
        this.sideBar.showDownloadStartedToast();
    }

    pdfMergeInfoFailed() {
        this.clearMergePdfInterval();
        this.eventBus.dispatch('downloadBar:toggle'); // toggling off downloadBar (pdf merging failed)
        this.toastService.showToast(i18next.t('download-as-one-info-check-failed-toast'), 'error');
    }

    stopPdfMerge() {
        if (this.isMergingPdf()) {
            this.clearMergePdfInterval();
            this.eventBus.dispatch('downloadBar:toggle');
        }
    }

    clearMergePdfInterval() {
        clearInterval(this.mergePdfInterval);
        this.mergePdfInterval = null;
    }

    toggleDownloadBar() {
        this.downloadBar.toggle();
        Dom.toggleClass('with-download-bar', this.domManager.get('shared-collection-content'));
        Dom.toggleClass('with-download-bar', this.domManager.get('collection-sidebar'));
    }

    alreadyMergingPdf() {
        this.toastService.showToast(i18next.t('already-merging-pdf'), 'info');
    }

    getAssetToOpen(showcaseItems) {
        let indexOfAsset;
        const uniqueidOfAsset = Url.getParamValue('uniqueid');
        if (uniqueidOfAsset) {
            indexOfAsset = this.info.content.items.findIndex(
                item => item.contactSessionAsset && item.contactSessionAsset.uniqueId === uniqueidOfAsset
            );
        } else {
            indexOfAsset = Number(Url.getSegment(ASSET_INDEX_SEGMENT));
            if (isNaN(indexOfAsset) || indexOfAsset > showcaseItems.length - 1) {
                return 0;
            }
        }

        return Math.max(indexOfAsset, 0);
    }
}

export function getAssetBySlug(pageJson, slug) {
    if (pageJson && pageJson.resources && pageJson.resources.assets) {
        const assets = pageJson.resources.assets;
        const allAssetsIds = Object.keys(assets);
        const foundAsset = allAssetsIds.find(id => assets[id].appLink && assets[id].appLink.includes(slug));

        if (foundAsset) {
            return pageJson.resources.assets[foundAsset];
        }

        return null;
    }

    return null;
}
