export default class DateHelper {
    /**
     * Get a formatted date for UI
     * @param {number} timestamp
     * @returns {string}
     */
    static timestampToFormattedDate(timestamp) {
        const TIME_SECOND_MS = 1000;
        const date = new Date(timestamp * TIME_SECOND_MS);

        const options = {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        };

        return DateHelper.dateToLocaleDateString(date, options);
    }

    /**
     * Get info for a date object
     * @param {Date} date
     * @returns {{year: number, month: number, day: number, hour: number, minute: number, second: number}}
     */
    static dateToInfoObject(date) {
        return {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
            hour: date.getHours(),
            minute: date.getMinutes(),
            second: date.getSeconds()
        };
    }

    /**
     * Get the browsers locale
     * @returns {string}
     */
    static getUserLocale() {
        const locale = navigator.userLanguage || navigator.language || navigator.browserLanguage || navigator.systemLanguage;

        return locale;
    }

    /**
     * Output the specified date object into the user's locale
     * @param {Date} date
     * @returns {String}
     */
    static dateToLocaleDateString(date, options) {
        const locale = DateHelper.getUserLocale();
        let formatted = '';

        if (typeof date.toLocaleDateString === 'function') {
            formatted = date.toLocaleDateString(locale, options);
        } else {
            formatted = DateHelper.dateToFallbackLocaleDateString(date, locale, options);
        }

        return formatted;
    }

    /**
     * Naive fallback method when `toLocaleDateString` is not available
     * Does not support options
     * @param {Date} date
     * @param {string} locale
     */
    static dateToFallbackLocaleDateString(date, locale) {
        const FORMAT_YYYYMMDD = 'YYYY/MM/DD';
        const FORMAT_DDMMYYYY = 'DD/MM/YYYY';
        const FORMAT_MMDDYYYY = 'MM/DD/YYYY';
        const LOCALE_FORMAT = getLocaleFormat(locale);

        const info = DateHelper.dateToInfoObject(date);

        let formatted = '';

        if (LOCALE_FORMAT === FORMAT_MMDDYYYY) {
            formatted = `${info.month}/${info.day}/${info.year}`;
        } else if (LOCALE_FORMAT === FORMAT_YYYYMMDD) {
            formatted = `${info.year}/${info.month}/${info.day}`;
        } else {
            formatted = `${info.day}/${info.month}/${info.year}`;
        }

        return formatted;

        /**
         * Get the format for the locale
         *
         * Using https://en.wikipedia.org/wiki/Date_format_by_country + http://www.localeplanet.com/index.html
         * YYYY-MM-DD for afghanistan, china+taiwan, hungary, lithuania, japan, korea, mongolia
         * MM-DD-YYYY for some us, zimbabwe (en_zw detectable but is YYYYMMDD, zw not detectable), micronesia (not detectable)
         * DD-MM-YYYY for other countries
         *
         * @param locale
         * @returns {string}
         */
        function getLocaleFormat(locale) {
            const LOCALE = locale.toLowerCase().replace('-', '_');
            const ITEM_NOT_FOUND = -1;

            const MMDDYYY_FULL_LOCALES = [
                'en_us',
                'en_as',
                'en_bb',
                'en_bm',
                'en_bw',
                'en_gu',
                'en_gy',
                'en_jm',
                'en_mh',
                'en_mp',
                'en_vi',
                'en_um',
                'en_tt',
                'en_ph',
                'en_na'
            ];

            const YYYYMMDD_PARTIAL_LOCALES = ['fa', 'zh', 'hu', 'lt', 'ja', 'ko', 'mn'];

            // Default
            let format = FORMAT_DDMMYYYY;

            if (YYYYMMDD_PARTIAL_LOCALES.indexOf(LOCALE.split('_')[0]) > ITEM_NOT_FOUND) {
                format = FORMAT_YYYYMMDD;
            } else if (MMDDYYY_FULL_LOCALES.indexOf(LOCALE) > ITEM_NOT_FOUND) {
                format = FORMAT_MMDDYYYY;
            }

            return format;
        }
    }
}
