function _template() {return `<div class="share-modal">
    <div class="modal-header">
        <div class="modal-header-title">Share This</div>
        <button type="button" class="button-transparent button-close">
            <i class="spsc-icon-close"></i>
            <div class="button_focus-overlay"></div>
        </button>
    </div>
    <form class="share-modal-form">
        <div class="modal-body">
            <p class="share-error">
                Due to a technical issue the content could not be shared. <br />
                Our developers have been notified of this problem.
            </p>

            <div class="field-wrapper">
                <label id="tags-input-email__label">Recipients</label>
                <div class="share-recipients">
                    <input type="tags" class="tags-input-email" placeholder="Email Address" />
                </div>
            </div>

            <div class="field-wrapper">
                <label id="tags-input-message__label">Message</label>
                <textarea class="share-body"></textarea>
            </div>
        </div>

        <div class="modal-footer">
            <div class="reshare-policy">
                By clicking the send button I agree to the
                <a href="https://www.showpad.com/online-terms" target="_blank">Buyer Terms and Conditions</a>
            </div>
            <button type="submit" class="button--m button-tint button-send"><span>Send</span>
                <div class="button_focus-overlay"></div>
            </button>
        </div>
    </form>
</div>`;}export default function (ctx) {return _template.call(ctx, ctx);};