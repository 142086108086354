import {
    ASSET_TYPE,
    AssetViewer,
    FILE_TYPE_JS,
    LAYOUT,
    PRESENTATION_RENDERERS,
    THREE_JS_DEPS_KEYS,
    TranslationKeys
} from '@showpad/asset-viewer-v2';
import i18next from 'i18next';
import { STATIC_PATH } from '../../helpers/constants';
import FullscreenHelper from '../../helpers/fullscreen';
import ActionBar from '../action-bar/actionBar';

const THREE_JS_DEPS = {
    [THREE_JS_DEPS_KEYS.THREE]: {
        type: FILE_TYPE_JS,
        src: require('!!file-loader?name=[name]-[hash].[ext]!@showpad/three/build/three.min.js').default
    },
    [THREE_JS_DEPS_KEYS.COLLADA_LOADER]: {
        type: FILE_TYPE_JS,
        src: require('!!file-loader?name=[name]-[hash].[ext]!@showpad/three/examples/js/loaders/ColladaLoader.js').default
    },
    [THREE_JS_DEPS_KEYS.TGA_LOADER]: {
        type: FILE_TYPE_JS,
        src: require('!!file-loader?name=[name]-[hash].[ext]!@showpad/three/examples/js/loaders/TGALoader.js').default
    },
    [THREE_JS_DEPS_KEYS.ORBIT_CONTROLS]: {
        type: FILE_TYPE_JS,
        src: require('!!file-loader?name=[name]-[hash].[ext]!@showpad/three/examples/js/controls/OrbitControls.js').default
    }
};
export default class ShowpadAssetViewer {
    constructor(eventBus, pdfJsService, eventLogger) {
        this.eventBus = eventBus;
        this.pdfJsService = pdfJsService;
        this.eventLogger = eventLogger;
        this.assetViewerItems = null;
        this.assetViewer = null;
        this.initialAsset = 1;

        FullscreenHelper.addFullscreenChangeListener(() => {
            if (FullscreenHelper.isFullscreen()) {
                document.body.classList.add('fullscreen');
            } else {
                document.body.classList.remove('fullscreen');
            }
        });
    }

    createAssetViewer(content, thumbs, assetViewerItems, index, flags, isOverlayAssetViewer) {
        const OVERLAY_PRESENTATION_RENDERERS_FOR_ASSET_TYPE_MAP = {
            [ASSET_TYPE.PHOTO]: [PRESENTATION_RENDERERS.IMAGE],
            [ASSET_TYPE.AUDIO]: [PRESENTATION_RENDERERS.AUDIO],
            [ASSET_TYPE.VIDEO]: [PRESENTATION_RENDERERS.VIDEO],
            [ASSET_TYPE.PERSONAL_VIDEO_RECORDING]: [PRESENTATION_RENDERERS.VIDEO],
            [ASSET_TYPE.WEBAPP]: [PRESENTATION_RENDERERS.IFRAME],
            [ASSET_TYPE.SCORM]: [PRESENTATION_RENDERERS.IFRAME],
            [ASSET_TYPE.DOCUMENT]: [PRESENTATION_RENDERERS.ASSET_VIEWER],
            [ASSET_TYPE['3D-MODEL']]: [PRESENTATION_RENDERERS.THREEJS],
            [ASSET_TYPE.RAW]: [PRESENTATION_RENDERERS.ERROR],
            [ASSET_TYPE.MEETING_VIDEO]: [PRESENTATION_RENDERERS.VIDEO],
            [ASSET_TYPE.URL]: [PRESENTATION_RENDERERS.IFRAME, PRESENTATION_RENDERERS.URL],
            [ASSET_TYPE.PAGE]: [PRESENTATION_RENDERERS.IFRAME]
        };

        this.initialAsset = index;

        // needed for showpad js to post messages to parent
        window.name = 'showpad_webview';

        this.assetViewerItems = assetViewerItems;

        if (assetViewerItems.length === 0) {
            const errorMessage = 'No content is present, it may have been unshared or deleted';
            this.eventBus.dispatch('error:message', errorMessage, 'number of filtered (sharable or deleted) items is 0', items);
            throw new Error(errorMessage);
        }

        let urlRenderer = [];

        urlRenderer = [PRESENTATION_RENDERERS.IFRAME, PRESENTATION_RENDERERS.URL];
        content.classList.add('dynamic-renderer');

        const PRESENTATION_RENDERERS_FOR_ASSET_TYPE_MAP = {
            [ASSET_TYPE.PHOTO]: [PRESENTATION_RENDERERS.IMAGE],
            [ASSET_TYPE.AUDIO]: [PRESENTATION_RENDERERS.AUDIO],
            [ASSET_TYPE.VIDEO]: [PRESENTATION_RENDERERS.VIDEO],
            [ASSET_TYPE.PERSONAL_VIDEO_RECORDING]: [PRESENTATION_RENDERERS.VIDEO],
            [ASSET_TYPE.WEBAPP]: assetViewerItems.length === 1 ? [PRESENTATION_RENDERERS.IFRAME] : [PRESENTATION_RENDERERS.NEW_WINDOW], // Don't use OBSERVABLE_DIMENSIONS_IFRAME: SP-43343
            [ASSET_TYPE.SCORM]: [PRESENTATION_RENDERERS.NEW_WINDOW],
            [ASSET_TYPE.DOCUMENT]: [PRESENTATION_RENDERERS.PDFJS],
            [ASSET_TYPE['3D-MODEL']]: [PRESENTATION_RENDERERS.THREEJS],
            [ASSET_TYPE.RAW]: [PRESENTATION_RENDERERS.ERROR],
            [ASSET_TYPE.MEETING_VIDEO]: [PRESENTATION_RENDERERS.VIDEO],
            [ASSET_TYPE.URL]: urlRenderer,
            [ASSET_TYPE.PAGE]: [PRESENTATION_RENDERERS.OBSERVABLE_DIMENSIONS_IFRAME]
        };

        const TRANSLATIONS = Object.keys(TranslationKeys)
            .map(key => TranslationKeys[key])
            .filter(
                translationKey =>
                    [
                        TranslationKeys.OfficeOnlineBarExcelMessage,
                        TranslationKeys.OfficeOnlineBarPowerpointMessage,
                        TranslationKeys.OfficeOnlineBarWordMessage,
                        TranslationKeys.OpenInExcel,
                        TranslationKeys.OpenInPowerpoint,
                        TranslationKeys.OpenInWord,
                        TranslationKeys.ToggleThumbnails,
                        TranslationKeys.SwitchToSinglePageView,
                        TranslationKeys.SwitchToContinuousScrollView,
                        TranslationKeys.ZoomIn,
                        TranslationKeys.ZoomOut,
                        TranslationKeys.SearchComponentNoResults,
                        TranslationKeys.SearchComponentSearchDocument,
                        TranslationKeys.SearchComponentIsUnavailable
                    ].indexOf(translationKey) === -1
            )
            .reduce((translations, translationKey) => {
                const translation = i18next.t(`asset-viewer.${translationKey}`);
                if (!translation) {
                    console.warn(`Asset viewer: Translation key: "asset-viewer.${translationKey}" not found.`);
                }
                translations[translationKey] = translation;
                return translations;
            }, {});

        // single web-app and page assets are rendered 100% width by default
        const canZoom = !(
            assetViewerItems.length === 1 &&
            (assetViewerItems[0].type === ASSET_TYPE.WEBAPP || assetViewerItems[0].type === ASSET_TYPE.PAGE)
        );

        const baseConfig = {
            container: content,
            thumbnailContainer: thumbs,
            thumbnailViewer: !!thumbs & !isOverlayAssetViewer,
            DEFAULT_THUMB_BASE_PATH: `${STATIC_PATH}images/default`,
            TRANSLATIONS,
            VIEWER_LAYOUT: LAYOUT.VERTICAL,
            PRESENTATION_RENDERERS_FOR_ASSET_TYPE_MAP,
            THREE_JS_DEPS,
            THREEJS_USE_PREVIEW_DIMENSIONS: true,
            THREEJS_VIEW_FULLSCREEN: true,
            PDFJS_PINCH_ZOOM_ENABLED: true,
            VIDEO_POSTER_IMAGE_ENABLED: true,
            SHOWPAD_JS_PATH: require('!!file-loader?name=[name]-[hash].[ext]!@showpad/javascript-api/dist/showpad-js-web.min.js').default,
            TRACK_HTML_PAGE_LOCATION_ENABLED: flags.trackHtmlPageLocationEnabled,
            getPdfJs: () => this.pdfJsService.loadPdfJsResources(),
            getPdfJsWorker: () => this.pdfJsService.getWorker(),
            canZoom,
            MAX_CONTENT_WIDTH: 1200,
            MAX_CONTENT_WIDTH_PAGES: 1600
        };

        const overlayAssetViewerDocumentConfig = {
            ...baseConfig,
            thumbnailViewer: true,
            VIEW_BORDER: 0,
            PRESENTATION_RENDERERS_FOR_ASSET_TYPE_MAP: {
                ...OVERLAY_PRESENTATION_RENDERERS_FOR_ASSET_TYPE_MAP,
                [ASSET_TYPE.DOCUMENT]: [PRESENTATION_RENDERERS.PDFJS]
            }
        };
        const overlayAssetViewerConfig = {
            ...baseConfig,
            VIEW_BORDER: 0,
            VIEWER_LAYOUT: LAYOUT.HORIZONTAL,
            PRESENTATION_RENDERERS_FOR_ASSET_TYPE_MAP: OVERLAY_PRESENTATION_RENDERERS_FOR_ASSET_TYPE_MAP,
            DOCUMENT_ASSET_VIEWER_CONFIG: overlayAssetViewerDocumentConfig
        };

        const config = isOverlayAssetViewer ? overlayAssetViewerConfig : baseConfig;

        this.assetViewer = new AssetViewer(assetViewerItems, config);
        if (!isOverlayAssetViewer) {
            this.actionBar = new ActionBar({
                container: content.parentNode,
                assetViewer: this.assetViewer,
                eventLogger: this.eventLogger
            });
        }
    }

    init(page = 1) {
        if (this.assetViewer) {
            const assetToOpenIndex = this.initialAsset;
            this.assetViewer.init(assetToOpenIndex, page);
            this.assetViewer.initThumbnailViewer();
        }
    }

    destroy() {
        if (this.assetViewer) {
            this.assetViewer.destroy();
        }
    }

    resize() {
        if (this.assetViewer) {
            this.assetViewer.resize();
        }
    }
}
