import i18next from 'i18next';
import Dom from '../../helpers/dom-helper';
import RenderComponent from '../renderComponent';
import ToastService from '../toast/toast';
import sideBarTemplate from './html/sidebar.html';

const SIDEBAR_CLOSED_CLASS = 'collection-sidebar-closed';

export default class Sidebar extends RenderComponent {
    constructor(element, parent, itemCount, domManager, eventBus, info) {
        super(element, parent);

        this.data = {
            itemCount,
            isClosed: false
        };

        this.listenToWindowClick = this.listenToWindowClick.bind(this);

        this.domManager = domManager;
        this.eventBus = eventBus;
        this.element.innerHTML = sideBarTemplate();
        this.info = info;
        this.allowDownloadAll = info.allowDownloadAll;
        this.downloadAsPdfEnabled = info.downloadAsPdfEnabled;
        this.toastService = new ToastService(this.domManager.get('toast-container'));
        this.downloadeableItems = info.content.items.filter(item => !!item.asset && (item.isDownloadable || item.asset.type === 'url')); // SP-23863
        // SP-23863 only count the visible items that are not downloadable (asset should be set)
        this.notDownloadableFilesCount = info.content.items.filter(
            item => !!item.asset && !item.isDownloadable && item.asset.type !== 'url' // SP-23863
        ).length;
        this.actionsDisabled = false;
        this.fetchDomElements();
        this.setI18NLabels();
        this.addEventListeners();
    }

    fetchDomElements() {
        this.domManager.set('collection-sidebar', this.element.querySelector('.collection-sidebar'));
        this.domManager.set('collection-sidebar-thumbs', this.element.querySelector('.collection-sidebar-thumbs'));
        this.domManager.set('shared-collection-sidebar-count', this.element.querySelector('.collection-sidebar-count'));
        this.domManager.set('collection-sidebar-toggle', this.element.querySelector('.collection-sidebar-header'));
        this.domManager.set('collection-sidebar-close', this.element.querySelector('.collection-sidebar-close'));
        this.domManager.set('collection-sidebar-close-mobile', this.element.querySelector('.collection-sidebar-close-mobile'));
        this.domManager.set('collection-sidebar-subheader', this.element.querySelector('.collection-sidebar-subheader'));
        this.domManager.set(
            'collection-sidebar-download-all-button',
            this.element.querySelector('.collection-sidebar-download-all-button')
        );
        this.domManager.set('collection-sidebar-dropdown', this.element.querySelector('.collection-sidebar-dropdown'));
        this.domManager.set('collection-download-as-one-button', this.element.querySelector('#download-as-one-button'));
        this.domManager.set('collection-download-separate-button', this.element.querySelector('#download-separate-button'));
    }

    setI18NLabels() {
        this.domManager.get('collection-download-as-one-button').textContent = i18next.t('download.as-1-pdf');
        this.domManager.get('collection-download-separate-button').textContent = i18next.t('download.as-separate');
        this.domManager.get('collection-sidebar-download-all-button').title = i18next.t('download.all');
    }

    addEventListeners() {
        Dom.on(this.domManager.get('collection-sidebar-close'), 'click', () => this.toggleSidebar());
        Dom.on(this.domManager.get('collection-sidebar-close-mobile'), 'click', () => this.toggleSidebar());
        Dom.on(this.domManager.get('collection-sidebar-toggle'), 'click', () => this.toggleSidebar());

        if (this.allowDownloadAll) {
            if (this.downloadAsPdfEnabled && this.downloadeableItems.length > 1) {
                Dom.on(this.domManager.get('collection-sidebar-download-all-button'), 'click', () => this.toggleDropdown());
                Dom.on(this.domManager.get('collection-download-as-one-button'), 'click', () => {
                    this.toggleDropdown();
                    this.downloadAsPdf();
                });
                Dom.on(this.domManager.get('collection-download-separate-button'), 'click', () => {
                    this.toggleDropdown();
                    this.downloadAll();
                });
            } else {
                Dom.on(this.domManager.get('collection-sidebar-download-all-button'), 'click', () => this.downloadAll());
            }
        }
    }

    getAssetThumbsContainer() {
        return this.domManager.get('collection-sidebar-thumbs');
    }

    render() {
        const text = this.data.itemCount > 1 ? i18next.t('file-plural') : i18next.t('file-single');
        Dom.setElementText(this.domManager.get('shared-collection-sidebar-count'), `${this.data.itemCount} ${text} `);
        if (!this.allowDownloadAll || this.downloadeableItems.length === 0) {
            Dom.hideElement(this.domManager.get('collection-sidebar-download-all-button'));
        }
    }

    isClosed() {
        return this.data.isClosed;
    }

    open() {
        Dom.removeClass(SIDEBAR_CLOSED_CLASS, this.parent);
        Dom.removeClass('closed', this.domManager.get('collection-sidebar-subheader'));
        this.data.isClosed = false;
        this.eventBus.dispatch('sidebar:toggle', this.data.isClosed);
    }

    close() {
        Dom.addClass(SIDEBAR_CLOSED_CLASS, this.parent);
        Dom.addClass('closed', this.domManager.get('collection-sidebar-subheader'));
        this.data.isClosed = true;
        this.eventBus.dispatch('sidebar:toggle', this.data.isClosed);
    }

    toggleSidebar() {
        if (this.actionsDisabled) {
            return;
        }

        if (this.isClosed()) {
            this.open();
        } else {
            this.close();
        }
    }

    toggleDropdown() {
        Dom.toggleClass('open', this.domManager.get('collection-sidebar-dropdown'));

        if (this.domManager.get('collection-sidebar-dropdown').classList.contains('open')) {
            setTimeout(() => window.addEventListener('click', this.listenToWindowClick), 1);
        } else {
            window.removeEventListener('click', this.listenToWindowClick);
        }
    }

    listenToWindowClick() {
        this.toggleDropdown();
    }

    downloadAsPdf() {
        this.eventBus.dispatch('downloadAsPDF', true);
    }

    downloadAll() {
        if (this.downloadeableItems.length === 1) this.eventBus.dispatch('download-only-item');
        else this.eventBus.dispatch('downloadAllFiles');
    }

    showDownloadStartedToast() {
        if (this.notDownloadableFilesCount > 0) {
            const message =
                this.notDownloadableFilesCount === 1
                    ? i18next.t('download-toast-non-downloadable-file', { count: this.notDownloadableFilesCount })
                    : i18next.t('download-toast-non-downloadable-files_plural', { count: this.notDownloadableFilesCount });
            this.toastService.showToast(message, 'info', 8000, 685);
        } else {
            this.toastService.showToast(i18next.t('download-as-one-started-toast'));
        }
    }

    disableButtons() {
        this.close();
        this.actionsDisabled = true;
    }

    enableButtons() {
        this.actionsDisabled = false;
    }
}
