import Dom from '../../helpers/dom-helper';
import AboutModal from './about/about-modal';
import ComplianceModal from './compliance/compliance-modal';
import modalTemplate from './modal.html';
import ShareModal from './share/share-modal';

const MODAL_TEMPLATE = modalTemplate();
const BODY_OVERLAY_OPEN_CLASSNAME = 'overlay-open';
const BODY_MODAL_OPEN_CLASSNAME = 'modal-open';

export default class Modal {
    constructor(domManager, eventLogger = null, eventBus, privacyPolicyUrl) {
        this.domManager = domManager;
        this.eventLogger = eventLogger;
        this.eventBus = eventBus;
        this.privacyPolicyUrl = privacyPolicyUrl;
        this.element = null;
        this.isOpen = false;

        this.modals = {
            about: null,
            share: null,
            compliance: null
        };

        // references to different constructors
        // to be able to map during initialisation
        this.constructors = {
            share: ShareModal,
            about: AboutModal,
            compliance: ComplianceModal
        };

        this.init();
    }

    /**
     * Initialize the modal
     */
    init() {
        this.render();
    }

    /**
     * Open a particular modal (about, share, ..)
     * @param {String} type
     * @param {Object} data
     */
    open(type, data) {
        this.isOpen = true;

        // update view state
        Dom.addClass(BODY_OVERLAY_OPEN_CLASSNAME, this.domManager.get('body'));
        Dom.addClass(BODY_MODAL_OPEN_CLASSNAME, this.domManager.get('body'));

        if (!this.modals[type]) {
            Array.from(this.domManager.get('modal').children).forEach(child => Dom.hideElement(child));

            this.modals[type] = new this.constructors[type](this, data);
        }
    }

    /**
     * Close and delete a particular modal
     * @param {String} type
     */
    close(type) {
        if (this.isModalStacked()) {
            const getSecondLastElement = () => {
                const stackedModals = Array.from(this.domManager.get('modal').children);
                return stackedModals[stackedModals.length - 2];
            };

            const stackedModals = Array.from(this.domManager.get('modal').children);
            Dom.showElement(getSecondLastElement());
        } else {
            this.isOpen = false;

            Dom.removeClass(BODY_OVERLAY_OPEN_CLASSNAME, this.domManager.get('body'));
            Dom.removeClass(BODY_MODAL_OPEN_CLASSNAME, this.domManager.get('body'));

            this.eventBus.dispatch('modal:close');
        }

        delete this.modals[type];
    }

    isModalStacked() {
        return Array.from(this.domManager.get('modal') ? this.domManager.get('modal').children : []).length > 1;
    }

    /**
     * Render the modal in the DOM
     */
    render() {
        if (this.element !== null) {
            throw new Exception('Modal already rendered');
        }

        this.element = Dom.createElement(MODAL_TEMPLATE);
        this.domManager.get('body').appendChild(this.element);

        // cache our modal element in our dom manager
        this.domManager.set('modal-wrapper', document.querySelector('.modal-wrapper'));
        this.domManager.set('modal', document.querySelector('.modal'));
    }
}
