function _template() {return `<div class="modal-consent__outer-wrapper">
    <div class="modal-consent__inner-wrapper">
        <div class="modal-consent__wrapper">
            <aside class="modal-consent">
                <h1 class="modal-consent__header">Privacy Policy</h1>
                <main class="modal-consent__message">
                </main>
                <footer class="modal-consent__accept">
                    <span class="control control--checkbox">
                        <input id="consent-modal-checkbox" type="checkbox" class="consent-modal-checkbox">
                        <div class="control__indicator"></div>
                        <label for="consent-modal-checkbox" class="control__label--custom"
                            data-test-label="privacy-policy-control-checkbox-label">
                            By checking this box, I confirm having read,
                            understood and accepted the <a class="privacy-policy-link" target="_blank">Privacy
                                Policy</a>.
                        </label>
                    </span>
                    <div class="modal-consent__buttons">
                        <button class="button--m consent-modal-button modal-consent__continue button-tint"
                            disabled><span>Continue</span>
                            <div class="button_focus-overlay"></div>
                        </button>
                    </div>
                </footer>
            </aside>
        </div>
    </div>
</div>`;}export default function (ctx) {return _template.call(ctx, ctx);};