function _template() {return `<div class="alert alert--inline alert--danger toast">
    <div class="icon--alert">
        <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewbox="0 0 512 512">
            <path
                d="M256 .5C114.893.5.5 114.893.5 256S114.893 511.5 256 511.5 511.5 397.107 511.5 256 397.107.5 256 .5zm30.241 373.59h-60.482v-41.877h60.482v41.877zm-5.839-68.046h-48.805l-6.886-168.134h62.578l-6.887 168.134z"
            />
        </svg>
    </div>
    <div class="alert__container">
        <span class="alert__title toast-message"></span>
    </div>
</div>
`;}export default function (ctx) {return _template.call(ctx, ctx);};