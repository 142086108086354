import Dom from '../../helpers/dom-helper';
import Storage from '../../helpers/storage';

export const ASSET_VIEWER_OVERLAY_CLASSNAME = 'asset-viewer-overlay__container';
export const ASSET_VIEWER_OVERLAY_MAIN_CONTAINER_CLASSNAME = 'asset-viewer-overlay__asset-viewer-container';
export const ASSET_VIEWER_OVERLAY_THUMBNAIL_CONTAINER_CLASSNAME = 'asset-viewer-overlay__asset-viewer-thumbnail-container';
export const ASSET_VIEWER_OVERLAY_CLOSE_BUTTON_CLASSNAME = 'asset-viewer-overlay__toolbar__close-button';
export const ASSET_VIEWER_OVERLAY_DISPLAY_NAME_CLASSNAME = 'asset-viewer-overlay__toolbar__display-name';
export const ASSET_VIEWER_OVERLAY_SIDEBAR_TOGGLE_BUTTON_CLASSNAME = 'asset-viewer-overlay__sidebar-toggle-button';
export const ASSET_VIEWER_OVERLAY_SIDEBAR_TOGGLE_BUTTON_MOBILE_CLASSNAME = 'asset-viewer-overlay__sidebar-toggle-button-mobile';
export const ASSET_VIEWER_OVERLAY_HEADER_TOGGLE_BUTTON_CLASSNAME = 'asset-viewer-overlay__header-toggle';
export const ASSET_VIEWER_OVERLAY_DOWNLOAD = 'asset-viewer-overlay__download-button';
export const ASSET_VIEWER_OVERLAY_SIDEBAR_CLASSNAME = 'asset-viewer-overlay__sidebar';
export const ASSET_VIEWER_OVERLAY_PRESENTATION_CONTAINER_CLASSNAME = 'asset-viewer-overlay__main';

const HIDDEN_CLASSNAME = 'hide';
const SIDEBAR_HIDDEN_CLASSNAME = 'sidebar-hidden';
const SIDEBAR_COLLAPSED_CLASSNAME = 'sidebar-collapsed';
const SIDEBAR_OPEN_CLASSNAME = 'sidebar-open';

const SHOW_THUMBNAILS_LOCAL_STORAGE_KEY = 'scv-thumbnails-visible';

export default class AssetViewerOverlay {
    constructor(domManager, eventLogger, eventBus, assetViewerLogger) {
        this.overlayAssetViewer = null;
        this.unregisterAssetViewer = null;
        this.isOpen = false;
        this.domManager = domManager;
        this.eventLogger = eventLogger;
        this.eventBus = eventBus;
        this.assetViewerLogger = assetViewerLogger;
        this.overlayContainer = document.querySelector(`.${ASSET_VIEWER_OVERLAY_CLASSNAME}`);
        this.overlayPresentationContainer = document.querySelector(`.${ASSET_VIEWER_OVERLAY_PRESENTATION_CONTAINER_CLASSNAME}`);
        this.container = document.querySelector(`.${ASSET_VIEWER_OVERLAY_MAIN_CONTAINER_CLASSNAME}`);
        this.thumbnailContainer = document.querySelector(`.${ASSET_VIEWER_OVERLAY_THUMBNAIL_CONTAINER_CLASSNAME}`);
        this.sidebar = document.querySelector(`.${ASSET_VIEWER_OVERLAY_SIDEBAR_CLASSNAME}`);
        this.closeButton = document.querySelector(`.${ASSET_VIEWER_OVERLAY_CLOSE_BUTTON_CLASSNAME}`);
        this.displayName = document.querySelector(`.${ASSET_VIEWER_OVERLAY_DISPLAY_NAME_CLASSNAME}`);
        this.sidebarToggleButton = document.querySelector(`.${ASSET_VIEWER_OVERLAY_SIDEBAR_TOGGLE_BUTTON_CLASSNAME}`);
        this.sidebarToggleButtonMobile = document.querySelector(`.${ASSET_VIEWER_OVERLAY_SIDEBAR_TOGGLE_BUTTON_MOBILE_CLASSNAME}`);
        this.sidebarHeaderToggleButton = document.querySelector(`.${ASSET_VIEWER_OVERLAY_HEADER_TOGGLE_BUTTON_CLASSNAME}`);
        this.downloadButton = document.querySelector(`.${ASSET_VIEWER_OVERLAY_DOWNLOAD}`);

        this.showThumbnailsPreference = true;
        Storage.get(SHOW_THUMBNAILS_LOCAL_STORAGE_KEY)
            .then(showThumbnails => {
                this.showThumbnailsPreference = showThumbnails === 'true';
            })
            .catch(() => {});

        this.reset();

        this.close = this.close.bind(this);
        this.toggleThumbnails = this.toggleThumbnails.bind(this);
        this.download = this.download.bind(this);
        this.addEventListeners();
    }

    open() {
        this.isOpen = true;
        Dom.removeClass(HIDDEN_CLASSNAME, this.overlayContainer);
    }

    close() {
        this.isOpen = false;

        Dom.addClass(HIDDEN_CLASSNAME, this.overlayContainer);
        Dom.removeClass(SIDEBAR_HIDDEN_CLASSNAME, this.overlayContainer);
        Dom.removeClass(SIDEBAR_COLLAPSED_CLASSNAME, this.overlayContainer);
        Dom.removeClass(SIDEBAR_OPEN_CLASSNAME, this.overlayContainer);
        Dom.removeClass(SIDEBAR_OPEN_CLASSNAME, this.overlayPresentationContainer);
        Dom.removeClass(SIDEBAR_OPEN_CLASSNAME, this.sidebar);

        this.assetViewerLogger.stopRunningDeviceEvents();
        this.eventBus.dispatch('asset-viewer-overlay-closed');

        this.reset();
    }

    reset() {
        this.resetDisplayName();
        this.resetDownloadEnabled();
        this.destroyAssetViewer();
        this.container.innerHTML = '';
        this.thumbnailContainer.innerHTML = '';
        this.closeCallback = null;
    }

    registerAssetViewer(assetViewer, page) {
        this.overlayAssetViewer = assetViewer;
        this.unregisterAssetViewer = this.assetViewerLogger.registerChildAssetViewer(assetViewer.assetViewer);
        this.overlayAssetViewer.init(page);

        const currentAssetViewer = this.overlayAssetViewer.assetViewer.viewer.currentView.presentationRenderer.assetViewer;
        if (currentAssetViewer && currentAssetViewer.thumbnailViewer) {
            currentAssetViewer.initThumbnailViewer();
            currentAssetViewer.setActive();
        }
        // debugging
        window.overlayAssetViewer = assetViewer;
    }

    destroyAssetViewer() {
        if (this.unregisterAssetViewer) {
            this.unregisterAssetViewer();
            this.unregisterAssetViewer = null;
        }
        if (this.overlayAssetViewer) {
            this.overlayAssetViewer.destroy();
            this.overlayAssetViewer = null;
        }
        // debugging
        window.overlayAssetViewer = null;
    }

    /**
     * Add event listeners for this modal
     */
    addEventListeners() {
        Dom.on(this.closeButton, 'click', this.close);
        Dom.on(this.sidebarToggleButton, 'click', this.toggleThumbnails);
        Dom.on(this.sidebarToggleButtonMobile, 'click', this.toggleThumbnails);
        Dom.on(this.sidebarHeaderToggleButton, 'click', this.toggleThumbnails);
        Dom.on(this.downloadButton, 'click', this.download);
    }

    setDisplayName(name) {
        this.displayName.innerText = name;
    }

    resetDisplayName() {
        this.displayName.innerText = '';
    }

    setDownloadEnabled() {
        this.downloadButton.removeAttribute('disabled');
    }

    resetDownloadEnabled() {
        this.downloadButton.setAttribute('disabled', 'disabled');
    }

    /**
     * Show side bar with user preference (open/collapsed) when opening the asset viewer with no animation
     */
    showSidebar() {
        Dom.removeClass(SIDEBAR_HIDDEN_CLASSNAME, this.overlayContainer);
        if (this.showThumbnailsPreference) {
            Dom.addClass(SIDEBAR_OPEN_CLASSNAME, this.overlayContainer);
        } else {
            Dom.addClass(SIDEBAR_COLLAPSED_CLASSNAME, this.overlayContainer);
        }
        setTimeout(() => {
            this.overlayAssetViewer.resize();
        }, 350);
    }

    hideSidebar() {
        Dom.addClass(SIDEBAR_HIDDEN_CLASSNAME, this.overlayContainer);
    }
    /**
     * Animate side bar and presentation only on toggle
     */
    toggleThumbnails() {
        if (this.showThumbnailsPreference) {
            this.showThumbnailsPreference = false;
            Storage.set(SHOW_THUMBNAILS_LOCAL_STORAGE_KEY, 'false').catch(() => {});

            Dom.addClass(SIDEBAR_COLLAPSED_CLASSNAME, this.overlayContainer);
            Dom.removeClass(SIDEBAR_OPEN_CLASSNAME, this.overlayContainer);
            Dom.removeClass(SIDEBAR_OPEN_CLASSNAME, this.sidebar);
            Dom.removeClass(SIDEBAR_OPEN_CLASSNAME, this.overlayPresentationContainer);
        } else {
            this.showThumbnailsPreference = true;
            Storage.set(SHOW_THUMBNAILS_LOCAL_STORAGE_KEY, 'true').catch(() => {});

            Dom.removeClass(SIDEBAR_COLLAPSED_CLASSNAME, this.overlayContainer);
            Dom.addClass(SIDEBAR_OPEN_CLASSNAME, this.overlayContainer);
            Dom.addClass(SIDEBAR_OPEN_CLASSNAME, this.sidebar);
            Dom.addClass(SIDEBAR_OPEN_CLASSNAME, this.overlayPresentationContainer);
        }
        setTimeout(() => {
            this.overlayAssetViewer.resize();
        }, 350);
    }

    download() {
        if (this.overlayAssetViewer) {
            this.overlayAssetViewer.assetViewer.viewer.viewerLayout.currentView.download();
        }
    }
}
