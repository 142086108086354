function _template() {return `<div class="alert alert--inline alert--information toast">
    <div class="icon--alert">
        <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewbox="0 0 512 512">
            <path
                d="M256 .5C114.893.5.5 114.893.5 256S114.893 511.5 256 511.5 511.5 397.107 511.5 256 397.107.5 256 .5zm-21.292 149.042h42.583v42.583h-42.583v-42.583zm63.875 212.916h-85.166v-21.291h21.292V234.708h-21.292v-21.292h63.875v127.75h21.291v21.292z"
            />
        </svg>
    </div>
    <div class="alert__container">
        <span class="alert__title toast-message"></span>
    </div>
</div>
`;}export default function (ctx) {return _template.call(ctx, ctx);};