export function isCloseToWhite(hexColor) {
    // convert hex to RGB values
    const red = parseInt(hexColor.substring(1, 3), 16);
    const green = parseInt(hexColor.substring(3, 5), 16);
    const blue = parseInt(hexColor.substring(5, 7), 16);

    // calculate color distance from white (255,255,255)
    const colorDistance = Math.sqrt(Math.pow(255 - red, 2) + Math.pow(255 - green, 2) + Math.pow(255 - blue, 2));

    // determine if color is close to white
    if (colorDistance < 100) {
        return true;
    } else {
        return false;
    }
}

export function isCloseToBlack(hexColor) {
    // convert hex to RGB values
    const red = parseInt(hexColor.substring(1, 3), 16);
    const green = parseInt(hexColor.substring(3, 5), 16);
    const blue = parseInt(hexColor.substring(5, 7), 16);

    // calculate color distance from black (0,0,0)
    const colorDistance = Math.sqrt(Math.pow(red, 2) + Math.pow(green, 2) + Math.pow(blue, 2));

    // determine if color is close to black
    if (colorDistance < 100) {
        return true;
    } else {
        return false;
    }
}
