// Needs to be the first import in order for webpack to use the correct __webpack_public_path__
import './public-path';

import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import FastClick from 'fastclick';
import { warnDeviceEventLogging } from '../source/helpers/device-event-logger.helper';
import ShowcaseClient from './components/viewer/showcase-client';
import env from './helpers/env';
import { initializePendo } from './helpers/pendo';
import { HANDLED_PROMISE_REJECTION_MESSAGE_PREFIX } from './helpers/promise';
import Url from './helpers/url-helper';
import initI18n from './i18n/i18n';
import { ignoreErrors } from './ignore-errors';
import './style/main.less';

// instantiate FastClick on the body
if ('addEventListener' in document) {
    document.addEventListener(
        'DOMContentLoaded',
        function() {
            FastClick.attach(document.body);
        },
        false
    );
}

Sentry.init({
    dsn: env.SENTRY_DSN,
    environment: env.ENVIRONMENT,
    release: env.RELEASE_TAG,
    normalizeDepth: 5,
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.2,
    // startTransactionOnLocationChange: false,
    // startTransactionOnPageLoad: false,
    denyUrls: [
        /\/share\/static\/pdf/, // pdfjs
        /^file:\/\//i, // Local file system
        // Chrome and Firefox extensions
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        /^moz-extension:\/\//i
    ],
    ignoreErrors
});

initI18n()
    .then(() => warnDeviceEventLogging())
    .then(() => initShowcaseClient())
    .catch(() => initShowcaseClient());

function initShowcaseClient() {
    const showcaseClient = new ShowcaseClient();

    try {
        if (window.shareHash && window.showcaseBaseUrl) {
            const showcaseLink = `${window.showcaseBaseUrl}/${window.shareHash}`;

            showcaseClient.setIsShowcaseLoading(false);
            showcaseClient.setShowcaseErrorMessage(`
                You are trying to view this Showcase locally, please visit
                <a href="${showcaseLink}">${showcaseLink}</a>
                for an enhanced experience.
            `);
            showcaseClient.setShowcaseError(true);
        } else {
            const path = window.location.pathname;
            const showcaseBaseUrl = Url.getShowcaseBaseUrl();

            const [apiBase, shareHash] = Url.parseShareHash(path);

            showcaseClient
                .load(apiBase, shareHash, showcaseBaseUrl)
                .then(response => {
                    if (response && response.success && response.data && response.data.organisation) {
                        try {
                            initializePendo(env.PENDO_API_KEY, env.ENVIRONMENT, response.data.organisation.id);
                        } catch (error) {
                            Sentry.captureException(error);
                        }
                    }
                    return showcaseClient.handle(response);
                })
                .then(() => showcaseClient.createShowCaseViewer())
                .then(() => showcaseClient.setIsShowcaseLoading(false))
                // set loading to false, without error, error is handled/logged when error occurs
                .catch(error => handleTopLevelError(error));
        }
        // For debugging purposes
        document.querySelector('body').showcase = () => showcaseClient;
    } catch (error) {
        handleTopLevelError(error);
    }
    function handleTopLevelError(error) {
        showcaseClient.setIsShowcaseLoading(false);
        showcaseClient.setShowcaseError(true);

        if (error && (typeof error !== 'string' || !error.includes(HANDLED_PROMISE_REJECTION_MESSAGE_PREFIX))) {
            Sentry.captureException(error); // Only log to sentry when an actual error is thrown, expect empty Promise rejection to be handled handled
            console && console.error && console.error(error);
        }
    }
}
