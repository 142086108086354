/**
 * Showcase event logger
 * Extends the asset viewer event logger to have the asset events interface, overwrite all methods
 * Adds additional methods for asset download, showcase view, share, ...
 */
import DeviceEvents, {
    ChildViewDeviceEvent,
    ItemDownloadDeviceEvent,
    ItemViewedDeviceEvent,
    ShowcaseViewedDeviceEvent
} from './device-event.helper';
import * as Sentry from '@sentry/browser';
import { HANDLED_PROMISE_REJECTION_MESSAGE_PREFIX } from './promise';
import { ignoreErrors } from '../ignore-errors';
import { logStart } from './device-event-logger.helper';

const SHOWCASE_EVENT = {
    SHOWCASE_VIEW: 'showcase-view',
    SHOWCASE_SHARED: 'showcase-shared',
    ASSET_DOWNLOAD: 'asset-download',
    ASSET_VIEW: 'asset-view'
};

const INDEX_ITEM_NOT_FOUND = -1;
const UPDATE_THRESHOLD = 2000;
const UPDATE_ASSET_THRESHOLD = 10000;

export function shouldLogError(error) {
    // ignore known false positives
    if (
        ignoreErrors.includes(error) ||
        (error && error.stack && ignoreErrors.includes(error.stack)) ||
        (error && error.message && ignoreErrors.includes(error.message))
    ) {
        return false;
    }
    // Ignore errors in the 4xx range
    if (error && error.status && error.status > 400 && error.status < 500) {
        return false;
    }
    // Ignore specific error codes that are monitored elsewhere
    if (error && error.status && [502, 503].includes(error.status)) {
        return false;
    }
    return true;
}

/**
 * Showcase event logger
 * Extends the asset viewer event logger to have the asset events interface, overwrite all methods
 * Adds additional methods for asset download, showcase view, share, ...
 */
class EventLogger {
    constructor(shareHash, trackEvents, consentRequired = true) {
        this.trackEvents = trackEvents;
        this.consentRequired = consentRequired;
        this.showcaseViewedEventSent = false;

        // Stores current
        this.itemViewDeviceEvent = null;

        this.shareHash = shareHash;
        this.startSessionTimestamp = null;
    }

    get canTrack() {
        return !this.consentRequired;
    }

    /**
     * Track the viewing of a showcase
     */
    trackShowcaseView() {
        if (this.showcaseViewedEventSent) return;
        this.showcaseViewedEventSent = true;

        const timestamp = Date.now();
        this.startSessionTimestamp = timestamp;
        if (this.canTrack && this.trackEvents.indexOf(SHOWCASE_EVENT.SHOWCASE_VIEW) > INDEX_ITEM_NOT_FOUND) {
            const event = ShowcaseViewedDeviceEvent.build({
                shareHash: this.shareHash,
                start: timestamp,
                end: timestamp
            });

            return DeviceEvents.createAsyncDeviceEvent(event, this.startSessionTimestamp).catch(error => {
                if (shouldLogError(error)) {
                    this.notify(error, 'could not track showcase view');
                }
            });
        } else {
            return Promise.resolve();
        }
    }

    /**
     * Track the downloading of a showcase item
     * @param {assetViewerItem} AssetViewerItem
     */
    trackItemDownload(assetViewerItem) {
        if (this.canTrack && this.trackEvents.indexOf(SHOWCASE_EVENT.ASSET_DOWNLOAD) > INDEX_ITEM_NOT_FOUND) {
            const timestamp = new Date().getTime();
            const parentPosition = assetViewerItem.parentPosition;
            const event = ItemDownloadDeviceEvent.build({
                itemPosition: typeof assetViewerItem.position === 'number' ? assetViewerItem.position : parentPosition,
                shareHash: this.shareHash,
                start: timestamp,
                end: timestamp
            });

            if (typeof parentPosition === 'number') {
                event.metadata = {
                    assetId: assetViewerItem.assetId
                };
            }

            DeviceEvents.createAsyncDeviceEvent(event, this.startSessionTimestamp).catch(error => {
                if (shouldLogError(error)) {
                    this.notify(error, 'could not track item download');
                }
            });
        }
    }

    trackItemsDownload(assetViewerItems) {
        if (this.canTrack && this.trackEvents.indexOf(SHOWCASE_EVENT.ASSET_DOWNLOAD) > INDEX_ITEM_NOT_FOUND) {
            const timestamp = new Date().getTime();

            const events = assetViewerItems.map(assetViewerItem => {
                const parentPosition = assetViewerItem.parentPosition;
                const event = ItemDownloadDeviceEvent.build({
                    itemPosition: typeof assetViewerItem.position === 'number' ? assetViewerItem.position : parentPosition,
                    shareHash: this.shareHash,
                    start: timestamp,
                    end: timestamp
                });

                if (typeof parentPosition === 'number') {
                    event.metadata = {
                        assetId: assetViewerItem.assetId
                    };
                }

                return event;
            });

            DeviceEvents.createAsyncDeviceEvent(events, this.startSessionTimestamp).catch(error => {
                if (shouldLogError(error)) {
                    this.notify(error, 'could not track item download');
                }
            });
        }
    }

    /**
     * Action when an asset has been viewed for UPDATE_THRESHOLD time, log it with the threshold as view time
     * Return deviceEvent for updates
     *
     * @param {Object} assetViewerItem
     * @param {null|Number} page
     * @param {Boolean} hasTreshold
     */
    startViewingAsset(assetViewerItem, page, parentPosition, hasTreshold) {
        if (this.canTrack && this.trackEvents.indexOf(SHOWCASE_EVENT.ASSET_VIEW) > INDEX_ITEM_NOT_FOUND) {
            const timestamp = new Date().getTime();
            let start = timestamp;

            if (hasTreshold === true) {
                start = start - UPDATE_THRESHOLD;
            }

            const itemViewDeviceEvent = ItemViewedDeviceEvent.build({
                shareHash: this.shareHash,
                start,
                end: timestamp,
                itemPosition: typeof assetViewerItem.position === 'number' ? assetViewerItem.position : parentPosition,
                page
            });

            if (typeof parentPosition === 'number') {
                itemViewDeviceEvent.metadata = {
                    assetId: assetViewerItem.assetId
                };
            }

            const headers = {
                'X-SHOWPAD-EVE-ID': DeviceEvents.getEveId(this.shareHash, assetViewerItem.position, start)
            };

            return DeviceEvents.createDeviceEvent(itemViewDeviceEvent, this.startSessionTimestamp, headers)
                .then(
                    deviceEvent =>
                        (this.itemViewDeviceEvent = {
                            ...itemViewDeviceEvent,
                            ...deviceEvent,
                            itemPosition: assetViewerItem.position,
                            page
                        })
                )
                .then(() => this.itemViewDeviceEvent)
                .catch(error => {
                    const message = 'could not track asset view start';
                    return Promise.reject(HANDLED_PROMISE_REJECTION_MESSAGE_PREFIX + 'could not track asset viewer star');
                });
        } else {
            return Promise.reject(HANDLED_PROMISE_REJECTION_MESSAGE_PREFIX + 'Expected promise rejection: tracking disabled');
        }
    }

    createUrlAssetViewDeviceEvent(assetViewerItem) {
        if (this.canTrack && this.trackEvents.indexOf(SHOWCASE_EVENT.ASSET_VIEW) > INDEX_ITEM_NOT_FOUND) {
            const timestamp = new Date().getTime();
            const parentPosition = assetViewerItem.parentPosition;

            const itemViewDeviceEvent = ItemViewedDeviceEvent.build({
                shareHash: this.shareHash,
                start: timestamp,
                end: timestamp,
                itemPosition: typeof assetViewerItem.position === 'number' ? assetViewerItem.position : parentPosition,
                page: null
            });

            if (typeof parentPosition === 'number') {
                itemViewDeviceEvent.metadata = {
                    assetId: assetViewerItem.assetId
                };
            }

            const headers = {
                'X-SHOWPAD-EVE-ID': DeviceEvents.getEveId(this.shareHash, assetViewerItem.position, timestamp)
            };

            return DeviceEvents.createDeviceEvent(itemViewDeviceEvent, this.startSessionTimestamp, headers).catch(error => {
                if (shouldLogError(error)) {
                    this.notify(error, 'could not track url asset');
                }
            });
        } else {
            return Promise.resolve();
        }
    }

    trackHtmlNavigation(events, metadata) {
        if (this.canTrack) {
            const childViewEvents = events.map(event =>
                ChildViewDeviceEvent.build({
                    shareHash: this.shareHash,
                    start: new Date().getTime(),
                    parentId: metadata.parentId,
                    assetPosition: metadata.assetPosition,
                    pageLocation: event.pageLocation,
                    assetId: metadata.assetId
                })
            );

            logStart({
                data: {
                    assetViewerItem: {
                        assetId: metadata.assetId,
                        displayName: metadata.displayName
                    }
                },
                deviceEvent: {
                    id: metadata.parentId,
                    action: 'childview'
                }
            });

            return DeviceEvents.createAsyncDeviceEvent(childViewEvents, this.startSessionTimestamp);
        } else {
            return Promise.resolve();
        }
    }

    /**
     * Action called when the asset is stopped being viewed
     * Update the end time + clear the device event
     *
     * @param {Object} itemViewDeviceEvent to update
     */
    stopViewingAsset(itemViewDeviceEvent, assetViewerItem) {
        if (this.canTrack && this.trackEvents.indexOf(SHOWCASE_EVENT.ASSET_VIEW) > INDEX_ITEM_NOT_FOUND) {
            if (itemViewDeviceEvent === null) {
                this.notify(
                    new Error('EventLogger:stopViewingAsset:tried to update a device event without the original event'),
                    'EventLogger:stopViewingAsset:tried to update a device event without the original event'
                );
            } else {
                itemViewDeviceEvent.end = new Date().getTime();
                itemViewDeviceEvent.updateType = 'stop';

                const headers = {
                    'X-SHOWPAD-EVE-ID': DeviceEvents.getEveId(this.shareHash, assetViewerItem.position, itemViewDeviceEvent.start)
                };

                DeviceEvents.updateDeviceEvent(itemViewDeviceEvent, this.startSessionTimestamp, headers)
                    .then(() => (itemViewDeviceEvent = null))
                    .catch(error => {
                        itemViewDeviceEvent = null;
                        if (shouldLogError(error)) {
                            this.notify(error, 'could not track item view stop');
                        }
                    });
            }
        }
    }

    /**
     * Action called to update the asset view event
     * Update the end time
     *
     * @param {Object} itemViewDeviceEvent to update
     */
    stillViewingAsset(itemViewDeviceEvent, assetViewerItem) {
        if (this.canTrack && this.trackEvents.indexOf(SHOWCASE_EVENT.ASSET_VIEW) > INDEX_ITEM_NOT_FOUND) {
            if (itemViewDeviceEvent === null) {
                this.notify(
                    new Error('EventLogger:stopViewingAsset:tried to update a device event without the original event'),
                    'EventLogger:stopViewingAsset:tried to update a device event without the original event'
                );
            } else {
                itemViewDeviceEvent.end = new Date().getTime();
                itemViewDeviceEvent.updateType = 'heartbeat';

                const headers = {
                    'X-SHOWPAD-EVE-ID': DeviceEvents.getEveId(this.shareHash, assetViewerItem.position, itemViewDeviceEvent.start)
                };

                DeviceEvents.updateDeviceEvent(itemViewDeviceEvent, this.startSessionTimestamp, headers).catch(error => {
                    if (shouldLogError(error)) {
                        this.notify(error, 'could not update the item view');
                    }
                });
            }
        }
    }

    /**
     * Safe proxy to console log
     */
    log() {
        if (window.console && window.console.log) {
            const params = Array.prototype.slice.call(arguments);
            window.console.log.apply(window.console, params);
        }
    }

    /**
     * Safe proxy to console warn
     */
    warn() {
        if (window.console && window.console.warn) {
            const params = Array.prototype.slice.call(arguments);
            window.console.warn.apply(window.console, params);
        }
    }

    /**
     * Safe proxy to console info
     */
    info() {
        if (window.console && window.console.info) {
            const params = Array.prototype.slice.call(arguments);
            window.console.info.apply(window.console, params);
        }
    }

    /**
     * Safe proxy to console error
     */
    error() {
        if (window.console && window.console.error) {
            const params = Array.prototype.slice.call(arguments);

            // Better representation of errors
            if (params.length === 2 && typeof params[0] === 'string' && params[1] instanceof Error) {
                window.console.group(params[0]);
                window.console.error(params[1].message);
                window.console.error(params[1].stack);
                window.console.groupEnd();
            } else {
                window.console.error(...params);
            }
        }
    }

    /**
     * Notify Sentry of an issue
     * @param {string} error
     * @param {string} message
     * @param {object} metaData
     * @param {string} severity
     */
    notify(error, message, metaData, severity = 'warn') {
        Sentry.captureException(error, { extra: { message, metaData }, level: severity });

        if (typeof this[severity] === 'function') {
            this[severity](error, message, metaData);
        }
    }
}

export default EventLogger;
export { UPDATE_THRESHOLD, UPDATE_ASSET_THRESHOLD };
